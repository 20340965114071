import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Transition, Menu } from '@headlessui/react'
import AuthContext from '../context/AuthContext'
import { Link, useLocation } from 'react-router-dom'
import OImage from './reusable/OImage'
import { apiGet } from 'utils/apiFetch'
import apiPath from 'utils/pathObj'
import MerchantRoutes from 'routes/MerchantRoutes'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import helper from '../utils/helpers'

const TopNavBar = () => {
  const {
    logoutUser,
    user,
    handleSidebar,
    updatePageName,
    language,
    setLanguage
  } = useContext(AuthContext)
  const { t, i18n } = useTranslation()

  const location = useLocation()

  const [notification, setNotification] = useState(false)
  const [breadcrumbs, setBreadcrumbs] = useState(false)
  const changeLanguageHandler = eventKey => {
    setLanguage(eventKey)
    if (eventKey === 2) {
      localStorage.setItem('language', 'al')
    } else {
      localStorage.setItem('language', 'en')
    }
    if (eventKey === 1) {
      i18n.changeLanguage('en')
    } else if (eventKey === 2) {
      i18n.changeLanguage('al')
    }
  }
  const handleLogout = () => {
    logoutUser()
  }

  useEffect(() => {
    setBreadcrumbs(
      MerchantRoutes.children.find(c => c.path === location.pathname) || null
    )
    getCompleteProfile()
  }, [location.pathname])

  const getCompleteProfile = async () => {
    try {
      const path = apiPath.getCompleteProfile
      const result = await apiGet(path, {})
      setNotification(result?.data?.results?.hasNewNotification)
    } catch (error) {
      console.log('error:', error)
    }
  }

  const handelApiCall = () => {
    getCompleteProfile()
  }

  useEffect(() => {
    if (!isEmpty(user)) {
      document.body.classList.remove('body-img')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='linHeader border-b py-4 px-4 md:px-8 bg-slate-100 '>
      <header className='items-center flex'>
        <div className='mr-4 xl:hidden' onClick={() => handleSidebar()}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
            />
          </svg>
        </div>
        <div className={breadcrumbs?.name === undefined ? 'page_heading hidden' : 'page_heading w-full'}>
          <h1 className='flex items-center text-lg text-slate-900 font-medium '>
            {breadcrumbs?.name}
          </h1>
        </div>

        <div className='flex items-center w-full'>
          {breadcrumbs?.name ? "": <strong className='block w-full'>{t('WELCOME')}, {user?.first_name}</strong> }
          <Menu
            as='div'
            className='relative  flex items-center justify-end w-full mr-3'
          >
            <Menu.Button className=''>
              <img
                src={
                  language === 1
                    ? './images/usFlag.svg'
                    : './images/albaniaFlag.svg'
                }
                className='h-[26px] w-[26px] rounded-full'
                alt=''
              />
            </Menu.Button>
            <Menu.Button className='flex items-center block pl-3'>
              {language === 1 ? 'En' : 'Al'}
            </Menu.Button>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='z-50 absolute right-0 top-[40px] w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className='px-1 py-1 '>
                  <Menu.Item className='py-2 flex items-center w-full hover:bg-gray-100'>
                    {({ active }) => (
                      <button
                        className='flex items-center'
                        onClick={() => changeLanguageHandler(1)}
                      >
                        <img
                          src='./images/usFlag.svg'
                          alt=''
                          className='mr-2 h-[26px] w-[26px] rounded-full'
                        />{' '}
                        English
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item className='py-2 flex items-center w-full hover:bg-gray-100'>
                    {({ active }) => (
                      <button
                        className='flex items-center'
                        onClick={() => changeLanguageHandler(2)}
                      >
                        <img
                          src='./images/albaniaFlag.svg'
                          alt=''
                          className='mr-2 rounded-full'
                        />{' '}
                        Shqip
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <Menu as='div'>
            <div className='flex items-center min-w-[220px]'>
              {notification ? (
                <Link
                  to='/notifications'
                  className='mx-4 relative'
                  onClick={() => handelApiCall()}
                >
                  <img src='images/bell.png' alt='No_image' className='w-8' />
                  <span className='absolute top-0 right-0 w-0.5 h-0.5 bg-red-500 flex justify-center items-center rounded-full text-white p-2 d-block text-xs font-bold'></span>
                </Link>
              ) : (
                <Link to='/notifications' className='mx-4 relative'>
                  <img src='images/bell.png' alt='No_image' className='w-8' />
                </Link>
              )}
              <Menu.Button className='flex relative items-center w-full text-white active:text-LightBlue hover:bg-white hover:text-LightBlue group rounded-lg'>
                <div className='profile flex items-center ml-auto md:mr-10 mr-2 p-1'>
                  <figure className='inline-block overflow-hidden rounded-full'>
                    <OImage
                      src={user?.business_logo}
                      fallbackUrl='/images/user.png'
                      className='w-[40px] h-[40px] inline'
                      alt=''
                    />
                  </figure>
                  <small className='block text-sm text-slate-900 ml-2 md:block hidden'>
                    {user?.first_name} {user?.last_name}
                  </small>
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-10'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute z-10 text-left min-w-[200px]  right-2 content divide-y divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className=''>
                  {(user?.permission?.[13]?.view ||
                    user?.role === 'merchant') && (
                    <Menu.Item onClick={() => updatePageName('Edit Profile')}>
                      {({ active }) => (
                        <Link
                          to='/profile_new'
                          className={`${
                            active
                              ? 'bg-violet-500 text-white hover:bg-LightBlue'
                              : 'text-gray-900'
                          } group flex w-full items-center  px-6 py-2 text-sm`}
                        >
                          {t('TOP_NAV_PROFILE')}
                        </Link>
                      )}
                    </Menu.Item>
                  )}
                  {(user?.permission?.[13]?.view || user?.role === 'agent') && (
                    <Menu.Item onClick={() => updatePageName('Edit Profile')}>
                      {({ active }) => (
                        <Link
                          to='/change_pwd'
                          className={`${
                            active
                              ? 'bg-violet-500 text-white hover:bg-LightBlue'
                              : 'text-gray-900'
                          } group flex w-full items-center  px-6 py-2 text-sm`}
                        >
                          {t('TOP_NAV_PROFILE')}
                        </Link>
                      )}
                    </Menu.Item>
                  )}
                  {(user?.permission?.[13]?.view || user?.role === 'admin') && (
                    <Menu.Item onClick={() => updatePageName('Settings')}>
                      {({ active }) => (
                        <Link
                          to='/settings'
                          className={`${
                            active
                              ? 'bg-violet-500 text-white'
                              : 'text-gray-900'
                          } group flex w-full items-center  px-6 py-2 text-sm`}
                        >
                          {t('SETTING_SETTINGS')}
                        </Link>
                      )}
                    </Menu.Item>
                  )}
                </div>
                <div className=''>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() =>
                          helper.alertFunction(
                            `${t('ARE_YOU_SURE_YOU_WANT_TO_LOGOUT')}`,
                            '',
                            handleLogout,
                            '',
                            language
                          )
                        }
                        className={`${
                          active
                            ? 'bg-violet-500 text-white  hover:bg-LightBlue'
                            : 'text-gray-900'
                        } group flex w-full items-center  px-6 py-2 text-sm`}
                      >
                        {t('NAV_LOGOUT')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </header>
    </div>
  )
}

export default TopNavBar
