import OInputField from 'components/reusable/OInputField'
import AuthContext from 'context/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { validationRules } from 'utils/constants'
import { containsNumbers, generateRandomID } from 'utils/reusableMethods'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

const LoginForm = ({ openTab }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { loginUser, user } = useContext(AuthContext)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const [icon, setIcon] = useState(true)
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem('rememberMe')
      ? JSON.parse(localStorage.getItem('rememberMe'))
      : false
  )

  const handleRememberMe = e => {
    localStorage.setItem('rememberMe', e.target.checked)
    setRememberMe(e.target.checked)
  }

  useEffect(() => {
    if (rememberMe) {
      reset({
        email_or_phone: localStorage.getItem('email_or_phone'),
        password: localStorage.getItem('password')
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(user !== null){
      switch(user.type){
        case 'agent':
          navigate('/dashboard-agent')
          break;
        case 'merchant':
          navigate('/dashboard')
          break;
        case 'operator':
          navigate('/dashboard-operator')
          break;
        default:
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleLogin = async data => {
    const sendData = {
      device_id: generateRandomID(10),
      device_type: 'web',
      device_token: generateRandomID(20)
    }
    if (rememberMe) {
      localStorage.setItem('email_or_phone', data.email_or_phone)
      localStorage.setItem('password', data.password)
    } else {
      localStorage.removeItem('email_or_phone')
      localStorage.removeItem('password')
    }

    try {
      if (
        containsNumbers(data?.email_or_phone[0]) &&
        containsNumbers(data?.email_or_phone[data?.email_or_phone.length - 1])
      ) {
        sendData.input = data?.email_or_phone
        sendData.country_code = '355'
      } else {
        sendData.input = data?.email_or_phone
      }
      sendData.password = data.password
      loginUser(sendData)
    } catch (err) {} // finally { // }
  }

  function changeIcon () {
    setIcon(!icon)
  }
  useEffect(() => {
    reset()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTab])

  const preventMaxValue = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 50) {
      e.target.value = e.target.value.slice(0, 50)
    }
  }
  const preventMaxEmail = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  return (
    <div>
      <form
        className='md:max-w-lg m-auto px-3 '
        onSubmit={handleSubmit(handleLogin)}
      >
        <div className='grid grid-cols-1 gap-4 md:gap-6 mt-[50px]'>
          <div className='relative'>
            {openTab === 2 && (
              <OInputField
                name='email_or_phone'
                inputLabel={
                  <>
                    {t('LOGIN_EMAIL_ID_MOBILE')}
                    <span className='text-red-500'>*</span>
                  </>
                }
                autoFocus
                placeholder=''
                type='text'
                maxLength={50}
                onInput={e => preventMaxValue(e)}
                register={register('email_or_phone', {
                  required: t('PLEASE_ENTER_MOBILE_NUMBER_EMAIL_ID'),
                  validate: value => {
                    return !!value.trim() ? true : t('WHITE_SPACE_NOT_ALLOWED')
                  }
                })}
                errors={errors}
              />
            )}
          </div>
        </div>
        <div className='grid grid-cols-1 gap-4 md:gap-6 mt-5'>
          <div className='relative'>
            <OInputField
              name='password'
              inputLabel={
                <>
                  {t('O_PASSWORD')}
                  <span className='text-red-500'>*</span>
                </>
              }
              errors={errors}
              type={icon ? 'password' : 'text'}
              autoComplete='new-password'
              maxLength={15}
              onInput={e => preventMaxEmail(e)}
              register={register('password', {
                required: t('PLEASE_ENTER_PASSWORD'),
                pattern: {
                  value: validationRules.password,
                  message: t('PASSWORD_MUST_CONTAIN_LOWERCASE_UPPERCASE_')
                }
              })}
            />
            {icon ? (
              <span
                className='password_view absolute top-[18px] right-[20px]'
                onClick={() => changeIcon()}
              >
                <AiFillEyeInvisible />
              </span>
            ) : (
              <span
                className='password_view absolute top-[18px] right-[20px]'
                onClick={() => changeIcon()}
              >
                <AiFillEye />
              </span>
            )}
          </div>
        </div>

        <div className='flex  my-5'>
          <input
            id='default-checkbox'
            type='checkbox'
            defaultChecked={rememberMe}
            className='w-5 h-5 text-blue-600 bg-gray-100 rounded border-gray-100 focus:ring-0 dark:focus:ring-white dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
            onChange={e => handleRememberMe(e)}
          />
          <label
            htmlFor='default-checkbox'
            className='ml-2 text-md text-gray-900 dark:text-gray-300 dark:text-black'
          >
            {t('LOGIN_REMEMBER_ME')}
          </label>
          <Link to='/forgot-password' className='text-gradblue flex ml-auto'>
            {t('O_FORGOT_PASSWORD')}?
          </Link>
        </div>
        <button className='bg-[#7100fa] hover:bg-DarkBlue cursor-pointer w-full mt-3 text-white active:bg-slate-100 text-md px-8 py-4 rounded-lg font-normal shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'>
          {t('O_LOGIN')}
        </button>
      </form>
    </div>
  )
}

export default LoginForm
