import ChangePwd from 'pages/ChangePwd'
import HomeAgent from 'pages/HomeAgent'
import MyTransaction from 'pages/my-transaction/MyTransaction'
import Notifications from 'pages/notification/Notifications'
import MyQrCode from 'pages/MyQrCode/MyQrCode'
import SharedLayout from 'utils/SharedLayout'
import { HomeRedirect } from './HomeRedirect'
import Refund from 'pages/refund/Refund'

const AgentRoutes = {
  // All common routes
  path: '/',
  element: <SharedLayout />,
  children: [
    {
      path: '/dashboard-agent',
      element: <HomeAgent />
    },
    {
      path: '/my-transaction',
      element: <MyTransaction />
    },
    {
      path: '/refund',
      element: <Refund />
    },
    {
      path: '/change_pwd',
      element: <ChangePwd />
    },
    {
      path: '/notifications',
      element: <Notifications />
    },
    {
      path: '/my-qrcode',
      element: <MyQrCode />
    },
    {
      path: '*',
      element: <HomeRedirect to='/dashboard-agent' />
    }
  ]
}

export default AgentRoutes
