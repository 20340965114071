import { useTranslation } from 'react-i18next'

const UseChange = ({ data }) => {
  let { t } = useTranslation()
  return t(data)
}

const formValidation = {
  title: {
    required: <>{<UseChange data='PLEASE_ENTER_VOUCHER_OFFER_TITLE' />}</>,
    minLength: {
      value: 3,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_3' />}</>
    },
    maxLength: {
      value: 15,
      message: <>{<UseChange data='MAXIMUM_LENGTH_MUST_BE_15' />}</>
    },
    validate: value => {
      return !!value?.trim() ? (
        true
      ) : (
        <>{<UseChange data='WHITE_SPACE_NOT_ALLOWED' />}</>
      )
    }
  }
}

export default formValidation
