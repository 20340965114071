import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import className from 'classnames'

const InvoiceList = ({ invoices, setOfferFilterState, page }) => {
  const { t } = useTranslation()
  const [sort, setSort] = useState({})

  const handleSort = (sortKey, sortType, state) => {
    setOfferFilterState({ sortKey, sortType })
    setSort({ [state]: true })
  }

  return (
    <div className='p-1 md:p-3 pt-0'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6 '>
                #
              </th>
              <th scope='col' className='py-3 px-6 '>
                <div className='flex text-left'>
                  {t('INVOICE_TRANSACTION_ID')}
                </div>
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_MOBILE_NUMBER')} (+355)
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('O_CREATED_DATE')}
                  <button className='ml-3'>
                    <img
                      name='createdAt'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort.createdAtDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'createdAtUpArrow')
                      }
                    />
                    <img
                      name='createdAt'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.createdAtUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'createdAtDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('AMOUNT')} ({t('O_LEK')})
                </div>
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('STATUS')}
                  <button className='ml-3'>
                    <img
                      name='status'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort.statusDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'statusUpArrow')
                      }
                    />
                    <img
                      name='status'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.statusUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'statusDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {invoices && invoices?.length > 0 ? (
              <>
                {invoices &&
                  invoices?.map(
                    (
                      { transactionId, mobile, createdAt, amount, status },
                      i
                    ) => {
                      return (
                        <tr
                          key={i}
                          className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                          style={i % 2 ? { background: '#F9F9F9' } : {}}
                        >
                          <td className='py-3 px-6 border-r font-medium text-gray-900 dark:text-white'>
                            {i + 1 + 10 * (page - 1)}
                          </td>
                          <td className='py-3 px-6 border-r text-left'>
                            <div>{transactionId}</div>
                          </td>
                          <td className='py-3 px-6 border-r text-right'>
                            <div>{mobile}</div>
                          </td>
                          <td className='py-3 px-6 border-r text-right'>
                            {dayjs(createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                          </td>
                          <td className='py-3 px-6 border-r text-right'>
                            {amount}
                          </td>
                          <td
                            className={className(
                              'py-3 px-6 border-r text-center',
                              {
                                'text-green-600': status === 'completed',
                                'text-yellow-500': status === 'pending'
                              }
                            )}
                          >
                            {status === 'completed'
                              ? t('O_COMPLETED')
                              : t('O_PENDING')}
                          </td>
                        </tr>
                      )
                    }
                  )}{' '}
              </>
            ) : (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-[#F2EFEF]'>
                {' '}
                <td colSpan={6} className='py-4 px-6 border-r'>
                  {t('O_NO_RECORD_FOUND')}
                </td>{' '}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default InvoiceList
