import React, { useContext, useEffect, useState } from 'react'
import { apiGet, apiPost } from 'utils/apiFetch'
import apiPath from 'utils/pathObj'
import { useTranslation } from 'react-i18next'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import AuthContext from 'context/AuthContext'
import { Link } from 'react-router-dom'
import useToastContext from 'hooks/useToastContext'
import dayjs from 'dayjs'
import { startCase } from 'lodash'
import CashIn from './CashIn'
import CashOut from './CashOut'
import CashAdd from './CashAdd'
import helpers from '../utils/helpers'
import User from './users/User'

function HomeOperator () {
  const { t } = useTranslation()
  const [isReset, setIsReset] = useState(false)
  let { logoutUser, updatePageName } = useContext(AuthContext)
  // eslint-disable-next-line no-unused-vars
  const [startDate, setStartDate] = useState()
  // eslint-disable-next-line no-unused-vars
  const [endDate, setEndDate] = useState('')
  const notification = useToastContext()
  const [dashboardDetails, setDashboardDetails] = useState({})
  const [walletDetails, setWalletDetails] = useState({})
  const [transactionDetails, setTransactionDetails] = useState([])
  const currentYear = new Date().getFullYear()
  const [showCashInModal, setShowCashInModal] = useState(false)
  const [showCashOutModal, setShowCashOutModal] = useState(false)
  const [showCashAddModal, setShowCashAddModal] = useState(false)

  const getDashboardDetails = async (year = new Date().getFullYear()) => {
    try {
      const payload = { year }
      const path = apiPath.getDashboardDetails
      const result = await apiGet(path, payload)
      setDashboardDetails({ ...dashboardDetails, ...result.data.results })
    } catch (error) {
      handleDashboardError(error)
    }
  }

  const handleDashboardError = error => {
    console.log('error:', error)
    if (error.response?.status === 401 || error.response?.status === 409) {
      logoutUser()
    }
  }

  useEffect(() => {
    getDashboardDetails(currentYear)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear])

  const getCompleteProfile = async () => {
    try {
      const path = apiPath.getCompleteProfile
      const result = await apiGet(path, {})
      setWalletDetails({ ...walletDetails, ...result.data.results })
    } catch (error) {
      console.log('error:', error)
    }
  }

  useEffect(() => {
    getCompleteProfile()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getCompleteProfile()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCashAddModal])

  const getTransaction = async () => {
    let result
    try {
      const path = apiPath.getTransaction
      result = await apiGet(path, {})
      if (result?.status === 200) {
        const response = result?.data?.results
        setTransactionDetails(response?.docs)
      }
    } catch (error) {
      console.log('error:', error)
    }
  }

  useEffect(() => {
    getTransaction()
  }, [])

  const handleDateChange = (start, end) => {
    setStartDate(start)
    setEndDate(end)
  }

  const countDigitsAfterDecimal = number => {
    if (number?.toString()?.split('.')[1]?.length > 2)
      return parseFloat(number).toFixed(2)
    return number || 0
  }
  return (
    <>
      <div className='bg-slate-100'>
      <div className='common-heading md:px-8 px-3 py-5 pb-2 flex items-center'>
        <img src='images/dashboard-icon.svg' alt='' className='mr-4' />
        <h3 className='xl:flex block items-center mb-0 text-slate-900 font-medium md:text-base'>
          {t('NAV_DASHBOARD')}{' '}
        </h3>
      </div>

      <div className='account-summary-sec py-4 px-4 md:px-8 '>
        <div class='grid grid-rows-1 grid-flow-col rounded-lg overflow-hidden bg-LightBlue '>
          <div class='col-span-12 sm:col-span-1'>
            <div className='text-white p-4 py-3 relative h-full flex items-center'>
              <div className=''>
                <span className='mb-1 block text-sm'>{t('DASHBOARD_WALLET_BALANCE')}</span>
                <h2 className='text-2xl font-bold'>
                  {countDigitsAfterDecimal(walletDetails?.wallet_amount)}{' '}
                  {t('O_LEK')}
                </h2>
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <div className='py-7 px-4 md:px-8 pt-1 '>
        <div className='sale_report grid lg:grid-cols-2 md:grid-cols-1 gap-5 sm:mb-7 mb-0  p-7'>
          <div className='grid lg:grid-cols-3 sm:grid-cols-2 gap-4'>
            <div className='text-center bg-[#fff] rounded-lg p-4 drop-shadow'>
              <Link to='' onClick={() => setShowCashAddModal(true)}>
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d1.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>
                  {t('DASHBOARD_TOP_UP_CASH_ADD')}
                </h4>
              </Link>
            </div>
            <div className='text-center bg-[#fff] rounded-lg p-4 drop-shadow'>
              <Link to='' onClick={() => setShowCashInModal(true)}>
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d1.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>
                  {t('DASHBOARD_TOP_UP_CASH_IN')}
                </h4>
              </Link>
            </div>
            <div className='text-center bg-[#fff] rounded-lg p-4 drop-shadow'>
              <Link to='' onClick={() => setShowCashOutModal(true)}>
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d2.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>
                  {t('DASHBOARD_TOP_UP_CASH_OUT')}
                </h4>
              </Link>
            </div>
            <div className='text-center bg-[#fff] rounded-lg p-4 drop-shadow'>
              <Link to='/refund' onClick={() => updatePageName('Refund')}>
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d4.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>
                  {t('DASHBOARD_REFUND')}
                </h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <User updateBalance={getCompleteProfile} />
      <div >
        <form className=' px-4 py-3'>
          <div className='col-span-2 flex flex-wrap items-center justify-between'>
            <h4 className='m-0 text-[#222] md:text-md text-lg md:text-start  md:w-auto w-full font-medium mr-12'>
              {t('DASHBOARD_AGENT_RECENT_TRANSACTION')}
            </h4>
            <div className='flex items-center lg:pt-0 pt-3 flex-wrap justify-center'>
              <ODateRangePicker
                handleDateChange={handleDateChange}
                isReset={isReset}
                setIsReset={setIsReset}
              />
            </div>
          </div>
        </form>
      </div>
      <div className='p-3 pt-0 '>
        <div className='relative'>
          <table className='border w-[1200px] lg:w-full text-left text-dark dark:text-gray-400  max-w-3/4'>
            <thead className='text-sm text-gray-600  font-normal  dark:bg-gray-700 dark:text-gray-400 border-b border-[#F2EFEF]'>
              <tr>
                <th scope='col' className='p-3 font-normal'>
                  {t('DASHBOARD_AGENT_TRANSACTION_ID')}
                </th>
                <th scope='col' className='p-3 font-normal'>
                  {t('DASHBOARD_AGENT_TRANSACTION_TYPE')}
                </th>
                <th scope='col' className='p-3 font-normal'>
                  {t('DASHBOARD_AGENT_CUSTOMER_NUMBER')} (+355)
                </th>
                <th scope='col' className='p-3 font-normal text-center'>
                  {t('O_CREATED_DATE')}
                </th>
                <th scope='col' className='p-3 font-normal text-center'>
                  {t('O_OFFER_AMOUNT')}
                </th>
              </tr>
            </thead>
            <tbody>
              {transactionDetails &&
                transactionDetails?.map(
                  (
                    {
                      transactionId,
                      transaction_type,
                      transaction_amount,
                      mobile,
                      createdAt
                    },
                    i
                  ) => (
                    <tr
                      key={i}
                      className='bg-white border-b dark:bg-gray-800 dark:border-[#F2EFEF]'
                      style={i % 2 ? { background: '#F9F9F9' } : {}}
                    >
                      <td className='py-3 px-6 text-black text-base'>
                        {transactionId}{' '}
                      </td>
                      <td className='py-3 px-6 text-black text-base'>
                        {startCase(transaction_type)}
                      </td>
                      <td className='py-3 px-6 text-black text-base'>
                        {mobile}
                      </td>
                      <td className='py-3 px-6 text-black text-base text-center'>
                        {dayjs(createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                      </td>
                      <td className='py-3 px-6 text-black text-base text-center'>
                        {transaction_amount} {t('O_LEK')}
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
      {showCashInModal && <CashIn setShowCashInModal={setShowCashInModal} />}
      {showCashOutModal && (
        <CashOut setShowCashOutModal={setShowCashOutModal} />
      )}
      {showCashAddModal && (<CashAdd setShowCashAddModal={setShowCashAddModal} />)}
      </div>
    </>
  )
}

export default HomeOperator
