import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const NotificationList = ({ notifications, page }) => {
  const { t } = useTranslation()

  return (
    <div className="p-1 md:p-3 pt-0">
      <div className="relative overflow-x-auto">
        <table className="w-max md:w-full text-left text-dark dark:text-gray-400 border">
          <thead className="text-sm text-gray-600  font-normal  dark:bg-gray-700 dark:text-gray-400 border-b border-[#F2EFEF]">
            <tr>
              <th scope="col" className="py-3 px-6 font-normal ">
                #
              </th>
              <th
                scope="col"
                className="py-3 px-6 font-normal "
              >
                {t('NOTIFICATION_TITLE')}
              </th>
              <th scope="col" className="py-3 px-6 font-normal">
                {t('NOTIFICATION_MESSAGE')}
              </th>
              <th scope="col" className="py-3 px-6 font-normal">
                <div className='flex justify-end'>
                  {t('O_CREATED_DATE')}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>{notifications && notifications?.length > 0 ?
            <>
              {notifications && notifications?.map(({
                title,
                body,
                createdAt
              }, i) => {
                return (
                  <tr
                    key={i}
                    className="bg-white border-b dark:bg-gray-800 dark:border-[#F2EFEF]"
                    style={i % 2 ? { background: "#F9F9F9" } : {}}
                  >
                    <td className={i % 2 ? "py-3 px-6 text-base text-black" : " py-3 px-6 text-base text-white"}>
                      {i + 1 + 10 * (page - 1)}
                    </td>
                    <td className={i % 2 ? "py-3 px-6 text-base text-black" : " py-3 px-6 text-base text-white"}>
                      {title}
                    </td>
                    <td className={i % 2 ? "py-3 px-6 text-base text-black" : " py-3 px-6 text-base text-white"}>
                      {body}
                    </td>
                    <td className={i % 2 ? "py-3 px-6 text-base text-black text-right" : " py-3 px-6 text-base text-white text-right"}>
                      {dayjs(createdAt).format("DD-MM-YYYY hh:mm A")}{" "}
                    </td>
                  </tr>
                );
              })} </>
            : <tr > <td colSpan={5} className="text-center">{t('O_NO_RECORD_FOUND')}</td> </tr>}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default NotificationList