import React, { useState, useEffect } from 'react'
import { apiPost } from 'utils/apiFetch'
import apiPath from 'utils/pathObj'
import { useForm } from 'react-hook-form'
import ErrorMessage from 'components/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
import OInputField from 'components/reusable/OInputField'
import { useTranslation } from 'react-i18next'
import { validationRules } from 'utils/constants'
import { preventMax } from 'utils/validations'
import OtpInput from 'react18-input-otp'
import OButton from 'components/reusable/OButton'
import { startCase } from 'lodash'

const CashOut = ({ setShowCashOutModal }) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const notification = useToastContext()
  const [cashInLoading, setCashInLoading] = useState(false)
  const [cashPreview, setCashPreview] = useState(false)
  const [cashPreviewOtp, setCashPreviewOtp] = useState(false)
  const [cashPreviewData, setCashPreviewData] = useState({})
  var isTimer =
    Number(localStorage.getItem('seconds')) > 0
      ? Number(localStorage.getItem('seconds'))
      : 60
  const [seconds, setSeconds] = useState(isTimer)
  const [minutes, setMinutes] = useState('00')
  const [timerActive, setTimerActive] = useState(true)
  const [otp, setOtp] = useState(null)
  const [otpError, setOtpError] = useState('')
  const handleOTPChange = enteredOtp => {
    setOtp(enteredOtp)
  }

  const handleSubmitForm = async data => {
    if (!otp) {
      setOtpError('Please enter OTP.')
      return
    } else if (otp.length < 4) {
      setOtpError('Please enter OTP.')
      return
    } else {
      setOtpError('')
    }
    try {
      setCashInLoading(true)
      const sendData = {}
      let input = data?.mobile
      if (input === data?.mobile) {
        sendData.countryCode = 355
        sendData.mobile = input
        sendData.amount = Number(data.amount)
        sendData.otp = otp
      }
      const path = `${apiPath.AddCashOut}?type=mobile`
      const res = await apiPost(path, sendData)
      if (res.data.success === true) {
        setShowCashOutModal(false)
        notification.success(res.data.message)
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setCashInLoading(false)
    }
  }

  const handleSubmitPreview = async data => {
    try {
      setCashInLoading(true)

      const sendData = {}
      let input = data?.mobile
      if (input === data?.mobile) {
        sendData.countryCode = 355
        sendData.mobile = input
        sendData.amount = Number(data.amount)
        sendData.type = 'mobile'
      }
      const path = `${apiPath.cashOutPreview}?type=mobile`
      const res = await apiPost(path, sendData)
      if (res?.data?.success === true) {
        setCashPreviewData(res?.data?.results)
        setCashPreview(true)
        notification.success(res.data.message)
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setCashInLoading(false)
    }
  }

  const handleSubmitOtp = async data => {
    try {
      setCashInLoading(true)
      const sendData = {}
      let input = data?.mobile
      if (input === data?.mobile) {
        sendData.countryCode = 355
        sendData.mobile = input
        sendData.amount = Number(data.amount)
        sendData.type = 'mobile'
      }
      const path = `${apiPath.cashOutSendOtp}?type=mobile`
      const res = await apiPost(path, sendData)
      if (res?.data?.success === true) {
        setCashPreviewOtp(true)
        notification.success(res.data.message)
        localStorage.setItem('seconds', 60)
      } else {
        setShowCashOutModal(false)
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setCashInLoading(false)
    }
  }
  const handleResendOTP = async data => {
    try {
      if (minutes === 0 && seconds === 0) {
        let sendData = {}
        let input = data?.mobile
        if (input === data?.mobile) {
          sendData.countryCode = 355
          sendData.mobile = input
          sendData.amount = Number(data.amount)
          sendData.type = 'mobile'
        }
        sendData.resend = true
        const res = await apiPost(
          `${apiPath.cashOutSendOtp}?type=mobile`,
          sendData
        )
        if (res.data.success === true) {
          notification.success(res.data.message)
          setSeconds(60)
          setMinutes(0)
        } else {
          notification.error(res.data.message)
        }
      }
    } catch (err) {
      console.log('err:', err)
    }
  }
  let timerRef
  useEffect(() => {
    if (cashPreviewOtp) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timerRef = setInterval(() => {
        if (minutes >= 0) {
          if (seconds === 0) {
            if (minutes > 0) {
              setSeconds(60)
              setMinutes(prev => prev - 1)
            } else {
              setSeconds(0)
            }
          } else {
            setSeconds(prev => prev - 1)
          }
        } else {
          clearInterval(timerRef)
          setTimerActive(false)
        }
      }, 1000)
      return () => clearInterval(timerRef)
    }
  })

  localStorage.setItem('seconds', seconds)

  const clearTime = () => {
    setSeconds(0)
    localStorage.setItem('seconds', 0)
    setShowCashOutModal(false)
  }

  useEffect(() => {
    if (cashPreviewOtp) {
      window.onbeforeunload = function (event) {
        clearTime()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashPreviewOtp])

  const handleAmount = e => {
    var value = e.target.value
    const pattern = /^\d{0,5}(\.\d{0,2})?$/
    if (e.target.value === '') {
      e.target.value = e.target.value.slice(e.target.value?.length - 1, -1)
    }
    const isValid = pattern.test(value)
    if (!isValid) {
      value = value.slice(0, -1)
    }
    setValue('amount', value)
  }
  var pressedKeys = []

  return (
    <>
      <div
        id='add-agent'
        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
        aria-labelledby='drawer-label'
      >
        <div>
          <button
            onClick={() => setShowCashOutModal(false)}
            title='Close'
            type='button'
            data-drawer-hide='drawer-example'
            aria-controls='drawer-example'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <img src='./images/close-circle.png' alt='close' />

            <span className='sr-only'>{t('CLOSE_MENU')}</span>
          </button>
        </div>
        <h3 className='font-medium text-[18px] mb-4 '>{t('CASH_OUT_TOP_UP')}</h3>
        <div className='flex my-5 gap-4'>
          <div class='relative w-full col-span-1'>
            <OInputField
              greyClass
              name='country_code'
              inputLabel={<>{t('O_COUNTRY_CODE')}</>}
              errors={errors}
              disabled={true}
              type='select'
              register={register('country_code', {})}
              selectOptions={[
                <option value='' key='select_code'>
                  +355
                </option>,
                <option value='355' key='355'>
                  +355
                </option>
              ]}
            />
          </div>

          <div className='relative w-full '>
            <OInputField
              name='mobile'
              inputLabel={
                <>
                  {t('O_MOBILE_NUMBER')}
                  <span className='text-red-500'>*</span>
                </>
              }
              errors={errors}
              type='number'
              onKeyDown={e => {
                if (['-', '+', 'e', '.'].includes(e.key)) {
                  e.preventDefault()
                }
              }}
              onInput={e => preventMax(e, 9)}
              register={register('mobile', {
                required: t('PLEASE_ENTER_MOBILE_NUMBER'),
                minLength: {
                  value: 9,
                  message: t('MINIMUM_LENGTH_SHOULD_BE_9_DIGITS')
                },
                maxLength: {
                  value: 9,
                  message: t('MAXIMUM_LENGTH_SHOULD_BE_9_DIGITS')
                },
                pattern: {
                  value: validationRules.numbers,
                  message: t('INVALID_MOBILE_NUMBER')
                },
                min: {
                  value: 0,
                  message: t('VALUE_CAN_NOT_BE_LESS_THAN_0')
                }
              })}
            />
          </div>
        </div>
        <div className='relative  my-5'>
          <OInputField
            name='amount'
            errors={errors}
            inputLabel={
              <>
                {t('CASH_OUT_RECEIVED_AMOUNT')}
                <span className='text-red-500'>*</span>
              </>
            }
            type='number'
            register={register('amount', {
              required: t('PLEASE_ENTER_RECEIVED_AMOUNT'),
              onChange: e => handleAmount(e),
              validate: value => {
                return value !== '0'
                  ? true
                  : t('VALUE_CAN_NOT_BE_LESS_THAN_0.01')
              }
            })}
            onKeyDown={e => {
              pressedKeys.push(e.key)
              var lastKey = pressedKeys[pressedKeys.length - 2]
              if (lastKey === '.') {
                if (['-', '.', '+', 'e'].includes(e.key)) {
                  e.preventDefault()
                }
              } else if (['-', '+', 'e'].includes(e.key)) {
                e.preventDefault()
              }
            }}
          />
        </div>
        <div></div>
        <button
          class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
          type='submit'
          onClick={handleSubmit(handleSubmitPreview)}
        >
          {cashInLoading ? 'Loading...' : <>{t('O_SUBMIT')}</>}
        </button>
        {cashPreview ? (
          <>
            <div
              id='add-agent'
              className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80  right-0 top-0 '
              aria-labelledby='drawer-label'
            >
              <div>
                <button
                  onClick={() => setShowCashOutModal(false)}
                  title='Close'
                  type='button'
                  data-drawer-hide='drawer-example'
                  aria-controls='drawer-example'
                  className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                >
                  <img src='./images/close-circle.png' alt='close' />

                  <span className='sr-only'>{t('CLOSE_MENU')}</span>
                </button>
              </div>
              <div className='flex items-center w-full justify-center mb-4'>
                <div className='relative text-center '>
                  <h3 className='font-medium text-[18px] mb-4'>
                    {t('O_CUSTOMER_WILL_GET')} {t('O_LEK')}{' '}
                    {cashPreviewData.amountToBeReceived}{' '}
                    {t('CASH_IN_WALLET_TRANSFER')} {t('O_LEK')}{' '}
                    {cashPreviewData.amount}.
                  </h3>
                </div>
              </div>
              <div className='flex justify-between items-center'>
                <button
                  class='bg-LightBlue hover:bg-DarkBlue cursor-pointer text-white  font-medium active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
                  onClick={() => setShowCashOutModal(false)}
                >
                  {t('CASH_IN_CANCEL')}
                </button>
                <button
                  class='bg-LightBlue hover:bg-DarkBlue cursor-pointer text-white  font-medium active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
                  type='submit'
                  onClick={handleSubmit(handleSubmitOtp)}
                >
                  {t('CASH_IN_OK')}
                </button>
                {cashPreviewOtp ? (
                  <div
                    id='add-agent'
                    className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
                    aria-labelledby='drawer-label'
                  >
                    <div>
                      <button
                        onClick={clearTime}
                        title='Close'
                        type='button'
                        data-drawer-hide='drawer-example'
                        aria-controls='drawer-example'
                        className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                      >
                        <img src='./images/close-circle.png' alt='close' />

                        <span className='sr-only'>{t('CLOSE_MENU')}</span>
                      </button>
                    </div>
                    <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded'>
                      <div className=' py-5 flex-auto'>
                        <section className='w-full'>
                          <div className='w-full flex justify-between'>
                            <div className='font-semibold'>
                              {t('O_VERIFICATION_CODE')}
                            </div>{' '}
                          </div>
                          <div className='font-semibold mt-4 '>
                            {t('O_ENTER_OTP')}
                          </div>
                          <OtpInput
                            inputStyle={{
                              border: 'solid 1px #dfdfdf',
                              width: '5rem',
                              height: '2.4rem',
                              marginRight: '.5rem',
                              outline: 'none',
                              borderRadius: '8px'
                            }}
                            shouldAutoFocus
                            containerStyle='text-center mt-2'
                            value={otp}
                            onChange={handleOTPChange}
                            isInputNum={true}
                            numInputs={4}
                            separator={<span> </span>}
                          />
                          <ErrorMessage message={otpError} />
                          <OButton
                            label={startCase('Verify')}
                            extraClasses='w-full mt-4'
                            type='button'
                            onClick={handleSubmit(handleSubmitForm)}
                            disabled={!timerActive}
                          />
                          <div className='text-xs mt-5 text-center'>
                            {t('O_DID_NOT_RECEIVE_OTP')}{' '}
                            <span
                              className='text-violet-700'
                              onClick={handleSubmit(handleResendOTP)}
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              <span
                                style={{
                                  color: seconds === 0 ? 'blue' : 'gray'
                                }}
                              >
                                {' '}
                                {t('O_RESEND_OTP')}{' '}
                              </span>
                              ( {t('O_EXPIRING_IN')} {minutes}:
                              {seconds < 10 ? 0 + seconds : seconds})
                            </span>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>

      <div class='bg-gray-900  bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 overlay' />
    </>
  )
}

export default CashOut
