import React from 'react'
import dayjs from 'dayjs'
import { isEmpty, startCase } from 'lodash'
import { useTranslation } from 'react-i18next'

const Table = ({ transactions, page }) => {
  const { t } = useTranslation()
  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] uppercase bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('NAV_USER_NAME')} ({t('O_MOBILE')})
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('O_MERCHANT_NAME')} ({t('O_MOBILE')})
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('NAV_TRANSACTION_ACTIVITY')}
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('NAV_MODE_OF_PAYMENT')}
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('O_CREATED_AT')}
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions &&
              transactions?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-4 px-6 border-r'>
                    {item?.user_id.first_name} {item?.user_id.last_name}{' '}
                    {`(${item?.user_id.mobile})`}
                  </td>
                  <td className='py-4 px-6 border-r'>
                    {item?.merchant_id.business_name}{' '}
                    {`(${item?.merchant_id?.contact_no})`}
                  </td>
                  <td className='py-4 px-6 border-r'>
                    {startCase(item?.transaction_type)}
                  </td>
                  <td className='py-4 px-6 border-r'>
                    {startCase(item?.payment_mode)}
                  </td>
                  <td className='py-4 px-6 border-r'>
                    {dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                  </td>
                </tr>
              ))}
            {isEmpty(transactions) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={6}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
