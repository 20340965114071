import { useTranslation } from 'react-i18next'

const UseChange = ({ data }) => {
  let { t } = useTranslation()
  return t(data)
}
export const generateRandomID = length => {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const containsNumbers = str => {
  return /\d/.test(str)
}

export const validateFile = file => {
  let file_error_msg = ''
  const supportedTypes = ['image/jpeg', 'image/jpg', 'image/png']
  if (!supportedTypes.includes(file.type)) {
    file_error_msg = (
      <>{<UseChange data='ONLY_JPEG_JPG_AND_PNG_ARE_SUPPORTED' />}</>
    )
  }

  return file_error_msg
}

export const supportedImageTypes = ['image/jpeg', 'image/jpg', 'image/png']
