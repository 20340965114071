import { useRoutes } from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from 'context/AuthContext'
// routes
import MainRoutes from './MainRoutes'
import MerchantRoutes from './MerchantRoutes'
import AgentRoutes from './AgentRoutes'
import OperatorRoutes from './OperatorRouter'

// ==============================|| ROUTING RENDER ||============================== //
export default function CombineRoutes () {
  let { user } = useContext(AuthContext)

  const checkRoles = {
    merchant: MerchantRoutes,
    agent: AgentRoutes,
    operator: OperatorRoutes
  }

  return useRoutes(
    [user ? checkRoles[user?.role] : MainRoutes],
    process.env.BASENAME
  )
}
