import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from 'layout/Loader'
import ErrorMessage from 'components/ErrorMessage'
import { apiPost } from 'utils/apiFetch'
import pathObj from 'utils/pathObj'
import useToastContext from 'hooks/useToastContext'
import OButton from 'components/reusable/OButton'
import { useTranslation } from 'react-i18next'

function ResetPassword () {
  let { resetToken } = useParams()
  const { t } = useTranslation()
  const notification = useToastContext()

  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty }
  } = useForm({ mode: 'onBlur', shouldFocusError: true, defaultValues: {} })
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false)
  const onSubmit = async data => {
    let res
    try {
      setResetPasswordLoading(true)
      res = await apiPost(pathObj.resetPassword + '/' + resetToken, {
        password: data.password
      })
      if (res.data.success) {
        navigate('/')
        notification.success('Password successfully changed')
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setResetPasswordLoading(false)
    }
  }

  return (
    <div className='login-page h-full'>
      <Loader />
      <div className='p-4'>
        <div className='login-form bg-white max-w-lg m-auto mt-10 sm:mt-16 md:mt-28 rounded-[20px]'>
          <form
            className='sm:py-12 sm:px-11 py-8 px-7'
            onSubmit={handleSubmit(onSubmit)}
            method='post'
          >
            <img src='/images/logo.png' alt='' className='m-auto py-2' />
            <h1 className='text-center text-[40px] font-bold mb-6'>
              {t('RESET_PASSWORD')}
            </h1>
            <div className='relative z-0 mb-6 w-full group'>
              <input
                type='password'
                name='password'
                id='password'
                className='block py-4 px-3 w-full password-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                {...register('password', {
                  required: t('PASSWORD_IS_REQUIRED'),
                  pattern: {
                    value:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
                    message: t('PASSWORD_MUST_CONTAIN_LOWERCASE_UPPERCASE_')
                  }
                })}
              />

              <label
                for='password'
                className='peer-focus:font-normal absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
              >
                {t('PASSWORD')}
              </label>
              <ErrorMessage message={errors?.password?.message} />
            </div>

            <div className='relative z-0 mb-6 w-full group'>
              <input
                type='password'
                name='confirm_password'
                id='confirm_password'
                className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                {...register('confirm_password', {
                  required: t('CONFIRM_PASSWORD_IS_REQUIRED'),
                  validate: val => {
                    if (watch('password') !== val) {
                      return t('YOUR_PASSWORD_DO_NOT_MATCH')
                    }
                  }
                })}
              />

              <label
                for='confirm_password'
                className='peer-focus:font-normal absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
              >
                {t('CONFIRM_PASSWORD')}
              </label>
              <ErrorMessage message={errors?.confirm_password?.message} />
            </div>

            <div className='text-center mt-8'>
              <OButton
                disabled={!isDirty}
                label='Submit'
                type='submit'
                loading={resetPasswordLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
