import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import OInputField from 'components/reusable/OInputField'
import { useTranslation } from 'react-i18next'

const SplitBillGroup = ({
  setShowGroupModal,
  setShowParticipantModal,
  setSendData,
  sendData
}) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {}
  })

  const handleSubmitForm = async ({ name, amount }) => {
    try {
      setSendData({
        name,
        amount,
        participantId: sendData?.participantId
      })
      setShowGroupModal(false)
      setShowParticipantModal(true)
    } catch (err) {
      console.log('err:', err)
    }
  }
  const preventMaxValueName = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 15) {
      e.target.value = e.target.value.slice(0, 15)
    }
  }
  const handleAmount = e => {
    var value = e.target.value
    const pattern = /^\d{0,5}(\.\d{0,2})?$/
    if (e.target.value === '') {
      e.target.value = e.target.value.slice(e.target.value?.length - 1, -1)
    }
    const isValid = pattern.test(value)
    if (!isValid) {
      value = value.slice(0, -1)
    }
    setValue('amount', value)
  }
  var pressedKeys = []

  useEffect(() => {
    if (Object.keys(sendData).length !== 0 && sendData !== undefined) {
      setValue('name', sendData.name ?? '')
      setValue('amount', sendData.amount ?? '')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div
        id='add-offer'
        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
        aria-labelledby='drawer-label'
      >
        <div>
          <button
            onClick={() => setShowGroupModal(false)}
            title={t('O_CLOSE')}
            type='button'
            data-drawer-hide='drawer-example'
            aria-controls='drawer-example'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <img src='./images/close-circle.png' alt='close' />

            <span className='sr-only'>{t('CLOSE_MENU')}</span>
          </button>
        </div>
        <h3 className='font-medium text-[18px] mb-4'>{t('ADD_NEW_SPLIT')}</h3>
        <form>
          <div className='relative my-5'>
            <OInputField
              type='text'
              name='name'
              inputLabel={
                <>
                  {t('GROUP_NAME')}
                  <span className='text-red-500'>*</span>
                </>
              }
              autoFocus
              maxLength={15}
              onInput={e => preventMaxValueName(e)}
              register={register('name', {
                required: t('PLEASE_ENTER_GROUP_NAME'),
                minLength: {
                  value: 3,
                  message: t('MINIMUM_LENGTH_MUST_BE_3')
                },
                maxLength: {
                  value: 15,
                  message: t('MAXIMUM_LENGTH_MUST_BE_15')
                }
              })}
              errors={errors}
            />
          </div>
          <div className='relative  my-5'>
            <OInputField
              name='amount'
              inputLabel={
                <>
                  {t('AMOUNT')}
                  <span className='text-red-500'>*</span>
                </>
              }
              type='number'
              errors={errors}
              step='any'
              register={register('amount', {
                required: t('PLEASE_ENTER_AMOUNT'),
                onChange: e => handleAmount(e),
                validate: value => {
                  return value !== '0'
                    ? true
                    : t('VALUE_CAN_NOT_BE_LESS_THAN_0.01')
                }
              })}
              onKeyDown={e => {
                pressedKeys.push(e.key)
                var lastKey = pressedKeys[pressedKeys.length - 2]
                if (lastKey === '.') {
                  if (['-', '.', '+', 'e'].includes(e.key)) {
                    e.preventDefault()
                  }
                } else if (['-', '+', 'e'].includes(e.key)) {
                  e.preventDefault()
                }
              }}
            />
          </div>
          <button
            class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
            type='submit'
            onClick={handleSubmit(handleSubmitForm)}
          >
            {t('O_NEXT')}
          </button>
        </form>
      </div>
      <div class='bg-gray-900  bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 overlay' />
    </div>
  )
}

export default SplitBillGroup
