import CompleteProfile from 'pages/auth/CompleteProfile'
import ForgotPassword from 'pages/auth/ForgotPassword'
import OTP from 'pages/auth/OTP'
import ResetPassword from 'pages/auth/ResetPassword'
import Login from 'pages/Login'
import Careers from 'pages/static_content/Careers'
import PrivatePolicy from 'pages/static_content/PrivatePolicy'
import OtpScreen from 'pages/auth/OtpScreen'
import { HomeRedirect } from './HomeRedirect'
import FAQ from 'pages/static_content/FAQ'
import LoginOTP from 'pages/auth/LoginOTP'

const MainRoutes = {
  // All common routes
  path: '/',
  children: [
    {
      path: '/',
      element: <Login />
    },
    // {
    //     path: '/:type',
    //     element: <Login />
    // },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/otp',
      element: <OTP />
    },
    {
      path: '/login-otp',
      element: <LoginOTP />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/reset-password/:resetToken',
      element: <ResetPassword />
    },
    {
      path: '/contact',
      element: <Careers />
    },
    {
      path: '/about-us',
      element: <PrivatePolicy />
    },
    {
      path: '/faqs',
      element: <FAQ />
    },
    {
      path: '/contact-us',
      element: <PrivatePolicy />
    },
    {
      path: '/terms-and-conditions',
      element: <PrivatePolicy />
    },
    {
      path: '/privacy-policy',
      element: <PrivatePolicy />
    },
    {
      path: '*',
      element: <HomeRedirect to='/' />
    },
    {
      path: '/complete-profile',
      element: <CompleteProfile />
    },
    {
      path: '/otp-verification',
      element: <OtpScreen />
    }
  ]
}

export default MainRoutes
