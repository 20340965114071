import React from 'react'
import { apiPut, apiDelete } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { FaPassport } from 'react-icons/fa'
import { AiFillIdcard, AiFillEdit, AiFillDelete } from 'react-icons/ai'
import useToastContext from 'hooks/useToastContext'

import { useTranslation } from 'react-i18next'

const MerchantTable = ({
  merchants,
  getAllMerchants,
  handelEdit,
  user,
  page
}) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const handelStatusChange = item => async e => {
    const myState = e.target.checked
      ? 'Active the status?'
      : 'Inactive the status?'
    try {
      if (window.confirm(`Are you sure to ${myState}`)) {
        const payload = {
          status: e.target.checked ? 'active' : 'inactive'
        }
        const path = `${apiPath.changeMerchantStatus}/${item?._id}`
        const result = await apiPut(path, payload)
        if (result?.status === 200) {
          getAllMerchants()
          notification.success(result.data.message)
        }
      }
    } catch (error) {
      console.log('error in get all merchants list==>>>>', error.message)
    }
  }

  const handelVerifiedChange = item => async e => {
    try {
      if (window.confirm('Are you sure to verify KYC?')) {
        const payload = {
          kyc_verified: !!e.target.value
        }
        const path = `${apiPath.changeMerchantVerified}/${item?._id}`
        const result = await apiPut(path, payload)
        if (result?.status === 200) {
          getAllMerchants()
          notification.success(result.data.message)
        }
      }
    } catch (error) {
      console.log('error in get all merchants list==>>>>', error.message)
    }
  }

  const handelDelete = async item => {
    if (window.confirm(`${t('ARE_YOU_SURE_YOU_WANT_TO_DELETE')}?`)) {
      let result
      try {
        const path = apiPath.deleteMerchant + '/' + item?._id
        result = await apiDelete(path)
        if (result?.status === 200) {
          getAllMerchants({ deletePage: 1 })
          notification.success(result.data.message)
        }
      } catch (error) {
        console.log('error in get all users list==>>>>', error.message)
      }
    }
  }

  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] uppercase bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('MERCHANT_BUSINESS_NAME')}
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('O_EMAIL_ID')}
              </th>
              <th scope='col' className='py-3 px-6'>
                (+355) {t('O_MOBILE')}
              </th>

              <th scope='col' className='py-3 px-6'>
                {t('MERCHANT_DOCUMENTS')}
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('MERCHANT_DATE_OF_REGISTRATION')}
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('O_STATUS')}
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('MERCHANT_KYC_VERIFIED')}
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('O_ACTION')}
              </th>
            </tr>
          </thead>
          <tbody>
            {merchants &&
              merchants?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-4 px-6 border-r'>{item?.business_name} </td>
                  <td className='py-4 px-6 border-r'>{item?.email}</td>
                  <td className='py-4 px-6 border-r'>{item?.mobile}</td>

                  <td className='py-4 px-6 border-r uppercase flex'>
                    <a
                      href={item?.passport}
                      title={t('O_PASSPORT')}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FaPassport className='h-7 w-7' />
                    </a>
                    <a
                      href={item?.identity_card}
                      title={t('MERCHANT_ID_CARD')}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <AiFillIdcard className='h-7 ml-2 w-7' />
                    </a>
                  </td>
                  <td className='py-4 px-6 border-r'>
                    {dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                  </td>
                  <td className='py-4 px-6 border-r'>
                    <label
                      className='inline-flex relative items-center cursor-pointer'
                      title={t('O_STATUS')}
                    >
                      <input
                        type='checkbox'
                        // value={status}
                        className='sr-only peer'
                        checked={item?.status === 'active'}
                        onChange={handelStatusChange(item)}
                      />
                      <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
                    </label>
                  </td>
                  <td className='py-4 px-6 border-r'>
                    <div className='cursor-pointer'>
                      {item?.kyc_verified === true ? (
                        <span href='#' style={{ color: 'green' }}>
                          {t('MERCHANT_VERIFIED')}
                        </span>
                      ) : (
                        <span
                          onClick={handelVerifiedChange(item)}
                          href='#'
                          style={{ color: 'red' }}
                        >
                          {t('MERCHANT_VERIFY')}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className='py-4 px-6 border-l'>
                    <div className=''>
                      <ul className='flex'>
                        {(user?.permission?.[4]?.add ||
                          user.permission?.length === 0) && (
                          <li
                            onClick={() => handelEdit(item)}
                            className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                          >
                            <span title={t('O_EDIT')}>
                              {' '}
                              <AiFillEdit className='cursor-pointer w-5 h-5 text-slate-600' />
                            </span>
                          </li>
                        )}

                        {(user?.permission?.[4]?.delete ||
                          user.permission?.length === 0) && (
                          <li
                            onClick={() => handelDelete(item)}
                            className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                          >
                            <span title={t('O_DELETE')}>
                              {' '}
                              <AiFillDelete className='cursor-pointer w-5 h-5 text-red-600' />{' '}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            {isEmpty(merchants) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={8}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default MerchantTable
