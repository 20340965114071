export const validations = {
    text:{
        minLength:{
            value:2,
            message:'Minimum length must be 2.'
        }
    },
    name:{
        minLength:{
            value:2,
            message:'Minimum length must be 2.'
        },

    }
}

export const preventMax = (e,limit) => {
    if (e.target.value.length > limit) {
      e.target.value = e.target.value.slice(0, limit)
    }
  }