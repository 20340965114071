import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import Table from './Table'
import UserAdd from './UserAdd'
import UserEdit from './UserEdit'
import Pagination from '../Pagination'
import UserView from './UserView'
import AuthContext from 'context/AuthContext'
import dayjs from 'dayjs'
import CashTransfer from '../CashTransfer'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { useTranslation } from 'react-i18next'

function User ({updateBalance}) {
  const { t } = useTranslation()
  const { logoutUser, user, updatePageName } = useContext(AuthContext)
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [addShowModal, setShowModal] = useState(false)
  const [editShowModal, setEditShowModal] = useState(false)
  const [viewShowModal, setViewShowModal] = useState(false)
  const [transferMoneyShowModal, setTransferMoneyShowModal] = useState(false)
  const [users, setAllUser] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [item, setItem] = useState('')
  const [isDelete, setIsDelete] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [isInitialized, setIsInitialized] = useState(false)
  const [filterData, setFilterData] = useState({
    category: '',
    searchkey: '',
    startDate: '',
    endDate: '',
    isReset: false,
    isFilter: false,
    userType: ''
  })
  const [sort, setSort] = useState({
    sort_key: 'createdAt',
    sort_type: 'desc'
  })

  // get all user function-
  const getAllUser = async data => {
    try {
      const { category, startDate, endDate, searchkey, isFilter, userType } =
        filterData
      if (
        (data?.deletePage && users?.length >= 1) ||
        (isFilter && category && data?.statusChange && users?.length >= 1)
      ) {
        if (users?.length >= 1) {
          setPage(page - 1)
          setIsDelete(true)
          setPaginationObj({ ...paginationObj, page: page - 1 })
        }
      } else {
        setIsDelete(false)
      }
      const payload = {
        page,
        pageSize,
        userType,
        status: category,
        start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
        keyword: searchkey,
        sort_key: sort.sort_key,
        sort_type: sort.sort_type
      }

      const path = apiPath.getUsers
      const result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        const resultStatus = result?.data?.success
        console.log(response?.docs)
        setAllUser(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: resultStatus ? response.page : null,
          pageCount: resultStatus ? response.totalPages : null,
          perPageItem: resultStatus ? response?.docs.length : null,
          totalItems: resultStatus ? response.totalDocs : null
        })
      }
    } catch (error) {
      console.log('error ', error)
      setPaginationObj({})
      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handelEdit = item => {
    setItem(item)
    setEditShowModal(true)
  }

  const handleUserView = item => {
    setItem(item)
    setViewShowModal(true)
  }

  const handleUserTransferMoney = item => {
    setItem(item)
    setTransferMoneyShowModal(true)
  }

  useEffect(() => {
    getAllUser()
    updateBalance()
  }, [transferMoneyShowModal])

  useEffect(() => {
    getAllUser()
  }, [page, filterData, sort])

  useEffect(() => {
    updatePageName(t('O_USERS'))
  }, [])

  const handleReset = () => {
    setFilterData({
      category: '',
      searchkey: '',
      startDate: '',
      endDate: '',
      isReset: true,
      isFilter: false,
      userType: ''
    })
    setPage(1)
    setIsDelete(true)
    setSearchTerm('')
  }
  const handleDateChange = (start, end) => {
    setPage(1)
    setFilterData({
      ...filterData,
      startDate: start,
      endDate: end,
      isFilter: true,
      isReset: false
    })
    setIsDelete(true)
  }
  const statusPage = e => {
    setFilterData({
      ...filterData,
      category: e.target.value,
      isFilter: true,
      isReset: false
    })
    setPage(1)
    setIsDelete(true)
  }
  const setUserType = e => {
    setFilterData({
      ...filterData,
      userType: e.target.value,
      isFilter: true,
      isReset: false
    })
    setPage(1)
    setIsDelete(true)
  }

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true)
    } else if (searchTerm || !filterData?.isReset) {
      setFilterData({
        ...filterData,
        isReset: false,
        searchkey: debouncedSearchTerm ? debouncedSearchTerm : '',
        isFilter: debouncedSearchTerm ? true : false
      })
      setPage(1)
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchTerm])

  return (
    <div>
      <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg'>
            <form className='border-b border-b-[#E3E3E3]  px-4 py-3 pt-5 flex flex-wrap justify-between'>
              <div className='flex flex-wrap  items-center'>
                <div className='flex items-center lg:pt-0 pt-3 justify-center'>
                  <ODateRangePicker
                    handleDateChange={handleDateChange}
                    isReset={filterData?.isReset}
                    setIsReset={setFilterData}
                  />
                  <div className='flex items-center mb-3 ml-3'>
                    <select
                      id='countries'
                      type=' password'
                      name='floating_password'
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#DFDFDF] dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      value={filterData?.category}
                      onChange={e => statusPage(e)}
                    >
                      <option defaultValue value=''>
                        {t('O_ALL')}
                      </option>
                      <option value='active'>{t('O_ACTIVE')}</option>
                      <option value='inactive'>{t('O_INACTIVE')}</option>
                    </select>
                    <select
                      id='countries'
                      type=' password'
                      name='floating_password'
                      className='block mx-2 p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#DFDFDF]  dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      value={filterData?.userType}
                      onChange={e => setUserType(e)}
                    >
                      <option defaultValue value=''>
                        {t('O_ALL')}
                      </option>
                      <option value='individual'>{t('INDIVIDUAL')}</option>
                      <option value='business'>{t('BUSINESS')}</option>
                    </select>
                  </div>
                  <button
                    type='button'
                    onClick={() => handleReset()}
                    className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                  >
                    {t('O_RESET')}
                  </button>
                </div>
              </div>
              <div className='flex items-center md:justify-end px-4'>
                <label
                  htmlFor='default-search'
                  className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
                >
                  {t('O_SEARCH')}
                </label>
                <div className='flex'>
                  <div className='relative '>
                    <div className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'>
                      {!searchTerm ? (
                        <svg
                          aria-hidden='true'
                          className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                          fill='none'
                          stroke='currentColor'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                          />
                        </svg>
                      ) : (
                        ''
                      )}
                    </div>
                    <input
                      type='search'
                      id='default-search'
                      className='block w-full p-2 outline-none text-sm text-gray-900 2xl:min-w-[250px] xl:min-w-[300px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder={t('O_SEARCH_BY_NAME_EMAIL_MOBILE')}
                      value={searchTerm}
                      title=''
                      required
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <button
                      title={t('USERS_ADD_USER')}
                      type='button'
                      className='bg-LightBlue flex text-sm px-8 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue whitespace-nowrap'
                      onClick={() => setShowModal(true)}
                    >
                      + {t('USERS_ADD_USER')}
                    </button>
                </div>
              </div>
            </form>
            <Table
              users={users}
              user={user}
              getAllUser={getAllUser}
              handelEdit={handelEdit}
              handleUserView={handleUserView}
              handleUserTransferMoney={handleUserTransferMoney}
              page={page}
              setSort={setSort}
              sort={sort}
              setPage={setPage}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                isDelete={isDelete}
                page={page}
              />
            ) : null}
          </div>
        </div>
      <>
        {addShowModal ? (
          <UserAdd
            setShowModal={setShowModal}
            getAllUser={getAllUser}
            setPage={setPage}
          />
        ) : null}
        {editShowModal ? (
          <UserEdit
            setEditShowModal={setEditShowModal}
            getAllUser={getAllUser}
            item={item}
          />
        ) : null}
        {viewShowModal ? (
          <UserView
            setViewShowModal={setViewShowModal}
            getAllUser={getAllUser}
            item={item}
          />
        ) : null}

        {transferMoneyShowModal ? (
            <CashTransfer sender={item} setTransferMoneyShowModal={setTransferMoneyShowModal} />
        ): null}
      </>
    </div>
  )
}
export default User
