import React, { useState } from 'react'
import { apiPost } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import ErrorMessage from 'components/ErrorMessage'
import { BiArrowBack } from 'react-icons/bi'

const SplitBillRequest = ({
  setShowSplitBillRequestModal,
  participantData,
  setParticipantData,
  sendData,
  getAllSplitBills,
  setShowParticipantModal
}) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const [splitBillRequestLoading, setSplitBillRequestLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {}
  })

  const handleSubmitForm = async data => {
    try {
      setSplitBillRequestLoading(true)
      const payloadData = {
        name: sendData.name,
        amount: sendData.amount,
        participantId: sendData.participantId,
        note: data?.note
      }
      const res = await apiPost(
        apiPath.merchantSplitBillPaymentSendRequest,
        payloadData
      )
      if (res.data.success === true) {
        setParticipantData([])
        setShowSplitBillRequestModal(false)
        notification.success(res.data.message)
      } else {
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setSplitBillRequestLoading(false)
      getAllSplitBills()
    }
  }
  const preventMaxValueDescription = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 200) {
      e.target.value = e.target.value.slice(0, 200)
    }
  }

  return (
    <div>
      <div
        id='add-offer'
        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
        aria-labelledby='drawer-label'
      >
        <div>
          <span
            title={t('O_BACK')}
            className='hover:text-blue-700 transition duration-150 ease-in-out'
            data-bs-toggle='tooltip'
          >
            {' '}
            <BiArrowBack
              className='h-8 w-8 border text-white py-1 rounded-md bg-[#6236FF] cursor-pointer mb-2'
              onClick={() => {
                setShowParticipantModal(true)
                setShowSplitBillRequestModal(false)
              }}
            />
          </span>
          <button
            onClick={() => {
              setShowSplitBillRequestModal(false)
              setParticipantData([])
            }}
            type='button'
            title={t('O_CLOSE')}
            data-drawer-hide='drawer-example'
            aria-controls='drawer-example'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <img src='./images/close-circle.png' alt='close' />

            <span className='sr-only'>{t('CLOSE_MENU')}</span>
          </button>
        </div>
        <h3 className='font-medium text-[18px] mb-4 '>{t('ADD_NEW_SPLIT')}</h3>

        <div className='text-center mb-4'>
          <h2 className='font-bold text-[22px]'>{t('O_TOTAL')}</h2>
          <h2 className='font-bold text-[22px] text-DarkBlue'>
            {sendData.amount} {t('O_LEK')}
          </h2>
        </div>

        <form>
          <div className='relative my-5'>
            <textarea
              name='note'
              rows='4'
              id='note'
              className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
              placeholder=' '
              maxLength={200}
              onInput={e => preventMaxValueDescription(e)}
              {...register('note', {
                required: t('PLEASE_ENTER_NOTE'),
                minLength: {
                  value: 10,
                  message: t('MINIMUM_LENGTH_MUST_BE_10')
                }
              })}
              errors={errors}
            />
            <label
              htmlFor='note'
              className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
            >
              {t('NOTE')}
              <span className='text-red-500'>*</span>
            </label>
            <ErrorMessage message={errors?.note?.message} />
          </div>
          <p className='mb-5 font-medium'>
            {t('SPLIT_BETWEEN')} {participantData.length} {t('PEOPLE')}
          </p>
          <table className='w-full mb-4'>
            <tbody>
              {participantData?.map(item => (
                <div>
                  <tr className='block pb-3 mb-3 border-b'>
                    <div className='flex justify-between w-full items-center'>
                      <div className='flex items-center'>
                        <div>
                          <img
                            className='w-8 h-8 rounded-full'
                            src={item?.profile_pic}
                            alt='No_image'
                          />
                        </div>
                        <div className='pl-3'>
                          +{item?.country_code}-{item?.mobile}
                        </div>
                      </div>

                      <div className='items-end text-LightBlue font-medium text-sm'>
                        {sendData.amount / participantData.length} {t('O_LEK')}
                      </div>
                    </div>
                  </tr>
                </div>
              ))}
            </tbody>
          </table>
          <button
            class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
            type='submit'
            onClick={handleSubmit(handleSubmitForm)}
          >
            {splitBillRequestLoading ? 'Loading...' : <>{t('O_ADD')}</>}
          </button>
        </form>
      </div>
      <div class='bg-gray-900  bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 overlay'></div>
    </div>
  )
}

export default SplitBillRequest
