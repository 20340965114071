import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import Loader from 'layout/Loader'
import OInputField from 'components/reusable/OInputField'
import { apiPost } from 'utils/apiFetch'
import pathObj from 'utils/pathObj'
import useToastContext from 'hooks/useToastContext'
import { validationRules } from 'utils/constants'
import { preventMax } from 'utils/validations'
import { useTranslation } from 'react-i18next'
import Header from 'components/Header'

function ForgotPassword () {
  const { t } = useTranslation()
  const notification = useToastContext()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })

  const onSubmit = async data => {
    let res
    try {
      let sendData = {}
      let input = data?.email || data?.mobile

      if (input === data?.mobile) {
        sendData.type = 'mobile'
        sendData.country_code = '355'
        sendData.mobile = input
      } else {
        sendData.type = 'email'
        sendData.email = input
      }
      res = await apiPost(pathObj.forgotPassword, sendData)
      if (res.data.success) {
        notification.success(res.data.message)
        reset()
        navigate('/otp-verification', {
          state: {
            type: sendData.type,
            [sendData.type]:
              sendData.type === 'email' ? sendData.email : sendData.mobile,
            country_code: sendData.country_code
          }
        })
        localStorage.setItem('seconds', 60)
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
      notification.error(res?.data?.message)
    }
  }

  const [radioValueMobile, setRadioValueMobile] = useState('mobile')
  const preventMaxEmail = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  const codeValue = watch('email') ? watch('email') : ''

  return (
    <div className='login-page h-full'>
      <Header />
      <Loader />
      <div className='min-h-[767px] sm:min-h-[834px] main_wrap md:pl-40 md:pr-40 pl-4 pr-4 pt-16 flex flex-col justify-between'>
        <div className='bg-white main_panel p-0 rounded-3xl overflow-hidden register h-[calc(100%-94px)]'>
          <div className='g-0 grid-rows-1'>
            <div className='grid xl:grid-cols-2'>
              <div>
                <form
                  className='md:max-w-lg m-auto px-3 mt-10 mb-4'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <h3 className='font-semibold text-[#212529] my-3 text-[20px] text-center'>
                    {t('O_FORGOT_PASSWORD')}
                  </h3>

                  <div className='form-check flex gap-5 justify-center mt-10'>
                    <h5>{t('FORGOT_RESET_USING')}:</h5>
                    <div>
                      <input
                        defaultChecked
                        type='radio'
                        className='form-check-input'
                        value='mobile'
                        name='radioValueMobile'
                        onChange={e => setRadioValueMobile(e.target.value)}
                      />
                      <label className='form-check-label mr-5'>
                        {' '}
                        {t('FORGOT_MOBILE')}
                      </label>
                      <input
                        type='radio'
                        value='email'
                        className='form-check-input'
                        name='radioValueMobile'
                        onChange={e => setRadioValueMobile(e.target.value)}
                      />
                      <label className='form-check-label'>
                        {' '}
                        {t('O_EMAIL')}
                      </label>
                    </div>
                  </div>
                  <div className='grid grid-cols-1 gap-4 md:gap-6 mt-3'>
                    <div className='relative'>
                      {radioValueMobile === 'email' && (
                        <OInputField
                          name='email'
                          inputLabel={
                            <>
                              {t('O_EMAIL')}
                              <span className='text-red-500'>*</span>
                            </>
                          }
                          errors={errors}
                          type='email'
                          onInput={e => preventMaxEmail(e)}
                          value={codeValue.toLowerCase()}
                          register={register('email', {
                            required: t('PLEASE_ENTER_EMAIL'),
                            pattern: {
                              value: validationRules.email,
                              message: t('PLEASE_ENTER_VALID_EMAIL_AS')
                            }
                          })}
                        />
                      )}
                      {radioValueMobile === 'mobile' && (
                        <div className='grid md:grid-cols-6 grid-cols-4 gap-4 md:gap-6 mt-5'>
                          <div className='relative col-span-2'>
                            <OInputField
                              greyClass
                              name='country_code'
                              inputLabel={<>{t('O_COUNTRY_CODE')}</>}
                              errors={errors}
                              type='select'
                              register={register('country_code', {})}
                              disabled
                              selectOptions={[
                                <option value='355' key='355'>
                                  +355
                                </option>
                              ]}
                            />
                          </div>
                          <div className='relative z-0 md:col-span-4 col-span-3'>
                            <OInputField
                              name='mobile'
                              inputLabel={
                                <>
                                  {t('O_MOBILE_NUMBER')}
                                  <span className='text-red-500'>*</span>
                                </>
                              }
                              errors={errors}
                              type='number'
                              onKeyDown={e => {
                                if (['-', '+', 'e', '.'].includes(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                              onInput={e => preventMax(e, 9)}
                              register={register('mobile', {
                                required: t('PLEASE_ENTER_MOBILE_NUMBER'),
                                minLength: {
                                  value: 9,
                                  message: t(
                                    'MINIMUM_LENGTH_SHOULD_BE_9_DIGITS'
                                  )
                                },
                                maxLength: {
                                  value: 9,
                                  message: t(
                                    'MAXIMUM_LENGTH_SHOULD_BE_9_DIGITS'
                                  )
                                },
                                pattern: {
                                  value: validationRules.numbers,
                                  message: t('INVALID_MOBILE_NUMBER')
                                },
                                min: {
                                  value: 0,
                                  message: t('VALUE_CAN_NOT_BE_LESS_THAN_0')
                                }
                              })}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <button className='bg-[#7100fa] hover:bg-DarkBlue cursor-pointer w-full mt-3 text-white active:bg-slate-100 text-md px-8 py-4 rounded-lg font-normal shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'>
                      {t('O_SUBMIT')}
                    </button>
                    <div className='text-center mt-4 text-sm font-medium'>
                      {t('O_BACK_TO')}{' '}
                      <Link to='/login'>
                        {' '}
                        <span className='hover:underline text-[#6236FF] hover:text-[#9D36FF]'>
                          {' '}
                          {t('O_FORGOT_LOGIN')}{' '}
                        </span>{' '}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
              <div className='md:flex justify-center items-center hidden bg-[#f8f9fa]'>
                <img src='./images/register_img.png' alt='' className='' />
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-between py-3 sm:py-4'>
          <p className='mb-0 text-white'>{t('O_ALPAY')}</p>
          <ul className='list-none m-0 text-white flex'>
            <li className=''>
              <Link to='/privacy-policy' className='hover:text-black'>
                {t('O_PRIVACY_POLICY')}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/terms-and-conditions' className='hover:text-black'>
                {t('O_TERMS_AND_CONDITION')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/about-us' className='hover:text-black'>
                {t('O_ABOUT_US')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/contact-us' className='hover:text-black'>
                {t('O_CONTACT_US')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/faqs' className='hover:text-black'>
                {t('O_FAQS')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
