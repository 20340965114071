import React, { useContext, useState } from 'react'
import { apiPost } from 'utils/apiFetch'
import apiPath from 'utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import OInputField from 'components/reusable/OInputField'
import { useTranslation } from 'react-i18next'
import { validationRules } from 'utils/constants'
import { preventMax } from 'utils/validations'
import AuthContext from 'context/AuthContext'
import AgentOtpScreen from './auth/AgentOtpScreen'

const CashIn = ({ setShowCashInModal }) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const notification = useToastContext()
  const [cashInLoading, setCashInLoading] = useState(false)
  const [cashPreview, setCashPreview] = useState(false)
  const [cashPreviewData, setCashPreviewData] = useState({})
  const [radioPaymentMode, setRadioPaymentMode] = useState('wallet')
  const [cashInData, setCashInData] = useState(false)
  const [isOTP, setIsOTP] = useState(false)
  const [otp, setOtp] = useState(null)
  const [paymentDetails, setPaymentDetails] = useState({})
  const [otpError, setOtpError] = useState('')

  const { user } = useContext(AuthContext)
  const handleSubmitForm = async data => {
    try {
      console.log('first')
      if (user?.role === 'agent' && radioPaymentMode === 'cash') {
        if (!otp) {
          setOtpError(t('PLEASE_ENTER_OTP'))
          return
        } else if (otp.length < 4) {
          setOtpError(t('PLEASE_ENTER_OTP'))
          return
        } else {
          setOtpError('')
        }
      }

      setCashInLoading(true)
      const sendData = {}
      let input = data?.mobile
      if (input === data?.mobile) {
        sendData.countryCode = 355
        sendData.mobile = input
        sendData.amount = Number(data.amount)
        sendData.cashInType = radioPaymentMode
      }
      const path = `${apiPath.AddCashIn}?type=mobile`
      const res = await apiPost(path, { ...sendData, otp })
      if (res.data.success === true) {
        setCashInData(true)
        notification.success(res.data.message)
      } else {
        setShowCashInModal(false)
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setCashInLoading(false)
    }
  }

  const handleSubmitPreview = async data => {
    try {
      setCashInLoading(true)
      const sendData = {}
      let input = data?.mobile
      if (input === data?.mobile) {
        sendData.countryCode = 355
        sendData.mobile = input
        sendData.amount = Number(data.amount)
        sendData.type = 'mobile'
        sendData.cashInType = radioPaymentMode
      }
      const path = `${apiPath.cashInPreview}?type=mobile`
      const res = await apiPost(path, sendData)
      if (res?.data?.success === true) {
        setCashPreviewData(res?.data?.results)
        setCashPreview(true)
        if (user?.role === 'agent' && radioPaymentMode === 'cash') {
          setPaymentDetails(sendData)
          setIsOTP(true)
        }
        notification.success(res.data.message)
      } else {
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setCashInLoading(false)
    }
  }

  const handleAmount = e => {
    var value = e.target.value
    const pattern = /^\d{0,5}(\.\d{0,2})?$/
    if (e.target.value === '') {
      e.target.value = e.target.value.slice(e.target.value?.length - 1, -1)
    }
    const isValid = pattern.test(value)
    if (!isValid) {
      value = value.slice(0, -1)
    }
    setValue('amount', value)
  }
  var pressedKeys = []

  return (
    <>
      <div
        id='add-agent'
        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
        aria-labelledby='drawer-label'
      >
        <div>
          <button
            onClick={() => setShowCashInModal(false)}
            title='Close'
            type='button'
            data-drawer-hide='drawer-example'
            aria-controls='drawer-example'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <img src='./images/close-circle.png' alt='close' />

            <span className='sr-only'>{t('CLOSE_MENU')}</span>
          </button>
        </div>
        <h3 className='font-medium text-[18px] mb-4'>
          {t('CASH_IN_TOP_UP_CUSTOMER')}
        </h3>
        <form>
          <div className='flex my-5 gap-4'>
            <div class='relative w-full col-span-1'>
              <OInputField
                greyClass
                name='country_code'
                inputLabel={<>{t('O_COUNTRY_CODE')}</>}
                errors={errors}
                disabled={true}
                type='select'
                register={register('country_code', {})}
                selectOptions={[
                  <option value='' key='select_code'>
                    +355
                  </option>,
                  <option value='355' key='355'>
                    +355
                  </option>
                ]}
              />
            </div>

            <div className='relative w-full '>
              <OInputField
                name='mobile'
                inputLabel={
                  <>
                    {t('O_MOBILE_NUMBER')}
                    <span className='text-red-500'>*</span>
                  </>
                }
                errors={errors}
                type='number'
                onKeyDown={e => {
                  if (['-', '+', 'e', '.'].includes(e.key)) {
                    e.preventDefault()
                  }
                }}
                onInput={e => preventMax(e, 9)}
                register={register('mobile', {
                  required: t('PLEASE_ENTER_MOBILE_NUMBER'),
                  minLength: {
                    value: 9,
                    message: t('MINIMUM_LENGTH_SHOULD_BE_9_DIGITS')
                  },
                  maxLength: {
                    value: 9,
                    message: t('MAXIMUM_LENGTH_SHOULD_BE_9_DIGITS')
                  },
                  pattern: {
                    value: validationRules.numbers,
                    message: t('INVALID_MOBILE_NUMBER')
                  },
                  min: {
                    value: 0,
                    message: t('VALUE_CAN_NOT_BE_LESS_THAN_0')
                  }
                })}
              />
            </div>
          </div>
          <div className='relative  my-5'>
            <OInputField
              name='amount'
              errors={errors}
              inputLabel={
                <>
                  {t('CASH_IN_COLLECTED_AMOUNT')}
                  <span className='text-red-500'>*</span>
                </>
              }
              type='number'
              register={register('amount', {
                required: t('PLEASE_ENTER_COOLECTED_AMOUNT'),
                onChange: e => handleAmount(e),
                validate: value => {
                  return value !== '0'
                    ? true
                    : t('VALUE_CAN_NOT_BE_LESS_THAN_0.01')
                }
              })}
              onKeyDown={e => {
                pressedKeys.push(e.key)
                var lastKey = pressedKeys[pressedKeys.length - 2]
                if (lastKey === '.') {
                  if (['-', '.', '+', 'e'].includes(e.key)) {
                    e.preventDefault()
                  }
                } else if (['-', '+', 'e'].includes(e.key)) {
                  e.preventDefault()
                }
              }}
            />
            {user?.role === 'agent' && (
              <div className='relative  my-5'>
                <div>
                  <input
                    defaultChecked
                    type='radio'
                    className='form-check-input'
                    value='wallet'
                    name='radioValueWallet'
                    onChange={e => setRadioPaymentMode(e.target.value)}
                  />
                  <label className='form-check-label mr-5'>
                    {' '}
                    {t('WALLET')}
                  </label>
                  <input
                    type='radio'
                    value='cash'
                    className='form-check-input'
                    name='radioValueWallet'
                    onChange={e => setRadioPaymentMode(e.target.value)}
                  />
                  <label className='form-check-label'> {t('CASH')}</label>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className='bg-amber-50 p-2 rounded-lg items-center mb-4'>
              <h6 className='text-amber-300 font-medium'>
                {t('CASH_IN_NOTE')} :
              </h6>
              <h6 className='text-Black font-medium text-[16px]'>
                {t('CASH_IN_SERVICE_DEDUCTION')}
              </h6>
            </div>
          </div>
          <button
            class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
            type='submit'
            onClick={handleSubmit(handleSubmitPreview)}
          >
            {cashInLoading ? 'Loading...' : <>{t('CASH_IN_TRANSFER')}</>}
          </button>
          {cashPreview ? (
            <>
              <div
                id='add-agent'
                className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
                aria-labelledby='drawer-label'
              >
                <div>
                  <button
                    onClick={() => setShowCashInModal(false)}
                    title='Close'
                    type='button'
                    data-drawer-hide='drawer-example'
                    aria-controls='drawer-example'
                    className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                  >
                    <img src='./images/close-circle.png' alt='close' />

                    <span className='sr-only'>{t('CLOSE_MENU')}</span>
                  </button>
                </div>
                <div className='flex items-center w-full justify-center mb-4'>
                  <div className='relative text-center '>
                    <h3 className='font-medium text-[18px] mb-4'>
                      {t('O_CUSTOMER_WILL_GET')}{' '}
                      {cashPreviewData.amountToBeReceived}{' '}
                      {t('CASH_IN_WALLET_TRANSFER')} {cashPreviewData.amount}{' '}
                      {t('O_LEK')}
                    </h3>
                  </div>
                </div>
                {isOTP && (
                  <AgentOtpScreen
                    setIsOTP={setIsOTP}
                    otp={otp}
                    setOtp={setOtp}
                    paymentDetails={paymentDetails}
                    otpError={otpError}
                  />
                )}
                <div className='flex justify-between items-center'>
                  <button
                    class='bg-LightBlue hover:bg-DarkBlue cursor-pointer text-white  font-medium active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
                    type='button'
                    onClick={() => setShowCashInModal(false)}
                  >
                    {t('CASH_IN_CANCEL')}
                  </button>
                  <button
                    class='bg-LightBlue hover:bg-DarkBlue cursor-pointer text-white  font-medium active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
                    disabled={cashInLoading}
                    type='button'
                    onClick={handleSubmit(handleSubmitForm)}
                  >
                    {t('CASH_IN_OK')}
                  </button>

                  {cashInData ? (
                    <div
                      id='add-agent'
                      className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
                      aria-labelledby='drawer-label'
                    >
                      <div>
                        <button
                          onClick={() => setShowCashInModal(false)}
                          title='Close'
                          type='button'
                          data-drawer-hide='drawer-example'
                          aria-controls='drawer-example'
                          className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                        >
                          <img src='./images/close-circle.png' alt='close' />

                          <span className='sr-only'>{t('CLOSE_MENU')}</span>
                        </button>
                      </div>
                      <div className='flex items-center w-full justify-center mb-4'>
                        <div className='relative text-center '>
                          <div className='w-32 h-30 relative mx-auto'>
                            <img src='images/check.png' alt='No_image' />
                          </div>
                          <h3 className='font-medium text-[18px] mb-4'>
                            {t('CASH_IN_THE_AMOUNT_TRANSFERRED')}{' '}
                            {cashPreviewData.mobile}
                          </h3>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </form>
      </div>

      <div class='bg-gray-900  bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 overlay' />
    </>
  )
}

export default CashIn
