import React, { useEffect, useState } from 'react'
import NotificationList from './NotificationList'
import apiPath from '../../utils/pathObj'
import { apiGet } from '../../utils/apiFetch'
import Pagination from '../Pagination'

function Notifications () {
  const [notifications, setNotifications] = useState([])
  const [page, setPage] = useState(1)
  const [isDelete, setIsDelete] = useState(false)
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })

  const pageSize = 10

  // get all notifications -
  const getAllNotifications = async payload => {
    let result
    try {
      if (payload?.deletePage) {
        payload = {
          page,
          pageSize
        }
        if (notifications?.length >= 1) {
          setPage(page - 1)
          setIsDelete(true)
          setPaginationObj({ ...paginationObj, page: page - 1 })
        }
      } else {
        payload = {
          page,
          pageSize
        }
        setIsDelete(false)
      }
      const path = apiPath.merchantNotifications
      result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        setNotifications(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: response.page,
          pageCount: response.totalPages,
          perPageItem: response?.docs?.length,
          totalItems: response.totalDocs
        })
      }
    } catch (error) {
      console.log('error in get all notifications list==>>>> result:', error)
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  useEffect(() => {
    getAllNotifications()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <div>
      <div className='main_wrap'>
        <div className=''>
          <div className=''>
            <div className='px-3 md:py-4 md:py-2'>
              <div className='bg-white'>
                <form className='px-0 md:px-4 py-3'>
                  <div className='col-span-2 flex flex-wrap items-center justify-between'></div>
                </form>
                <NotificationList notifications={notifications} page={page} />
                <Pagination
                  handlePageClick={handlePageClick}
                  options={paginationObj}
                  isDelete={isDelete}
                  page={page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifications
