import ErrorMessage from 'components/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
import React, { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { apiPost } from 'utils/apiFetch'
import pathObj from 'utils/pathObj'
import OButton from 'components/reusable/OButton'
import { validationRules } from 'utils/constants'
import AuthContext from 'context/AuthContext'
import { useTranslation } from 'react-i18next'

import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const [changePasswordLoading, setChangePasswordLoading] = useState(false)
  const notification = useToastContext()
  const { t } = useTranslation()
  let { logoutUser } = useContext(AuthContext)
  const [icon, setIcon] = useState(true)
  const [icon2, setIcon2] = useState(true)
  const [icon3, setIcon3] = useState(true)

  const handleSubmitForm = async data => {
    try {
      if (!isDirty) {
        notification.info('Please enter something')
        return
      }
      setChangePasswordLoading(true)

      const res = await apiPost(pathObj.changePassword, {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword
      })
      if (res.data.success) {
        reset()
        notification.success(res?.data?.message)
        logoutUser()
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setChangePasswordLoading(false)
    }
  }

  function changeIcon () {
    setIcon(!icon)
  }
  function changeIcon2 () {
    setIcon2(!icon2)
  }
  function changeIcon3 () {
    setIcon3(!icon3)
  }

  return (
    <div className='bg-[#F9F9F9]'>
      <div className='px-3 py-4'>
        <div className='bg-white border border-[#E9EDF9] rounded-lg py-4 '>
          <form onSubmit={handleSubmit(handleSubmitForm)} method='post'>
            <div className='grid md:grid-cols-3 sm:grid-cols-2'>
              <div className='py-4 px-4'>
                <label
                  htmlFor='email-address-icon'
                  className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                >
                  Old Password
                </label>
                <div className='relative'>
                  <input
                    type={icon ? 'password' : 'text'}
                    name='oldPassword'
                    id='oldPassword'
                    placeholder={t('ENTER_OLD_PASSWORD')}
                    className='block py-3 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                    // {...register('oldPassword', {
                    //   required: 'Old password is required!',
                    //   validate: (value) => { return !!value.trim() ? true : 'White spaces not allowed!'  }
                    // })}
                    {...register('oldPassword', {
                      required: t('PASSWORD_IS_REQUIRED'),
                      validate: value => {
                        return !!value.trim()
                          ? true
                          : t('WHITE_SPACE_NOT_ALLOWED')
                      },
                      pattern: {
                        value: validationRules.password,
                        message: t('PASSWORD_MUST_CONTAIN_LOWERCASE_UPPERCASE_')
                      }
                    })}
                  />
                  {icon ? (
                    <span
                      className='password_view absolute top-[18px] right-[20px]'
                      onClick={() => changeIcon()}
                    >
                      <AiFillEyeInvisible />
                    </span>
                  ) : (
                    <span
                      className='password_view absolute top-[18px] right-[20px]'
                      onClick={() => changeIcon()}
                    >
                      <AiFillEye />
                    </span>
                  )}
                  <ErrorMessage message={errors?.oldPassword?.message} />
                </div>
              </div>
              <div className='py-4 px-4 '>
                <label
                  htmlFor='email-address-icon'
                  className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                >
                  {t('NEW_PASSWORD')}
                </label>
                <div className='relative'>
                  <input
                    type={icon2 ? 'password' : 'text'}
                    name='newPassword'
                    id='newPassword'
                    placeholder={t('ENTER_NEW_PASSWORD')}
                    className='block py-3 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                    {...register('newPassword', {
                      required: t('PASSWORD_IS_REQUIRED'),
                      pattern: {
                        value: validationRules.password,
                        message: t('PASSWORD_MUST_CONTAIN_LOWERCASE_UPPERCASE_')
                      }
                    })}
                  />
                  {icon2 ? (
                    <span
                      className='password_view absolute top-[18px] right-[20px]'
                      onClick={() => changeIcon2()}
                    >
                      <AiFillEyeInvisible />
                    </span>
                  ) : (
                    <span
                      className='password_view absolute top-[18px] right-[20px]'
                      onClick={() => changeIcon2()}
                    >
                      <AiFillEye />
                    </span>
                  )}
                  <ErrorMessage message={errors?.newPassword?.message} />
                </div>
              </div>
              <div className='py-4 px-4 '>
                <label
                  htmlFor='email-address-icon'
                  className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                >
                  {t('CONFIRM_PASSWORD')}
                </label>
                <div className='relative'>
                  <input
                    type={icon3 ? 'password' : 'text'}
                    name='confirm_password'
                    id='confirm_password'
                    placeholder={t('ENTER_CONFIRM_PASSWORD')}
                    className='block py-3 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                    {...register('confirm_password', {
                      required: t('CONFIRM_PASSWORD_IS_REQUIRED'),
                      validate: val => {
                        if (watch('newPassword') !== val) {
                          return t('YOUR_PASSWORD_DO_NOT_MATCH')
                        }
                      }
                    })}
                  />
                  {icon3 ? (
                    <span
                      className='password_view absolute top-[18px] right-[20px]'
                      onClick={() => changeIcon3()}
                    >
                      <AiFillEyeInvisible />
                    </span>
                  ) : (
                    <span
                      className='password_view absolute top-[18px] right-[20px]'
                      onClick={() => changeIcon3()}
                    >
                      <AiFillEye />
                    </span>
                  )}
                  {/* <span className='password_view' onClick={changeIcon()}><AiFillEye /></span> */}
                  {/* <span className='password_view'><AiFillEyeInvisible /></span> */}
                  <ErrorMessage message={errors?.confirm_password?.message} />
                </div>
              </div>
            </div>
            <div className='flex justify-center mt-4'>
              <OButton
                disabled={!isDirty}
                label={t('O_SUBMIT')}
                type='submit'
                loading={changePasswordLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
