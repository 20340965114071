import ChangePwd from 'pages/ChangePwd'
import HomeOperator from 'pages/HomeOperator'
import MyTransaction from 'pages/my-transaction/MyTransaction'
import Notifications from 'pages/notification/Notifications'
import MyQrCode from 'pages/MyQrCode/MyQrCode'
import SharedLayout from 'utils/SharedLayout'
import { HomeRedirect } from './HomeRedirect'
import Refund from 'pages/refund/Refund'

const OperatorRoutes = {
  // All common routes
  path: '/',
  element: <SharedLayout />,
  children: [
    {
      path: '/dashboard-operator',
      element: <HomeOperator />
    },
    {
      path: '/my-transaction',
      element: <MyTransaction />
    },
    {
      path: '/refund',
      element: <Refund />
    },
    {
      path: '/change_pwd',
      element: <ChangePwd />
    },
    {
      path: '/notifications',
      element: <Notifications />
    },
    {
      path: '/my-qrcode',
      element: <MyQrCode />
    },
    {
      path: '*',
      element: <HomeRedirect to='/dashboard-operator' />
    }
  ]
}

export default OperatorRoutes
