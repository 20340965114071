import React, { useEffect, useState } from 'react'
import { apiPost } from '../../utils/apiFetch'
import { useForm } from 'react-hook-form'
import ErrorMessage from '../../components/ErrorMessage'
import pathObj from '../../utils/pathObj'
import useToastContext from 'hooks/useToastContext'
import OButton from 'components/reusable/OButton'
import OInputField from 'components/reusable/OInputField'
import { validationRules } from 'utils/constants'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { validateFile } from 'utils/reusableMethods'
import { useTranslation } from 'react-i18next'
import FormData from 'form-data'

const MerchantAdd = ({ setShowModal, getAllMerchants }) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const [icon, setIcon] = useState(true)
  const [passwordIcon, setPasswordIcon] = useState(true)
  const [addMerchantLoading, setAddMerchantLoading] = useState(false)
  const [fileErrors, setFileErrors] = useState({
    identity_card: '',
    passport: ''
  })

  useEffect(() => {
    if (watch('identity_card')?.length > 0) {
      const identityMessg = validateFile(watch('identity_card')[0])
      if (identityMessg) {
        setFileErrors({
          ...fileErrors,
          identity_card: identityMessg
        })
      } else {
        setFileErrors({
          ...fileErrors,
          identity_card: ''
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('identity_card')])

  useEffect(() => {
    if (watch('passport')?.length > 0) {
      const passportMsg = validateFile(watch('passport')[0])
      if (passportMsg) {
        setFileErrors({
          ...fileErrors,
          passport: passportMsg
        })
      } else {
        setFileErrors({
          ...fileErrors,
          passport: ''
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('passport')])

  const handleSubmitForm = async data => {
    try {
      if (fileErrors.identity_card || fileErrors.passport) {
        return
      }

      setAddMerchantLoading(true)
      const formData = new FormData()
      formData.append('identity_card', data.identity_card[0])
      formData.append('passport', data.passport[0])
      formData.append('business_name', data.business_name)
      formData.append('business_address', data.business_address)
      formData.append('type', data.type)
      formData.append('mobile', data.mobile)
      formData.append('country_code', data.country_code)
      formData.append('email', data.email)
      formData.append('password', data.password)

      const res = await apiPost(pathObj.addMerchant, formData)
      if (res.data.success === true) {
        getAllMerchants()
        setShowModal(false)
        notification.success(res.data.message)
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setAddMerchantLoading(false)
    }
  }

  const preventMax = e => {
    if (e.target.value.length > 9) {
      e.target.value = e.target.value.slice(0, 9)
    }
  }

  function changeIcon () {
    setIcon(!icon)
  }

  function changePasswordIcon () {
    setPasswordIcon(!passwordIcon)
  }

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto max-w-3xl'>
          {/* content */}
          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            {/* header */}
            <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
              <h3 className='text-xl font-semibold'>
                {t('MERCHANT_ADD_MERCHANT')}
              </h3>
              <button
                className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                onClick={() => setShowModal(false)}
              >
                <span
                  className=' text-[#B8BBBF]  text-4xl '
                  title='Close button'
                >
                  ×
                </span>
              </button>
            </div>
            {/* body */}
            <form onSubmit={handleSubmit(handleSubmitForm)} method='post'>
              <div className='relative p-6 flex-auto'>
                <div className='grid sm:grid-cols-2'>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <OInputField
                        wrapperClassName='relative z-0   w-full group'
                        name='business_name'
                        inputLabel={t('MERCHANT_BUSINESS_NAME')}
                        type='text'
                        autoFocus
                        maxLength={50}
                        register={register('business_name', {
                          required: 'Please enter business name.',
                          minLength: {
                            value: 3,
                            message: 'Minimum length must be 3.'
                          }
                        })}
                        errors={errors}
                      />
                    </div>
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <input
                        type='text'
                        name='email'
                        id='email'
                        maxLength={40}
                        autoComplete='off'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
                        placeholder=' '
                        {...register('email', {
                          required: 'Please enter email.',
                          pattern: {
                            value: validationRules.email,
                            message:
                              'Invalid email address (Ex: example@domain.com).'
                          }
                        })}
                      />
                      <label
                        htmlFor='email'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_EMAIL_ID')}
                      </label>
                      <ErrorMessage message={errors?.email?.message} />
                    </div>
                  </div>

                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <input
                        type={icon ? 'password' : 'text'}
                        name='password'
                        id='password'
                        autoComplete='new-password'
                        maxLength={30}
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        {...register('password', {
                          required: 'Please enter password.',

                          pattern: {
                            value: validationRules.password,
                            message:
                              'Password must contain lowercase,uppercase characters, numbers, special character and at least 8 character long.'
                          }
                        })}
                      />
                      <label
                        htmlFor='password'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_PASSWORD')}
                      </label>
                      {icon ? (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changeIcon()}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changeIcon()}
                        >
                          <AiFillEye />
                        </span>
                      )}
                      <ErrorMessage message={errors?.password?.message} />
                    </div>
                  </div>
                  <OInputField
                    name='type'
                    inputLabel='Type'
                    type='hidden'
                    maxLength={50}
                    defaultValue='merchant'
                    register={register('type', {
                      required: 'Please enter Type'
                    })}
                    errors={errors}
                  />

                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <input
                        type={passwordIcon ? 'password' : 'text'}
                        name='confirm_password'
                        id='confirm_password'
                        autoComplete='new-password'
                        maxLength={30}
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        {...register('confirm_password', {
                          required: 'Please enter confirm password.',
                          validate: val => {
                            if (watch('password') !== val) {
                              return 'Your passwords do no match.'
                            }
                          }
                        })}
                      />
                      <label
                        htmlFor='confirm_password'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_CONFIRM_PASSWORD')}
                      </label>
                      {passwordIcon ? (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changePasswordIcon()}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changePasswordIcon()}
                        >
                          <AiFillEye />
                        </span>
                      )}
                      <ErrorMessage
                        message={errors?.confirm_password?.message}
                      />
                    </div>
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <select
                        type='select'
                        name='country_code'
                        id='country_code'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        {...register('country_code', {
                          required: 'Please enter country code.'
                        })}
                      >
                        <option value=''>{t('O_SELECT_COUNTRY_CODE')}</option>
                        <option value='355'>355</option>
                      </select>
                      <label
                        htmlFor='country_code'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_COUNTRY_CODE')}
                      </label>
                      <ErrorMessage message={errors?.country_code?.message} />
                    </div>
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <div className='block py-3 h-14 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                        <input
                          type='file'
                          name='identity_card'
                          placeholder='Identity Card '
                          className='form-control'
                          accept='image/png,image/jpeg,image/jpg'
                          {...register('identity_card', {
                            required: 'Please provide identity card.'
                          })}
                        />
                      </div>
                      <label
                        htmlFor='floating_file'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_IDENTITY_CARD')}
                      </label>
                      <ErrorMessage
                        message={
                          errors?.identity_card?.message ||
                          fileErrors.identity_card
                        }
                      />
                    </div>
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <input
                        type='number'
                        id='mobile'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        min={0}
                        onInput={e => preventMax(e)}
                        onKeyDown={e => {
                          if (['-', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        }}
                        {...register('mobile', {
                          required: 'Please enter valid mobile number.',
                          minLength: {
                            value: 9,
                            message: 'Minimum length should be 9 digits.'
                          },
                          pattern: {
                            value: validationRules.numbers,
                            message: 'Invalid mobile number.'
                          },
                          min: {
                            value: 0,
                            message: 'Minimum value must is 0.'
                          },
                          maxLength: {
                            value: 9,
                            message: 'Max length should be 9 digits.'
                          }
                        })}
                      />
                      <label
                        htmlFor='mobile'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_MOBILE_NUMBER')}
                      </label>
                      <ErrorMessage message={errors?.mobile?.message} />
                    </div>
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <div className='block py-3 h-14 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                        <input
                          type='file'
                          name='passport'
                          placeholder='passport'
                          className='form-control'
                          accept='image/*'
                          {...register('passport', {
                            required: 'Please provide passport.'
                          })}
                        />
                      </div>
                      <label
                        htmlFor='floating_file'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_PASSPORT')}
                      </label>
                      <ErrorMessage
                        message={
                          errors?.passport?.message || fileErrors.passport
                        }
                      />
                    </div>
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <textarea
                        name='business_address'
                        rows='3'
                        id='business_address'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        {...register('business_address', {
                          required: 'Please enter business address.',
                          maxLength: {
                            value: 200,
                            message: 'Maximum character can be 200.'
                          },
                          minLength: {
                            value: 10,
                            message: 'Minimum length must be 10.'
                          }
                        })}
                      />
                      <label
                        htmlFor='business_address'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('MERCHANT_BUSINESS_ADDRESS')}
                      </label>
                      <ErrorMessage
                        message={errors?.business_address?.message}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* footer */}
              <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                <button
                  className='text-black bg-[#E1E1E1] font-normal uppercase px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                  type='button'
                  onClick={() => setShowModal(false)}
                >
                  {t('O_BACK')}
                </button>
                <OButton
                  disabled={!isDirty}
                  label={<>{t('O_ADD')}</>}
                  type='submit'
                  loading={addMerchantLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default MerchantAdd
