import React, { useState, useEffect } from 'react'
import { apiGet } from 'utils/apiFetch'
import Pagination from '../Pagination'
import apiPath from '../../utils/pathObj'
import dayjs from 'dayjs'
import className from 'classnames'
import { startCase, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'

function ManageOrders () {
  const [orders, setOrders] = useState([])
  const [orderId, setOrderId] = useState('')
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [sort, setSort] = useState({})
  const [orderFilterState, setOrderFilterState] = useState({
    sortKey: '',
    sortType: ''
  })
  const { sortKey, sortType } = orderFilterState

  const [isReset, setIsReset] = useState(false)

  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })

  const size = 10

  const handleSort = (sortKeys, sortTypes, state) => {
    setOrderFilterState({ sortKey: sortKeys, sortType: sortTypes })
    setSort({ [state]: true })
  }

  const getOrderList = async () => {
    let result
    try {
      let payload = {
        page,
        size,
        orderId,
        startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
        sortType,
        sortKey
      }
      const path = apiPath.orders
      result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        setOrders(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: response.page,
          pageCount: response.totalPages,
          perPageItem: response?.docs.length,
          totalItems: response.totalDocs
        })
      }
    } catch (error) {
      console.log('error in get orders==>>>> result:', error)
    }
  }

  const handleReset = () => {
    setEndDate('')
    setStartDate('')
    setOrderId('')
    setIsReset(true)
  }
  const handleDateChange = (start, end) => {
    setStartDate(start)
    setEndDate(end)
    setPage(1)
  }
  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const searchData = e => {
    setOrderId(e.target.value)
    setPage(1)
  }
  useEffect(() => {
    getOrderList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderId, startDate, endDate, sortKey, sortType])

  return (
    <div>
      <div className='main_wrap'>
        <div className=''>
          <div className=''>
            <div className='pt-1 px-3 py-4'>
              <div className='bg-white'>
                <form className='px-0 md:px-4 py-3'>
                  <div className=''>
                    <div className=''>
                      <div className=''>
                        <div className='flex lg:justify-end flex-wrap'>
                          <ODateRangePicker
                            handleDateChange={handleDateChange}
                            isReset={isReset}
                            setIsReset={setIsReset}
                            className='w-100'
                          />

                          <div className='relative ml-3'>
                            <div className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none bottom-0.5'>
                              {!orderId ? (
                                <svg
                                  aria-hidden='true'
                                  className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                                  fill='none'
                                  stroke='currentColor'
                                  viewBox='0 0 24 24'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                                  />
                                </svg>
                              ) : (
                                ''
                              )}
                            </div>
                            <input
                              type='search'
                              id='default-search'
                              className='border block w-full p-2 py-3 text-sm text-gray-900 2xl:min-w-[400px] xl:min-w-[300px] rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                              placeholder={t('SEARCH_BY_ORDER_ID')}
                              required
                              value={orderId}
                              onChange={searchData}
                            />
                          </div>
                          <button
                            title={t('O_RESET')}
                            type='button'
                            onClick={handleReset}
                            className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                          >
                            {t('O_RESET')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className='px-0 md:p-3 pt-0'>
                  <div className='overflow-x-auto relative rounded-lg border'>
                    <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
                      <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
                        <tr>
                          <th scope='col' className='py-3 px-6'>
                            #
                          </th>
                          <th scope='col' className='py-3 px-6 text-left'>
                            {t('ORDER_USERNAME')}
                          </th>
                          <th scope='col' className='py-3 px-6'>
                            <div className='flex justify-start'>
                              {t('ORDER_ID')}:
                              <button className='ml-3'>
                                <img
                                  name='orderId'
                                  src='images/caret-down.png'
                                  alt=''
                                  className={className('w-2.5 rotate-180', {
                                    'opacity-20': sort?.orderIdDownArrow
                                  })}
                                  onClick={e =>
                                    handleSort(
                                      e.target.name,
                                      'asc',
                                      'orderIdUpArrow'
                                    )
                                  }
                                />
                                <img
                                  name='orderId'
                                  src='images/caret-down.png'
                                  alt=''
                                  className={className('w-2.5', {
                                    'opacity-20': sort?.orderIdUpArrow
                                  })}
                                  onClick={e =>
                                    handleSort(
                                      e.target.name,
                                      'desc',
                                      'orderIdDownArrow'
                                    )
                                  }
                                />
                              </button>
                            </div>
                          </th>
                          <th scope='col' className='py-3 px-6 text-left'>
                            {t('ORDER_PRODUCT')}
                          </th>
                          <th scope='col' className='py-3 px-6 text-left'>
                            {t('O_MOBILE_NUMBER')} (+355)
                          </th>
                          <th scope='col' className='py-3 px-6'>
                            <div className='flex justify-start'>
                              {t('O_CREATED_DATE')}
                              <button className='ml-3'>
                                <img
                                  name='createdAt'
                                  src='images/caret-down.png'
                                  alt=''
                                  className={className('w-2.5 rotate-180', {
                                    'opacity-20': sort?.dateDownArrow
                                  })}
                                  onClick={e =>
                                    handleSort(
                                      e.target.name,
                                      'asc',
                                      'dateUpArrow'
                                    )
                                  }
                                />
                                <img
                                  name='createdAt'
                                  src='images/caret-down.png'
                                  alt=''
                                  className={className('w-2.5', {
                                    'opacity-20': sort?.dateUpArrow
                                  })}
                                  onClick={e =>
                                    handleSort(
                                      e.target.name,
                                      'desc',
                                      'dateDownArrow'
                                    )
                                  }
                                />
                              </button>
                            </div>
                          </th>
                          <th scope='col' className='py-3 px-6 text-left'>
                            {t('OFFER_AMOUNT')} ({t('O_LEK')})
                          </th>
                          <th scope='col' className='py-3 px-6 text-left'>
                            {t('OFFER_OFFER_AMOUNT')} ({t('O_LEK')})
                          </th>
                          <th scope='col' className='py-3 px-6 '>
                            <div className='flex justify-start'>
                              {t('STATUS')}
                              <button className='ml-3'>
                                <img
                                  name='status'
                                  src='images/caret-down.png'
                                  alt=''
                                  className={className('w-2.5 rotate-180', {
                                    'opacity-20': sort.statusDownArrow
                                  })}
                                  onClick={e =>
                                    handleSort(
                                      e.target.name,
                                      'asc',
                                      'statusUpArrow'
                                    )
                                  }
                                />
                                <img
                                  name='status'
                                  src='images/caret-down.png'
                                  alt=''
                                  className={className('w-2.5', {
                                    'opacity-20': sort?.statusUpArrow
                                  })}
                                  onClick={e =>
                                    handleSort(
                                      e.target.name,
                                      'desc',
                                      'statusDownArrow'
                                    )
                                  }
                                />
                              </button>
                            </div>
                          </th>
                          {/* <th
                            scope='col'
                            className='py-3 px-6 font-normal text-center'
                          >
                            Action
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((item, i) => {
                          return (
                            <tr
                              className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                              style={i % 2 ? { background: '#F9F9F9' } : {}}
                            >
                              <td className='py-3 px-6 border-r font-medium text-gray-900 dark:text-white'>
                                {i + 1 + 10 * (page - 1)}
                              </td>
                              <td className='py-3 px-6 border-r text-left'>
                                {`${item.userFirstName} ${item.userLastName}`}
                              </td>
                              <td className='py-3 px-6 border-r'>
                                <div class='flex justify-end space-x-4'>
                                  <img
                                    alt=''
                                    class='w-10 h-10'
                                    src={item?.image}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null // prevents looping
                                      currentTarget.src = './images/sales.svg'
                                    }}
                                  />
                                  <div>{item?.orderId}</div>
                                </div>
                              </td>
                              <td className='py-3 px-6 border-r text-left'>
                                {item?.product}
                              </td>

                              <td className='py-3 px-6 border-r text-right'>
                                {item?.mobile}
                              </td>

                              <td className='py-3 px-6 border-r text-right'>
                                {dayjs(item?.createdAt).format(
                                  'DD-MM-YYYY hh:mm A'
                                )}
                              </td>
                              <td className='py-3 px-6 border-r text-right'>
                                {item?.actualAmount}{' '}
                              </td>
                              <td className='py-3 px-6 border-r text-right'>
                                {item?.amount}{' '}
                              </td>
                              <td
                                className={className(
                                  'py-3 px-6 border-r text-center',
                                  {
                                    'text-green-600':
                                      item?.status === 'completed',
                                    'text-yellow-500':
                                      item?.status === 'pending'
                                  }
                                )}
                              >
                                {startCase(item?.status)}
                              </td>
                            </tr>
                          )
                        })}
                        {isEmpty(orders) ? (
                          <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                            <td className='py-4 px-6' colSpan={8}>
                              {t('O_NO_RECORD_FOUND')}
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                    <Pagination
                      handlePageClick={handlePageClick}
                      options={paginationObj}
                      page={page}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageOrders
