import React from 'react'
import { isEmpty, startCase } from 'lodash'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import className from 'classnames'

const WalletList = ({ wallets, page }) => {
  const { t } = useTranslation()

  return (
    <div className='p-1 md:p-3 pt-0'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6 text-left'>
                #
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('INVOICE_TRANSACTION_ID')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('INVOICE_NAME')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('INVOICE_TYPE')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('DASHBOARD_AGENT_TRANSACTION_TYPE')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_CREATED_DATE')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_OFFER_AMOUNT')} ({t('O_LEK')})
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('STATUS')}
              </th>
            </tr>
          </thead>
          <tbody>
            {wallets &&
              wallets?.map(
                (
                  {
                    transaction_id,
                    transaction_amount,
                    type,
                    receiver_data,
                    transaction_type,
                    currency,
                    receiver_type,
                    status,
                    wallet_limit,
                    createdAt,
                    _id
                  },
                  i
                ) => (
                  <tr
                    key={i}
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    style={i % 2 ? { background: '#F9F9F9' } : {}}
                  >
                    <th
                      scope='row'
                      className='py-3 px-6 border-r font-medium text-gray-900 dark:text-white'
                    >
                      {i + 1 + 10 * (page - 1)}
                    </th>
                    <td className='py-3 px-6 border-r text-left'>
                      {transaction_id}
                    </td>
                    <td className='py-3 px-6 border-r text-left'>
                      {transaction_type === 'salary_payment'
                        ? 'Agents'
                        : transaction_type === 'wallet_to_bank'
                        ? 'Bank'
                        : `${receiver_data?.[0]?.first_name} ${receiver_data?.[0]?.last_name}`}
                    </td>
                    <td className='py-3 px-6 border-r text-left'>
                      {startCase(receiver_type)}
                    </td>
                    <td className='py-3 px-6 border-r text-left'>
                      {startCase(transaction_type)}
                    </td>
                    <td className='py-3 px-6 border-r text-right'>
                      {dayjs(createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                    </td>
                    <td className='py-3 px-6 border-r text-right'>
                      {' '}
                      {transaction_amount?.toFixed(2)}{' '}
                    </td>
                    <td
                      className={className('py-3 px-6 text-center', {
                        'text-green-600': status === 'completed',
                        'text-yellow-500': status === 'pending',
                        'text-red-500': status === 'failed'
                      })}
                    >
                      {startCase(status)}
                    </td>
                  </tr>
                )
              )}
            {isEmpty(wallets) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={8}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default WalletList
