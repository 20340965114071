import ErrorMessage from 'components/ErrorMessage'
import OImage from 'components/reusable/OImage'
import OInputField from 'components/reusable/OInputField'
import useToastContext from 'hooks/useToastContext'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { apiPost } from 'utils/apiFetch'
import apiPath from 'utils/pathObj'
import { validationRules } from 'utils/constants'
import { generateRandomID, validateFile } from 'utils/reusableMethods'
import { preventMax } from 'utils/validations'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import FormData from 'form-data'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import helpers from 'utils/helpers'

const RegisterForm = ({ openTab }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notification = useToastContext()
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    trigger,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })

  const [icon, setIcon] = useState(true)
  const [passwordIcon, setPasswordIcon] = useState(true)
  const [idCardImg, setIdCardImg] = useState({ img: null, url: null })
  const [passportImg, setPassportImg] = useState({ img: null, url: null })

  const [fileMsg, setFileMsg] = useState({
    identity_card: ''
  })
  const [fileMsgPassport, setFileMsgPassport] = useState({
    passport: ''
  })

  const handleCheckError = () => {
    if (idCardImg?.img == null) {
      setFileMsg({
        ...fileMsg,
        identity_card: t('PLEASE_UPLOAD_THE_IDENTITY_CARD')
      })
    }
    if (passportImg?.img == null) {
      setFileMsgPassport({
        ...fileMsgPassport,
        passport: t('PLEASE_UPLOAD_THE_PASSPORT')
      })
    }
  }

  useEffect(() => {
    if (watch('passport')?.length > 0) {
      setPassportImg({
        url: URL.createObjectURL(watch('passport')[0]),
        img: watch('passport')[0]
      })
      const passport_msg = validateFile(watch('passport')[0])

      if (passport_msg) {
        setFileMsgPassport({
          ...fileMsgPassport,
          passport: passport_msg
        })
      } else {
        setFileMsgPassport({
          ...fileMsgPassport,
          passport: ''
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('passport')])

  useEffect(() => {
    if (watch('identity_card')?.length > 0) {
      setIdCardImg({
        url: URL.createObjectURL(watch('identity_card')[0]),
        img: watch('identity_card')[0]
      })
      const identity_card_msg = validateFile(watch('identity_card')[0])

      if (identity_card_msg) {
        setFileMsg({
          ...fileMsg,
          identity_card: identity_card_msg
        })
      } else {
        setFileMsg({
          ...fileMsg,
          identity_card: ''
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('identity_card')])

  useEffect(() => {
    reset()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTab])

  const preventMaxValue = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 15) {
      e.target.value = e.target.value.slice(0, 15)
    }
  }
  const preventMaxEmail = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  const handleLogin = async data => {
    try {
      if (fileMsg.identity_card !== '' || fileMsgPassport.passport !== '') {
        return
      }
      const formData = new FormData()
      formData.append('first_name', data.first_name)
      formData.append('last_name', data.last_name)
      formData.append('email', data.email)
      formData.append('password', data.password)
      formData.append('country_code', 355)
      formData.append('mobile', data.mobile)
      formData.append('device_id', generateRandomID(10))
      formData.append('device_type', 'web')
      formData.append('device_token', generateRandomID(20))
      formData.append(
        'identity_card',
        idCardImg?.img !== null ? idCardImg?.img : data.identity_card[0]
      )
      formData.append(
        'passport',
        passportImg?.img !== null ? passportImg?.img : data.passport[0]
      )

      const res = await apiPost(apiPath.register, formData)
      const result = res?.data?.results

      if (res.data.success) {
        if (!result?.is_otp_verified) {
          navigate('/otp', {
            state: {
              mobile: data.mobile,
              country_code: data.country_code
            }
          })
          localStorage.setItem('seconds', 60)
        }
        notification.success(res.data.message)
      } else {
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    }
  }

  function changeIcon () {
    setIcon(!icon)
  }
  function changePasswordIcon () {
    setPasswordIcon(!passwordIcon)
  }

  const password = watch('password')
  useEffect(() => {
    if (!isEmpty(password)) {
      trigger('confirm_password')
    }
  }, [password, trigger])

  const codeValue = watch('email') ? watch('email') : ''

  return (
    <form
      className='md:max-w-lg m-auto px-3'
      onSubmit={handleSubmit(handleLogin)}
    >
      <div className='grid md:grid-cols-2 gap-4 md:gap-6 '>
        <div className='relative'>
          {openTab === 1 && (
            <OInputField
              type='text'
              name='first_name'
              inputLabel={
                <>
                  {t('O_FIRST_NAME')}
                  <span className='text-red-500'>*</span>
                </>
              }
              autoFocus
              onInput={e => preventMaxValue(e)}
              register={register('first_name', {
                required: t('PLEASE_ENTER_FIRST_NAME'),
                minLength: {
                  value: 3,
                  message: t('MINIMUM_LENGTH_MUST_BE_3')
                },
                maxLength: {
                  value: 15,
                  message: t('MAXIMUM_LENGTH_MUST_BE_15')
                },
                validate: value => {
                  return !!value.trim() ? true : t('WHITE_SPACE_NOT_ALLOWED')
                }
              })}
              errors={errors}
            />
          )}
        </div>

        <div className='relative'>
          <OInputField
            name='last_name'
            inputLabel={
              <>
                {t('O_LAST_NAME')}
                <span className='text-red-500'>*</span>
              </>
            }
            errors={errors}
            type='text'
            onInput={e => preventMaxValue(e)}
            register={register('last_name', {
              required: t('PLEASE_ENTER_LAST_NAME'),
              minLength: {
                value: 3,
                message: t('MINIMUM_LENGTH_MUST_BE_3')
              },
              maxLength: {
                value: 15,
                message: t('MAXIMUM_LENGTH_MUST_BE_15')
              }
            })}
          />
        </div>
      </div>
      <div className='grid  grid-cols-6 gap-4 md:gap-6 mt-5'>
        <div className='relative col-span-2 '>
          <OInputField
            greyClass
            name='country_code'
            inputLabel={<>{t('O_COUNTRY_CODE')}</>}
            errors={errors}
            type='select'
            register={register('country_code', {})}
            disabled
            selectOptions={[
              <option value='355' key='355'>
                +355
              </option>
            ]}
          />
        </div>
        <div className='relative z-0 md:col-span-4 col-span-4'>
          <OInputField
            name='mobile'
            inputLabel={
              <>
                {t('O_MOBILE_NUMBER')}
                <span className='text-red-500'>*</span>
              </>
            }
            errors={errors}
            type='number'
            onKeyDown={e => {
              if (['-', '+', 'e', '.'].includes(e.key)) {
                e.preventDefault()
              }
            }}
            onInput={e => preventMax(e, 9)}
            register={register('mobile', {
              required: t('PLEASE_ENTER_MOBILE_NUMBER'),
              minLength: {
                value: 9,
                message: t('MINIMUM_LENGTH_SHOULD_BE_9_DIGITS')
              },
              maxLength: {
                value: 9,
                message: t('MAXIMUM_LENGTH_SHOULD_BE_9_DIGITS')
              },
              pattern: {
                value: validationRules.numbers,
                message: t('INVALID_MOBILE_NUMBER')
              },
              min: {
                value: 0,
                message: t('VALUE_CAN_NOT_BE_LESS_THAN_0')
              }
            })}
          />
        </div>
      </div>
      <div className='grid grid-cols-1 gap-4 md:gap-6 mt-5'>
        <div className='relative'>
          <OInputField
            name='email'
            inputLabel={
              <>
                {t('O_EMAIL')}
                <span className='text-red-500'>*</span>
              </>
            }
            errors={errors}
            type='email'
            onInput={e => preventMaxEmail(e)}
            value={codeValue.toLowerCase()}
            register={register('email', {
              required: t('PLEASE_ENTER_EMAIL'),
              pattern: {
                value: validationRules.email,
                message: t('PLEASE_ENTER_VALID_EMAIL_AS')
              }
            })}
          />
        </div>
      </div>
      <div className='grid md:grid-cols-2 gap-4 md:gap-6 mt-5'>
        <div className='relative'>
          <OInputField
            type={helpers?.ternaryCondition(icon, 'password', 'text')}
            name='password'
            inputLabel={
              <>
                {t('O_PASSWORD')}
                <span className='text-red-500'>*</span>
              </>
            }
            errors={errors}
            maxLength={15}
            onInput={e => preventMaxEmail(e)}
            register={register('password', {
              required: t('PLEASE_ENTER_PASSWORD'),
              pattern: {
                value: validationRules.password,
                message: validationRules.passwordMessage
              }
            })}
          />
          {icon ? (
            <span
              className='password_view absolute top-[18px] right-[20px]'
              onClick={() => changeIcon()}
            >
              <AiFillEyeInvisible />
            </span>
          ) : (
            <span
              className='password_view absolute top-[18px] right-[20px]'
              onClick={() => changeIcon()}
            >
              <AiFillEye />
            </span>
          )}
        </div>
        <div className='relative'>
          <OInputField
            name='confirm_password'
            inputLabel={
              <>
                {t('O_CONFIRM_PASSWORD')}
                <span className='text-red-500'>*</span>
              </>
            }
            errors={errors}
            type={helpers?.ternaryCondition(passwordIcon, 'password', 'text')}
            maxLength={15}
            onInput={e => preventMaxEmail(e)}
            register={register('confirm_password', {
              required: t('PLEASE_ENTER_CONFIRM_PASSWORD'),
              pattern: {
                value: validationRules.password,
                message: validationRules.confirmPasswordMessage
              },
              validate: value => {
                const { password } = getValues()
                return (
                  password === value ||
                  t('PASSWORD_AND_CONFIRM_PASSWORD_DOES_NOT_MATCH')
                )
              }
            })}
          />
          {passwordIcon ? (
            <span
              className='password_view absolute top-[18px] right-[20px]'
              onClick={() => changePasswordIcon()}
            >
              <AiFillEyeInvisible />
            </span>
          ) : (
            <span
              className='password_view absolute top-[18px] right-[20px]'
              onClick={() => changePasswordIcon()}
            >
              <AiFillEye />
            </span>
          )}
        </div>
      </div>

      <h3 className='font-semibold text-[#212529] my-3 text-[20px]'>
        {t('O_UPLOAD')} {t('O_KYC_DETAILS')}
      </h3>
      <div className='grid md:grid-cols-2 gap-4 md:gap-6 mt-5'>
        <div className='relative'>
          <div className='relative z-0   w-full group'>
            <div className='flex justify-between items-center py-2 h-[46px] px-3 w-full text-sm text-gray-900 bg-transparent border border-1  rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
              <div className='w-full'>
                <input
                  type='file'
                  accept='image/png, image/jpg, image/jpeg'
                  id='identity_card'
                  className='form-control relative z-20 w-full'
                  placeholder='image'
                  name='identity_card'
                  {...register('identity_card', {})}
                />
              </div>
              <label
                htmlFor='identity_card'
                className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
              >
                {t('O_IDENTITY_CARD')}
                <span className='text-red-500'>*</span>
              </label>
            </div>
            <ErrorMessage
              message={errors?.identity_card?.message || fileMsg?.identity_card}
            />
            <div className='mt-1'>
              {idCardImg?.url && !fileMsg?.identity_card && (
                <OImage
                  src={
                    idCardImg?.url !== null
                      ? idCardImg?.url
                      : URL.createObjectURL(watch('identityCardWatch')[0])
                  }
                  style={{ width: '8rem', height: '8rem' }}
                />
              )}
            </div>
          </div>
        </div>

        <div className='relative z-0  w-full group'>
          <div className='flex justify-between items-center py-2 h-[46px] px-3 w-full text-sm text-gray-900 bg-transparent border border-1  rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
            <div className='w-full'>
              <input
                type='file'
                accept='image/png, image/jpg, image/jpeg'
                id='passport'
                className='form-control relative z-20 w-full'
                placeholder='image'
                name='passport'
                {...register('passport', {})}
              />
            </div>
            <label
              htmlFor='passport'
              className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
            >
              {t('O_PASSPORT')}
              <span className='text-red-500'>*</span>
            </label>
          </div>
          <ErrorMessage
            message={errors?.passport?.message || fileMsgPassport?.passport}
          />
          <div className='mt-1'>
            {passportImg?.url && !fileMsgPassport?.passport && (
              <OImage
                src={
                  passportImg?.url !== null
                    ? passportImg?.url
                    : URL.createObjectURL(watch('passportWatch')[0])
                }
                style={{ width: '8rem', height: '8rem' }}
              />
            )}
          </div>
        </div>
      </div>

      <div className='flex  mt-5'>
        <input
          id='default-checkbox'
          type='checkbox'
          value=''
          className='w-5 h-5 text-blue-600 bg-gray-100 rounded border-gray-100 focus:ring-0 dark:focus:ring-white dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
          {...register('terms_and_conditions', {
            required: t(
              'PLEASE_ACCEPT_THE_TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY'
            )
          })}
        />
        <label
          htmlFor='default-checkbox'
          className='ml-2 text-md text-gray-900 dark:text-gray-300 dark:text-black'
        >
          {t('REGISTER_BY_SIGNING')}{' '}
          <Link
            to='/terms-and-conditions'
            className='text-LightBlue'
            target='_blank'
          >
            {t('REGISTER_TERMS_OF_USE')}
          </Link>{' '}
          {t('REGISTER_AND')}{' '}
          <Link to='/privacy-policy' className='text-LightBlue' target='_blank'>
            {t('O_PRIVACY_POLICY')}
          </Link>{' '}
        </label>
      </div>
      <ErrorMessage message={errors?.terms_and_conditions?.message} />
      <button
        onClick={() => handleCheckError()}
        className='bg-[#7100fa] hover:bg-DarkBlue cursor-pointer w-full mt-3 text-white active:bg-slate-100 text-md px-8 py-4 rounded-lg font-normal shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'
      >
        {t('O_REGISTER')}
      </button>
    </form>
  )
}

export default RegisterForm
