import './MyQrCode.css'
import React, { useContext, useEffect, useState } from 'react'
import { ImQrcode } from 'react-icons/im'
import { FaUserAlt } from 'react-icons/fa'
import AuthContext from 'context/AuthContext'
import OImage from 'components/reusable/OImage'
import pathObj from 'utils/pathObj'
import { apiGet } from 'utils/apiFetch'
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'

const MyQrCode = () => {
  const { t } = useTranslation()
  let { user } = useContext(AuthContext)
  const [qrCode, setQrCode] = useState('')

  const getQR = async () => {
    try {
      const res = await apiGet(pathObj.merchantQR)
      setQrCode(res.data.results)
    } catch (error) {
      console.log('error:', error)
    }
  }

  useEffect(() => {
    getQR()
  }, [])

  const downloadImage = image => {
    saveAs(image, 'QRcode.png')
  }

  return (
    <div className='main_wrap px-0'>
      <div className='m-0 py-4 sidebar_pageHeading fullPage_space'>
        <h5 className='mb-0 text-white'>
          <ImQrcode style={{ marginRight: '6px' }} /> {t('O_MY_QR_CODE')}
        </h5>
      </div>
      <div className='bg-light main_panel rounded-0 p-3 p-md-4 myOrder'>
        <div className='bg-white p-3 p-md-4'>
          <section className='my-qrcode'>
            <div style={{ fontWeight: '500' }}>{t('O_MY_QR_CODE')}</div>

            {user?.business_logo ? (
              <OImage
                src={user.business_logo}
                style={{ width: '40px', height: '40px', marginTop: '20px' }}
              />
            ) : (
              <FaUserAlt
                style={{ width: '40px', height: '40px', marginTop: '20px' }}
              />
            )}

            <div className='username'>
              {' '}
              {`${user?.first_name} ${user?.last_name}`}
            </div>
            <div>+355 {user?.mobile}</div>
            <OImage
              src={qrCode}
              style={{ width: '250px', height: '250px' }}
              alt='qr code'
            />
          </section>
        </div>
      </div>

      <div className='text-center'>
        <button
          onClick={() => downloadImage(qrCode)}
          className='text-lg	bg-LightBlue px-4  py-3 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue'
        >
          {t('DOWNLOAD_QR_CODE')}
        </button>
      </div>
    </div>
  )
}

export default MyQrCode
