import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import MerchantTable from './MerchantTable'
import MerchantAdd from './MerchantAdd'
import MerchantEdit from './MerchantEdit'
import Pagination from '../Pagination'
import AuthContext from 'context/AuthContext'
import dayjs from 'dayjs'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { useTranslation } from 'react-i18next'

function Merchant () {
  const { t } = useTranslation()
  const { logoutUser, user } = useContext(AuthContext)

  const [showModal, setShowModal] = useState(false)
  const [editshowModal, setEditShowModal] = useState(false)
  const [merchants, setMerchants] = useState([])
  const [page, setPage] = useState(1)
  const [category, setCategory] = useState('')
  const [type, setType] = useState('')
  const [item, setItem] = useState('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState('')
  const [searchkey, setSearhkey] = useState('')
  const [isDelete, setIsDelete] = useState(false)
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const size = 10
  const [isReset, setIsReset] = useState(false)

  const preparePayload = () => ({
    page,
    size,
    type,
    status: category,
    start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
    end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    keyword: searchkey
  })

  const handleDeletePage = () => {
    setPage(page - 1)
    setIsDelete(true)
    setPaginationObj({ ...paginationObj, page: page - 1 })
  }

  const handleApiResponse = response => {
    setMerchants(response?.docs)
    setPaginationObj({
      ...paginationObj,
      page: response.page,
      pageCount: response.totalPages,
      perPageItem: response?.docs.length,
      totalItems: response.totalDocs
    })
  }

  const getAllMerchants = async payload => {
    let result
    try {
      let newPayload

      if (payload?.deletePage) {
        newPayload = preparePayload()
        if (merchants?.length >= 1) {
          handleDeletePage()
        }
      } else {
        newPayload = preparePayload()
        setIsDelete(false)
      }

      const path = apiPath.getMerchantList
      result = await apiGet(path, newPayload)

      if (result?.status === 200) {
        handleApiResponse(result?.data?.results)
      }
    } catch (error) {
      console.log('error in get all merchants list==>>>> result:', error)
      setPaginationObj({})

      if (error?.response?.status === 401 || error?.response?.status === 409) {
        logoutUser()
      }
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handelEdit = items => {
    setItem(items)
    setEditShowModal(true)
  }
  useEffect(() => {
    getAllMerchants()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, page, category, searchkey, endDate, startDate])

  const handleSearchKey = e => {
    setSearhkey(e.target.value)
    setPage(1)
  }

  const handleReset = () => {
    setType('')
    setCategory('')
    setSearhkey('')
    setStartDate('')
    setEndDate('')
    setIsReset(true)
  }

  const handleDateChange = (start, end) => {
    setStartDate(start)
    setEndDate(end)
  }
  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg'>
            {/* filter section */}
            <form className='border-b border-b-[#E3E3E3] 2xl:flex gap-2 px-4 py-3'>
              <div className='col-span-2 flex flex-wrap  items-center'>
                <div className='flex items-center lg:pt-0 pt-3 flex-wrap justify-center'>
                  <ODateRangePicker
                    handleDateChange={handleDateChange}
                    isReset={isReset}
                    setIsReset={setIsReset}
                  />

                  <div className='flex items-center  ml-3 mb-3'>
                    <select
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#DFDFDF]  dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      value={category}
                      onChange={e => setCategory(e.target.value)}
                    >
                      <option defaultValue value=''>
                        {t('O_ALL')}
                      </option>
                      <option value='active'>{t('O_ACTIVE')}</option>
                      <option value='inactive'>{t('O_INACTIVE')}</option>
                    </select>
                  </div>

                  <button
                    type='button'
                    onClick={handleReset}
                    className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                  >
                    {t('O_RESET')}
                  </button>
                </div>
              </div>
              <div className='2xl:ml-auto xl:ml-0 lg:pt-0 pt-2'>
                <div className='flex'>
                  <div className='relative '>
                    <div
                      className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'
                      style={{ transform: 'translateY(-2px)' }}
                    >
                      {!searchkey && (
                        <svg
                          aria-hidden='true'
                          className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                          fill='none'
                          stroke='currentColor'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                          />
                        </svg>
                      )}
                    </div>
                    <input
                      type='search'
                      className='block w-full p-2 outline-none text-sm text-gray-900 2xl:min-w-[200px] xl:min-w-[300px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder={t('MERCHANT_SEARCH_BY_NAME_EMAIL')}
                      value={searchkey}
                      required
                      onChange={e => handleSearchKey(e)}
                    />
                  </div>
                  {(user?.permission?.[4]?.add ||
                    user.permission?.length === 0) && (
                    <button
                      type='button'
                      className='bg-LightBlue flex text-sm px-8 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue whitespace-nowrap'
                      onClick={() => setShowModal(true)}
                    >
                      + {t('MERCHANT_ADD_MERCHANT')}
                    </button>
                  )}
                </div>
              </div>
            </form>
            <MerchantTable
              user={user}
              merchants={merchants}
              getAllMerchants={getAllMerchants}
              handelEdit={handelEdit}
              page={page}
            />

            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                isDelete={isDelete}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
      <>
        {showModal ? (
          <MerchantAdd
            setShowModal={setShowModal}
            getAllMerchants={getAllMerchants}
          />
        ) : null}
        {editshowModal ? (
          <MerchantEdit
            setEditShowModal={setEditShowModal}
            getAllMerchants={getAllMerchants}
            item={item}
          />
        ) : null}
      </>
    </div>
  )
}

export default Merchant
