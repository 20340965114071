import React, { useState, useEffect } from 'react'
import AddStaff from './AddStaff'
import StaffList from './StaffList'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import Pagination from '../Pagination'
import dayjs from 'dayjs'
import apiPath from '../../utils/pathObj'
import { apiGet } from '../../utils/apiFetch'
import EditStaff from './EditStaff'
import { useTranslation } from 'react-i18next'

function ManageStaff () {
  const { t } = useTranslation()
  const [isReset, setIsReset] = useState(false)
  const [editShowModal, setEditShowModal] = useState(false)
  const [showAddStaffModal, setShowStaffModal] = useState(false)
  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState('')
  const [staffs, setStaffs] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  const [item, setItem] = useState('')

  const [offerFilterState, setOfferFilterState] = useState({
    start_date: '',
    end_date: '',
    page: '',
    keyword: '',
    sortKey: '',
    sortType: '',
    status: ''
  })
  const { category, keyword, sortKey, sortType, status } = offerFilterState
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })

  const size = 10

  // get all offers -
  const getAllStaffs = async payload => {
    let result
    try {
      if (payload?.deletePage) {
        payload = {
          page,
          size,
          category,
          keyword,
          sortType,
          sortKey,
          status,
          start_date: startDate ? dayjs(startDate).format('MM-DD-YYYY') : null,
          end_date: endDate ? dayjs(endDate).format('MM-DD-YYYY') : null
        }
        if (staffs?.length >= 1) {
          setPage(page - 1)
          setIsDelete(true)
          setPaginationObj({ ...paginationObj, page: page - 1 })
        }
      } else {
        payload = {
          page,
          size,
          keyword,
          sortType,
          sortKey,
          status,
          start_date: startDate ? dayjs(startDate).format('MM-DD-YYYY') : null,
          end_date: endDate ? dayjs(endDate).format('MM-DD-YYYY') : null
        }
        setIsDelete(false)
      }
      const path = apiPath.getAllStaffs
      result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        setStaffs(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: response.page,
          pageCount: response.totalPages,
          perPageItem: response?.docs.length,
          totalItems: response.totalDocs
        })
      }
    } catch (error) {
      console.log('error in get all staffs list==>>>> result:', error)
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handleChange = event => {
    setOfferFilterState({
      ...offerFilterState,
      [event.target.name]: event.target.value
    })
    setPage(1)
  }

  const handelEdit = items => {
    setItem(items)
    setEditShowModal(true)
  }
  useEffect(() => {
    getAllStaffs()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    keyword,
    startDate,
    endDate,
    sortType,
    sortKey,
    offerFilterState,
    status
  ])

  const handleDateChange = (start, end) => {
    setStartDate(start)
    setEndDate(end)
    setPage(1)
  }

  const handleReset = () => {
    setEndDate('')
    setStartDate('')
    setIsReset(true)
    setOfferFilterState({
      ...offerFilterState,
      category: '',
      keyword: '',
      start_date: '',
      end_date: '',
      status: ''
    })
  }

  return (
    <div>
      <div className='main_wrap'>
        <div className=''>
          <div className=''>
            <div className='px-3 py-4'>
              <div className='bg-white'>
                <form className=' px-4 py-3'>
                  <div className='col-span-2 flex flex-wrap items-center justify-between pb-2'>
                    <h4 className='m-0 text-[#222] md:text-md text-lg md:text-start  md:w-auto w-full font-medium mr-12'>
                      &nbsp;
                    </h4>
                    <div className='flex lg:justify-end flex-wrap'>
                      <ODateRangePicker
                        handleDateChange={handleDateChange}
                        isReset={isReset}
                        setIsReset={setIsReset}
                      />
                      <div className='flex items-center ml-3 mb-3'>
                        <select
                          id='status'
                          name='status'
                          className='block py-3 p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#E9EDF9] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          value={status}
                          onChange={handleChange}
                        >
                          <option defaultValue value=''>
                            {t('O_ALL')}
                          </option>
                          <option value='active'>{t('O_ACTIVE')}</option>
                          <option value='inactive'>{t('O_INACTIVE')}</option>
                        </select>
                      </div>
                      <button
                        title={t('O_RESET')}
                        type='button'
                        onClick={handleReset}
                        className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                      >
                        {t('O_RESET')}
                      </button>
                      <button
                        title={t('STAFF_ADD_AGENT')}
                        onClick={() => setShowStaffModal(true)}
                        type='button'
                        className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                      >
                        + {t('STAFF_ADD_AGENT')}
                      </button>
                    </div>
                  </div>
                  <div className='2xl:ml-auto xl:ml-0 lg:pt-0 mt-5'>
                    <label
                      for='default-search'
                      className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
                    >
                      {t('O_SEARCH')}
                    </label>
                    <div className='flex'>
                      <div className='relative w-full'>
                        <div className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'>
                          {!keyword ? (
                            <svg
                              aria-hidden='true'
                              className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                              fill='none'
                              stroke='currentColor'
                              viewBox='0 0 24 24'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                              />
                            </svg>
                          ) : (
                            ''
                          )}
                        </div>
                        <input
                          type='search'
                          name='keyword'
                          id='default-search'
                          className='border bg-white block w-full p-2 py-3 text-sm text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                          placeholder='Search by agent'
                          required
                          value={keyword}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <StaffList
                  staffs={staffs}
                  getAllStaffs={getAllStaffs}
                  handelEdit={handelEdit}
                  page={page}
                  setOfferFilterState={setOfferFilterState}
                />
                <Pagination
                  handlePageClick={handlePageClick}
                  options={paginationObj}
                  isDelete={isDelete}
                  page={page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddStaffModal ? (
        <AddStaff
          setShowStaffModal={setShowStaffModal}
          getAllStaffs={getAllStaffs}
        />
      ) : null}
      {editShowModal ? (
        <EditStaff
          setEditShowModal={setEditShowModal}
          getAllStaffs={getAllStaffs}
          item={item}
        />
      ) : null}
    </div>
  )
}

export default ManageStaff
