import React, { useState, useEffect } from 'react'
import Pagination from '../Pagination'
import apiPath from '../../utils/pathObj'
import { apiGet, apiPost } from '../../utils/apiFetch'
import dayjs from 'dayjs'
import useToastContext from 'hooks/useToastContext'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import className from 'classnames'
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const PaymentSettlement = () => {
  const { t } = useTranslation()
  const [sort, setSort] = useState({})
  const [page, setPage] = useState(1)
  const [isDelete] = useState(false)
  const [staffs, setStaffs] = useState([])
  const notification = useToastContext()
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [offerFilterState, setOfferFilterState] = useState({
    page: '',
    sortKey: '',
    sortType: ''
  })
  const { sortKey, sortType } = offerFilterState

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handleSort = (sortKeys, sortTypes, state) => {
    setOfferFilterState({ sortKey: sortKeys, sortType: sortTypes })
    setSort({ [state]: true })
  }

  const getAllAgents = async () => {
    let result
    try {
      let payload = {
        page,
        sortType,
        sortKey
      }

      const path = apiPath.agentPaymentList
      result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        setStaffs(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: response.page,
          pageCount: response.totalPages,
          perPageItem: response?.docs.length,
          totalItems: response.totalDocs
        })
      }
    } catch (error) {
      console.log('error in get all staffs list==>>>> result:', error)
    }
  }

  const paymentRequest = async agentId => {
    try {
      if (
        window.confirm(t('ARE_YOU_SURE_TO_SEND_PAYMENT_SETTLEMENT_REQUEST'))
      ) {
        const path = apiPath.paymentSettlementRequest
        const result = await apiPost(path, { agentId })
        if (result?.status === 200) {
          notification.success(result?.data?.message)
        } else {
          notification.error(result?.data?.message)
        }
      }
    } catch (error) {
      console.log('error in get all staffs list==>>>> result:', error)
    }
  }

  useEffect(() => {
    getAllAgents()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, offerFilterState])

  return (
    <div className='p-4 md:p-8'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('PAYMENT_AGENT_NAME')}
                  <button className='ml-3'>
                    <img
                      name='first_name'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort?.firstNameDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'firstNameUpArrow')
                      }
                    />
                    <img
                      name='first_name'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.firstNameUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'firstNameDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 '>
                <div className='flex justify-start'>
                  {t('PAYMENT_SETTLEMENT_DATE')}
                  <button className='ml-3'>
                    <img
                      name='lastPaymentSettlementDate'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort?.dateDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'dateUpArrow')
                      }
                    />
                    <img
                      name='lastPaymentSettlementDate'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.dateUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'dateDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              {/* <th scope='col' className='py-3 px-3 font-normal'>
                Last Settlement Time
              </th> */}
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('O_OFFER_AMOUNT')} ({t('O_LEK')})
                  <button className='ml-3'>
                    <img
                      name='wallet_amount'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort?.amountDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'amountUpArrow')
                      }
                    />
                    <img
                      name='wallet_amount'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.amountUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'amountDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 text-left'></th>
            </tr>
          </thead>
          <tbody>
            {staffs.map((item, i) => {
              return (
                <tr
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  style={i % 2 ? { background: '#F9F9F9' } : {}}
                >
                  <td className='py-3 px-6 border-r font-medium text-gray-900 dark:text-white'>
                    {i + 1 + 10 * (page - 1)}
                  </td>
                  <td className='py-3 px-6 border-r text-left'>
                    {item?.first_name + ' ' + item?.last_name}
                  </td>
                  <td className='py-3 px-6 border-r text-right'>
                    {item?.lastPaymentSettlementDate
                      ? dayjs(item?.lastPaymentSettlementDate).format(
                          'DD-MM-YYYY hh:mm A'
                        )
                      : ''}
                  </td>
                  <td className='py-3 px-6 border-r text-right'>
                    {item?.wallet_amount || 0}
                  </td>
                  <td className='py-3 px-6 border-r text-LightBlue text-base text-left'>
                    <span
                      className='text-base'
                      style={{ cursor: 'pointer' }}
                      onClick={() => paymentRequest(item?._id)}
                    >
                      {t('PAYMENT_REQUEST_TO_PAY')}
                    </span>
                  </td>
                </tr>
              )
            })}
            {isEmpty(staffs) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={8}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
        <Pagination
          handlePageClick={handlePageClick}
          options={paginationObj}
          isDelete={isDelete}
          page={page}
        />
      </div>
    </div>
  )
}

export default PaymentSettlement
