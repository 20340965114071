import React, { useEffect, useState } from 'react'
import { apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import OInputField from 'components/reusable/OInputField'
import { useTranslation } from 'react-i18next'
import OImage from 'components/reusable/OImage'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import FormDateRangePicker from 'components/shared/datePicker/FormDateRangePicker'
import ErrorMessage from 'components/ErrorMessage'

const EditOffer = ({ setEditShowModal, getAllOffers, item }) => {
  const { t } = useTranslation()
  const [expiryFrom, setExpiryFrom] = useState(item?.expiryFrom)
  const [expiryTo, setExpiryTo] = useState(item?.expiryTo)
  const [dateErrors, setDateErrors] = useState({
    startDate: '',
    endDate: ''
  })
  const [passportPic, setPassportPic] = useState(item?.image)
  const [imageError, setImageError] = useState('')
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      title: item?.title,
      image: item?.image,
      actualPrice: item?.actualPrice,
      offerPrice: item?.offerPrice,
      expiryFrom: item?.expiryFrom,
      expiryTo: item?.expiryTo,
      description: item?.description
    }
  })

  const handleDateChange = (start, end) => {
    setExpiryFrom(dayjs(start).toDate())
    setExpiryTo(dayjs(end).toDate())
  }

  const validateFiles = passportPics => {
    let picError = ''
    const supportedTypes = ['image/jpeg', 'image/jpg', 'image/png']

    if (!isEmpty(supportedTypes.includes(passportPics.type))) {
      picError = t('ONLY_JPEG_AND_PNG_ARE_SUPPORTED')
    }

    if (picError) {
      setImageError(picError)
      return false
    } else {
      setImageError('')
      return true
    }
  }

  const handleFileChangeLogo = e => {
    const image = e?.target?.files[0]
    const isValidImage = validateFiles(image)
    if (isValidImage) {
      setPassportPic(image)
    } else {
      setPassportPic(null)
    }
  }

  useEffect(() => {
    if (dateErrors.startDate || dateErrors.endDate) {
      validateFunc()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryFrom, expiryTo])

  const validateFunc = () => {
    if (passportPic) {
      setImageError(false)
    } else {
      setImageError(true)
    }
    if (!expiryFrom && !expiryTo) {
      setDateErrors({
        startDate: t('PLEASE_SELECT_START_DATE'),
        endDate: t('PLEASE_SELECT_END_DATE')
      })
      return false
    }
    if (!expiryFrom) {
      setDateErrors({
        startDate: t('PLEASE_PROVIDE_START_DATE')
      })
      return false
    } else {
      setDateErrors({
        ...dateErrors,
        startDate: ''
      })
    }
    if (!expiryTo) {
      setDateErrors({
        endDate: t('PLEASE_SELECT_END_DATE')
      })
      return false
    } else {
      setDateErrors({
        ...dateErrors,
        endDate: ''
      })
    }
    setDateErrors({})
    return true
  }

  const notification = useToastContext()
  const [editOfferLoading, setEditOfferLoading] = useState(false)
  const handleSubmitForm = async data => {
    try {
      const isValid = validateFunc()
      if (!isValid) {
        return
      }

      const formData = new FormData()
      formData.append('title', data.title)

      formData.append('offerPrice', data.offerPrice)
      formData.append('actualPrice', data.actualPrice)
      formData.append('description', data.description)
      if (expiryFrom) {
        formData.append('expiryFrom', expiryFrom)
      }
      if (expiryTo) {
        formData.append('expiryTo', expiryTo)
      }
      if (passportPic) {
        formData.append('image', passportPic)
      }
      const res = await apiPut(
        apiPath.editMerchantOffer + '/' + item._id,
        formData
      )
      if (res.data.success === true) {
        setEditShowModal(false)
        notification.success(res.data.message)
      } else {
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setEditOfferLoading(false)
      getAllOffers()
    }
  }
  const preventMaxValueTitle = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 15) {
      e.target.value = e.target.value.slice(0, 15)
    }
  }
  const preventMaxValueDescription = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 200) {
      e.target.value = e.target.value.slice(0, 200)
    }
  }

  const handleDecimal = e => {
    var value = e.target.value
    const pattern = /^\d{0,5}(\.\d{0,2})?$/
    if (e.target.value === '') {
      e.target.value = e.target.value.slice(e.target.value?.length - 1, -1)
    }
    const isValid = pattern.test(value)
    if (!isValid) {
      value = value.slice(0, -1)
    }
    setValue('actualPrice', value)
  }
  const handleDecimalOffer = e => {
    var value = e.target.value
    const pattern = /^\d{0,5}(\.\d{0,2})?$/
    if (e.target.value === '') {
      e.target.value = e.target.value.slice(e.target.value?.length - 1, -1)
    }
    const isValid = pattern.test(value)
    if (!isValid) {
      value = value.slice(0, -1)
    }

    setValue('offerPrice', value)
  }
  var pressedKeys = []

  const handleKeyDown = e => {
    pressedKeys.push(e.key)
    const lastKey = pressedKeys[pressedKeys.length - 2]
    if (lastKey === '.') {
      if (['-', '.', '+', 'e'].includes(e.key)) {
        e.preventDefault()
      }
    } else if (['-', '+', 'e'].includes(e.key)) {
      e.preventDefault()
    }
  }

  return (
    <>
      <div
        id='edit-offer'
        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-[440px] w-80 right-0 top-0 '
        aria-labelledby='drawer-label'
      >
        <div>
          <button
            onClick={() => setEditShowModal(false)}
            title={t('O_CLOSE')}
            type='button'
            data-drawer-hide='drawer-example'
            aria-controls='drawer-example'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <img src='./images/close-circle.png' alt='close' />

            <span className='sr-only'>{t('CLOSE_MENU')}</span>
          </button>
        </div>
        <h3 className='font-medium text-[18px] mb-4 '>
          {t('OFFER_EDIT_OFFER')}
        </h3>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className='relative my-3'>
            <OInputField
              type='text'
              name='title'
              inputLabel={
                <>
                  {t('OFFER_TITLE')}
                  <span className='text-red-500'>*</span>
                </>
              }
              autoFocus
              onInput={e => preventMaxValueTitle(e)}
              register={register('title', {
                required: t('PLEASE_ENTER_VOUCHER_OFFER_TITLE'),
                minLength: {
                  value: 3,
                  message: t('MINIMUM_LENGTH_MUST_BE_3')
                },
                maxLength: {
                  value: 15,
                  message: t('MAXIMUM_LENGTH_MUST_BE_15')
                },

                validate: value => {
                  return !!value.trim() ? true : t('WHITE_SPACE_NOT_ALLOWED')
                }
              })}
              errors={errors}
            />
          </div>

          <div className='relative my-3'>
            <textarea
              name='description'
              id='description'
              placeholder=' '
              className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
              onInput={e => preventMaxValueDescription(e)}
              maxLength={200}
              {...register('description', {
                required: t('PLEASE_ENTER_DESCRIPTION_ABOUT_OFFER'),
                minLength: {
                  value: 10,
                  message: t('MINIMUM_LENGTH_MUST_BE_10')
                },
                maxLength: {
                  value: 200,
                  message: t('MAXIMUM_CHARACTER_CAN_BE_200')
                }
              })}
            />
            <label
              htmlFor='description'
              className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
            >
              {t('OFFER_DESCRIPTION')}
              <span className='text-red-500'>*</span>
            </label>
            <ErrorMessage message={errors?.description?.message} />
          </div>

          <div className='pt-2 relative'>
            <div className='relative z-0   w-full group'>
              <div className='flex justify-between items-center py-2 h-[46px] px-3 w-full text-sm text-gray-900 bg-transparent border border-1  rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                <div className='w-full'>
                  <input
                    type='file'
                    name='image'
                    placeholder='image'
                    className='form-control relative z-20 w-full'
                    errors={errors}
                    accept='image/jpeg, image/png, image/jpg'
                    onChange={handleFileChangeLogo}
                  />
                </div>
                <label
                  for='floating_file'
                  className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                >
                  {t('IMAGE_THUMBNAILS')}
                  <span className='text-red-500'>*</span>
                </label>
                <div className='flex items-center bg-white'>
                  <OImage
                    src={
                      passportPic === item.image
                        ? item?.image
                        : URL.createObjectURL(passportPic)
                    }
                    fallbackUrl='/images/default-product.jpg'
                    className='w-8 h-8 rounded-full mr-3'
                    alt='card'
                  />
                </div>
              </div>
              {imageError && (
                <ErrorMessage message={t('PLEASE_PROIVE_IMAGE_THUMBNAILS')} />
              )}
            </div>
          </div>
          <div className='relative  my-3'>
            <OInputField
              type='number'
              name='actualPrice'
              id='actualPrice'
              errors={errors}
              step='any'
              inputLabel={
                <>
                  {t('OFFER_ACTUAL_PRICE')}
                  <span className='text-red-500'>*</span>
                </>
              }
              register={register('actualPrice', {
                required: t('PLEASE_ENTER_ACTUAL_PRICE'),
                onChange: e => handleDecimal(e),
                validate: val => {
                  return val !== '0'
                    ? true
                    : t('VALUE_CAN_NOT_BE_LESS_THAN_0.01')
                }
              })}
              onKeyDown={e => handleKeyDown(e)}
            />
          </div>

          <div className='relative  my-3'>
            <OInputField
              type='number'
              name='offerPrice'
              id='offerPrice'
              errors={errors}
              step='any'
              inputLabel={
                <>
                  {t('OFFER_OFFER_PRICE')}
                  <span className='text-red-500'>*</span>
                </>
              }
              register={register('offerPrice', {
                required: t('PLEASE_ENTER_OFFER_PRICE'),
                onChange: e => handleDecimalOffer(e),
                validate: val => {
                  const { actualPrice } = getValues()
                  if (Number(actualPrice) < Number(val)) {
                    return t('OFFER_PRICE_MUST_BE_LESS_THAN_ACTUAL_PRICE')
                  }
                  return val !== '0'
                    ? true
                    : t('VALUE_CAN_NOT_BE_LESS_THAN_0.01')
                }
              })}
              onKeyDown={e => handleKeyDown(e)}
            />
          </div>
          <div className='flex my-3 gap-4'>
            <FormDateRangePicker
              expiryDateFrom
              expiryDateTo
              expiryFrom={item?.expiryFrom}
              expiryTo={item?.expiryTo}
              handleDateChange={handleDateChange}
              addFlex={false}
              errors={dateErrors}
            />
          </div>
          <button
            class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
            type='submit'
          >
            {editOfferLoading ? 'Loading...' : <>{t('O_EDIT')}</>}
          </button>
        </form>
      </div>
      <div class='bg-gray-900  bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 overlay' />
    </>
  )
}

export default EditOffer
