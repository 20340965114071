import React, { useState, useEffect } from 'react'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import Pagination from '../Pagination'
import dayjs from 'dayjs'
import apiPath from '../../utils/pathObj'
import { apiGet } from '../../utils/apiFetch'
import { useTranslation } from 'react-i18next'
import WalletList from './WalletList'

const Wallet = () => {
  const { t } = useTranslation()
  const [isReset, setIsReset] = useState(false)
  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState('')
  const [wallets, setWallets] = useState([])
  const [walletBalance, setWalletBalance] = useState({})
  const [isDelete, setIsDelete] = useState(false)
  const [offerFilterState, setOfferFilterState] = useState({
    startDate: '',
    endDate: '',
    page: '',
    status: ''
  })
  const { category, status } = offerFilterState
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })

  const pageSize = 10

  const getAllWallet = async payload => {
    let result
    try {
      if (payload?.deletePage) {
        payload = {
          page,
          pageSize,
          category,
          status,
          startDate: startDate ? dayjs(startDate).format('MM-DD-YYYY') : null,
          endDate: endDate ? dayjs(endDate).format('MM-DD-YYYY') : null
        }
        if (wallets?.length >= 1) {
          setPage(page - 1)
          setIsDelete(true)
          setPaginationObj({ ...paginationObj, page: page - 1 })
        }
      } else {
        payload = {
          page,
          pageSize,
          status,
          startDate: startDate ? dayjs(startDate).format('MM-DD-YYYY') : null,
          endDate: endDate ? dayjs(endDate).format('MM-DD-YYYY') : null
        }
        setIsDelete(false)
      }
      const path = apiPath.getAllWallet
      result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        setWallets(response?.docs)
        setWalletBalance(response)
        setPaginationObj({
          ...paginationObj,
          page: response.page,
          pageCount: response.totalPages,
          perPageItem: response?.docs.length,
          totalItems: response.totalDocs
        })
      }
    } catch (error) {
      console.log('error in get all staffs list==>>>> result:', error)
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  useEffect(() => {
    getAllWallet()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, startDate, endDate, status])

  const handleDateChange = (start, end) => {
    setStartDate(start)
    setEndDate(end)
    setPage(1)
  }

  const handleChange = event => {
    setOfferFilterState({
      ...offerFilterState,
      [event.target.name]: event.target.value
    })
    setPage(1)
  }
  const handleReset = () => {
    setEndDate('')
    setStartDate('')
    setIsReset(true)
    setOfferFilterState({
      ...offerFilterState,
      category: '',
      keyword: '',
      status: '',
      start_date: '',
      end_date: ''
    })
  }

  return (
    <div className='main_wrap'>
      <div className=''>
        <div className=''>
          <div className='pt-1 px-3 py-4'>
            <div className='bg-white'>
              <div className='col-span-2 flex flex-wrap items-center justify-between px-2'>
                <div class='common-heading  py-2 md:py-5 pb-2 flex items-center'>
                  <h3 class='xl:flex block items-center mb-0 text-slate-900 font-medium md:text-base'>
                    &nbsp;
                  </h3>
                </div>
                <div className='flex items-center lg:pt-0 pt-3 flex-wrap justify-center '>
                  <div className='2xl:ml-auto xl:ml-0 lg:pt-0 md:mt-5'>
                    <label
                      for='default-search'
                      className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
                    >
                      {t('O_SEARCH')}
                    </label>
                    <div className='flex w-full sm:w-[450px] items-center'>
                      <h2 className='text-[22px] md:text-[30px] font-medium w-full text-left md:text-right mr-3'>
                        <span className='text-base'>
                          {t('WALLET_AVAILABLE_BALANCE')}{' '}
                        </span>
                        {t('O_LEK')} {walletBalance?.wallet_amount?.toFixed(2)}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <form className='px-0 md:px-4 py-3'>
                <div className='flex items-center justify-between flex-wrap'>
                  <h4 className='m-0 text-[#222] md:text-md text-lg md:text-start  md:w-auto w-full font-medium mr-12'>
                    {t('DASHBOARD_AGENT_RECENT_TRANSACTION')}
                  </h4>
                  <div className='text-right'>
                    <div class='flex grid-cols-1 md:grid-cols-6 mt-2 flex-wrap sm:flex-nowrap'>
                      <ODateRangePicker
                        handleDateChange={handleDateChange}
                        isReset={isReset}
                        setIsReset={setIsReset}
                      />
                      <div className='flex items-center ml-3 mb-3 w-full sm:w-1/3'>
                        <select
                          id='status'
                          name='status'
                          className='block py-3 p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#E9EDF9] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          value={status}
                          onChange={handleChange}
                        >
                          <option value=''>Status</option>
                          <option value='completed'>{t('O_COMPLETED')}</option>
                          <option value='pending'>{t('O_PENDING')}</option>
                          <option value='failed'>{t('O_FAILED')}</option>
                        </select>
                      </div>
                      <button
                        type='button'
                        onClick={handleReset}
                        className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                      >
                        {t('O_RESET')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <WalletList
                wallets={wallets}
                getAllWallet={getAllWallet}
                page={page}
              />
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                isDelete={isDelete}
                page={page}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wallet
