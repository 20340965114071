import { useContext, useEffect, useState } from 'react'
import { apiGet, apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'

const Careers = () => {
  const { t } = useTranslation()
  const [content, setContent] = useState('')
  const notification = useToastContext()
  const { logoutUser } = useContext(AuthContext)

  const getStaticContent = async () => {
    try {
      const path = apiPath.getStaticContent
      const payload = {
        slug: 'careers'
      }
      const result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        setContent(response?.content)
      }
    } catch (error) {
      console.log('error:', error.message)
      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }
  const updateStaticContent = async () => {
    try {
      const data = {
        title: 'Careers',
        content
      }
      const path = `${apiPath.getStaticContent}?slug=careers`
      const result = await apiPut(path, data)
      if (result?.status === 200) {
        notification.success(result?.data?.message)
        getStaticContent()
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error.message)
    }
  }
  useEffect(() => {
    getStaticContent()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg'>
            <div className=' border-b-[#E3E3E3] grid 2xl:grid-cols-3 xl:grid-cols-2 lg:grid lg:grid-cols-1 gap-2 px-4 '>
              <div className='col-span-2 flex flex-wrap  items-center' />
            </div>
            <div className='p-5 shadow-md'>
              <ReactQuill theme='snow' value={content} onChange={setContent} />
            </div>
          </div>
          <div className='2xl:ml-auto xl:ml-0 lg:pt-0 pt-2'>
            <div className='flex'>
              <button
                className='bg-LightBlue flex my-4 text-sm px-8 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue whitespace-nowrap'
                onClick={updateStaticContent}
              >
                {t('O_UPDATE')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Careers
