import React, { useEffect, useState } from 'react'
import useToastContext from 'hooks/useToastContext'
import pathObj from 'utils/pathObj'
import Loader from 'layout/Loader'
import OtpInput from 'react18-input-otp'
import { apiPost } from 'utils/apiFetch'
import { useTranslation } from 'react-i18next'
import ErrorMessage from 'components/ErrorMessage'

function AgentOtpScreen ({ paymentDetails, otp, setOtp, otpError }) {
  const { t } = useTranslation()
  const notification = useToastContext()
  var isTimer =
    Number(localStorage.getItem('seconds')) > 0
      ? Number(localStorage.getItem('seconds'))
      : 60
  const [seconds, setSeconds] = useState(isTimer)
  const [minutes, setMinutes] = useState('00')
  // eslint-disable-next-line no-unused-vars
  const [timerActive, setTimerActive] = useState(true)

  const handleOTPChange = enteredOtp => {
    setOtp(enteredOtp)
  }

  const handleResendOTP = async () => {
    try {
      if (seconds !== 0) {
        return
      }
      const path = `${pathObj.cashInPreview}?type=mobile`
      const res = await apiPost(path, paymentDetails)
      if (res.data.success === true) {
        notification.success(res.data.message)
        setSeconds(60)
        setMinutes(0)
      } else {
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    }
  }

  let timerRef
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timerRef = setInterval(() => {
      if (minutes >= 0) {
        if (seconds === 0) {
          if (minutes > 0) {
            setSeconds(60)
            setMinutes(prev => prev - 1)
          } else {
            setSeconds(0)
          }
        } else {
          setSeconds(prev => prev - 1)
        }
      } else {
        clearInterval(timerRef)
        setTimerActive(false)
      }
    }, 1000)
    return () => clearInterval(timerRef)
  })
  localStorage.setItem('seconds', seconds)

  return (
    <div className='login-page'>
      <Loader />
      <div
        className=' main_wrap md:pl-40 md:pr-40 pl-4 pr-4  flex flex-col justify-between'
        style={{ zIndex: '9999' }}
      >
        <div className='bg-white main_panel p-0 rounded-3xl  register'>
          <div className='g-0 grid-rows-1'>
            <div className='grid xl:grid-cols-2'>
              <div>
                <div className=' flex justify-center '>
                  <div className='w-[352px] flex items-center '>
                    <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded'>
                      <div className=' flex-auto'>
                        <section className='w-full'>
                          <div className='font-semibold '>
                            {t('O_ENTER_OTP')}
                          </div>
                          <OtpInput
                            inputStyle={{
                              border: 'solid 1px #dfdfdf',
                              width: '5rem',
                              height: '2.4rem',
                              marginRight: '.5rem',
                              outline: 'none',
                              borderRadius: '8px'
                            }}
                            shouldAutoFocus
                            containerStyle='text-center mt-2'
                            value={otp}
                            onChange={handleOTPChange}
                            isInputNum={true}
                            numInputs={4}
                            separator={<span> </span>}
                          />
                          <ErrorMessage message={otpError} />

                          <div className='text-xs mt-8 text-center'>
                            {t('O_DID_NOT_RECEIVE_OTP')}{' '}
                            <span
                              className='text-violet-700 '
                              onClick={handleResendOTP}
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              <span
                                style={{
                                  color: seconds === 0 ? 'blue' : 'gray'
                                }}
                              >
                                {' '}
                                {t('O_RESEND_OTP')}{' '}
                              </span>
                              ( {t('O_EXPIRING_IN')} {minutes}:
                              {seconds < 10 ? '0' + seconds : seconds})
                            </span>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentOtpScreen
