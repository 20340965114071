import ErrorMessage from 'components/ErrorMessage'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { validationRules } from 'utils/constants'
import Loader from '../../layout/Loader'
import OInputField from 'components/reusable/OInputField'
import OImage from 'components/reusable/OImage'
import useToastContext from 'hooks/useToastContext'
import { apiPut, apiGet } from 'utils/apiFetch'
import pathObj from '../../utils/pathObj'
import { validateFile } from 'utils/reusableMethods'
import { preventMax } from 'utils/validations'
import AuthContext from 'context/AuthContext'
import { useTranslation } from 'react-i18next'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import Header from 'components/Header'

function CompleteProfile () {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { loginObj, user, language } = useContext(AuthContext)
  const notification = useToastContext()
  const [address, setAddress] = useState('')
  const [addressError, setAddressError] = useState('')
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null
  })
  const [fileMsg, setFileMsg] = useState({
    business_logo: ''
  })
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })

  console.log('loginObj', loginObj)

  const [recordsTemp, setRecordTemp] = useState('')
  const [listTemp, setListTemp] = useState('')
  const [cat, setCat] = useState(user?.category)
  const [subCat, setSubCat] = useState(user?.sub_category)
  const [isCatError, setIsCatError] = useState(false)
  const [businessImg, setBusinessImg] = useState({ img: null, url: null })

  const handleCheckError = () => {
    if (businessImg?.img == null) {
      setFileMsg({
        ...fileMsg,
        business_logo: t('PLEASE_UPLOAD_THE_BUSINESS_LOGO')
      })
    }
  }

  const checkFields = () => {
    let isValid = true
    if (!cat) {
      setIsCatError(true)
      isValid = false
    }
    if (!cat) {
      setIsCatError(true)
      isValid = false
    } else {
      setIsCatError(false)
    }
    if (address === '') {
      setAddressError(true)
      isValid = false
    } else {
      setAddressError(false)
    }
    return isValid
  }

  const handleSubmitForm = async data => {
    try {
      if (fileMsg.business_logo) {
        return
      }
      const isValid = checkFields()
      if (!isValid) return
      const id = loginObj._id || location?.state?._id
      const formData = new FormData()
      formData.append('first_name', data.first_name)
      formData.append('last_name', data.last_name)
      formData.append('email', data.email)
      formData.append('country_code', data.country_code)
      formData.append('mobile', data.mobile)
      formData.append('business_name', data.business_name)
      if (address) {
        formData.append('business_address', address)
      }
      if (coordinates) {
        formData.append('latitude', coordinates?.lat)
        formData.append('longitude', coordinates?.lng)
      }
      formData.append(
        'business_logo',
        businessImg?.img !== null ? businessImg?.img : data.business_logo[0]
      )
      formData.append('license', data.license)
      formData.append('description', data.description)
      if (cat) {
        formData.append('category', cat)
      }
      if (subCat) {
        formData.append('sub_category', subCat)
      }

      const res = await apiPut(
        pathObj.updateCompleteProfile + '/' + id,
        formData
      )
      if (res.data.success === true) {
        notification.success(res?.data?.message)
        let type = 'login'
        navigate(`/${type}`)
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    }
  }

  const getCategoryList = async () => {
    try {
      const res = await apiGet(pathObj.getCategoryList, {})
      setRecordTemp(res?.data?.results?.category_list)
      setListTemp(res?.data?.results?.sub_category_list)
    } catch (error) {
      console.log('error:', error)
    }
  }

  const preventMaxValue = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 15) {
      e.target.value = e.target.value.slice(0, 15)
    }
  }
  const preventMaxValueName = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 50) {
      e.target.value = e.target.value.slice(0, 50)
    }
  }
  const preventMaxValueDescription = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 200) {
      e.target.value = e.target.value.slice(0, 200)
    }
  }
  const preventMaxAddress = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  const handleSelect = async value => {
    const result = await geocodeByAddress(value)
    const ll = await getLatLng(result[0])
    setAddress(value)
    setCoordinates(ll)
  }
  const preventMaxValueLicense = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 30) {
      e.target.value = e.target.value.slice(0, 30)
    }
  }

  useEffect(() => {
    if (watch('business_logo')?.length > 0) {
      setBusinessImg({
        url: URL.createObjectURL(watch('business_logo')[0]),
        img: watch('business_logo')[0]
      })
      let business_logo_msg = validateFile(watch('business_logo')[0])
      if (business_logo_msg) {
        if (businessImg?.img) {
          setFileMsg({
            ...fileMsg,
            business_logo: business_logo_msg
          })
        }
      } else {
        setFileMsg({
          ...fileMsg,
          business_logo: ''
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('business_logo'), language])
  useEffect(() => {
    getCategoryList()
  }, [language])

  useEffect(() => {
    if (
      (location?.state != null && Object.keys(loginObj)?.length === 0) ||
      (location?.state == null && Object.keys(loginObj)?.length !== 0) ||
      (location?.state != null && Object.keys(loginObj)?.length !== 0)
    ) {
      setValue('first_name', loginObj.first_name || location?.state?.first_name)
      setValue('last_name', loginObj.last_name || location?.state?.last_name)
      setValue('email', loginObj.email || location?.state?.email)
      setValue(
        'country_code',
        loginObj.country_code || location?.state?.country_code
      )
      setValue('mobile', loginObj.mobile || location?.state?.mobile)
      setValue(
        'business_name',
        loginObj.business_name || location?.state?.business_name
      )
      setValue(
        'business_address',
        loginObj.business_address || location?.state?.business_address
      )

      setValue('license', loginObj.license || location?.state?.license)
      setValue('category', loginObj.category || location?.state?.category)
      setValue(
        'sub_category',
        loginObj.sub_category || location?.state?.sub_category
      )
      setValue(
        'description',
        loginObj.description || location?.state?.description
      )
    } else {
      navigate('/login')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginObj, language])

  return (
    <div className='login-page h-full'>
      <Header />
      <Loader />
      <div className='main_wrap md:pl-40 md:pr-40 pl-4 pr-4 pt-16'>
        <div className='bg-white main_panel p-0 rounded-3xl overflow-hidden register'>
          <div className='g-0 grid-rows-1'>
            <div className='grid xl:grid-cols-2'>
              <div className='flex flex-wrap'>
                <div className='w-full'>
                  <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded'>
                    <div className=' py-5 flex-auto'>
                      <div className='tab-content tab-space '>
                        <form
                          className='md:max-w-lg m-auto px-3'
                          onSubmit={handleSubmit(handleSubmitForm, () => {
                            const isValid = checkFields()
                            if (!isValid) return
                          })}
                        >
                          <div className='text-xl font-semibold py-5 block'>
                            {t('COMPLETE_COMPLETE_PROFILE')}
                          </div>
                          <div className='grid md:grid-cols-2 gap-4 md:gap-6 '>
                            <div className='relative'>
                              <OInputField
                                type='text'
                                name='first_name'
                                inputLabel={
                                  <>
                                    {t('O_FIRST_NAME')}
                                    <span className='text-red-500'>*</span>
                                  </>
                                }
                                autoFocus
                                onInput={e => preventMaxValue(e)}
                                register={register('first_name', {
                                  required: t('PLEASE_ENTER_FIRST_NAME'),
                                  minLength: {
                                    value: 3,
                                    message: t('MINIMUM_LENGTH_MUST_BE_3')
                                  },
                                  maxLength: {
                                    value: 15,
                                    message: t('MAXIMUM_LENGTH_MUST_BE_15')
                                  }
                                })}
                                errors={errors}
                              />
                            </div>

                            <div className='relative'>
                              <OInputField
                                name='last_name'
                                inputLabel={
                                  <>
                                    {t('O_LAST_NAME')}
                                    <span className='text-red-500'>*</span>
                                  </>
                                }
                                errors={errors}
                                type='text'
                                onInput={e => preventMaxValue(e)}
                                register={register('last_name', {
                                  required: t('PLEASE_ENTER_LAST_NAME'),
                                  minLength: {
                                    value: 3,
                                    message: t('MINIMUM_LENGTH_MUST_BE_3')
                                  },
                                  maxLength: {
                                    value: 15,
                                    message: t('MAXIMUM_LENGTH_MUST_BE_15')
                                  }
                                })}
                              />
                            </div>
                          </div>
                          <div className='grid md:grid-cols-6 gap-4 md:gap-6 mt-5'>
                            <div className='relative md:col-span-2'>
                              <OInputField
                                greyClass
                                name='country_code'
                                inputLabel={<>{t('O_COUNTRY_CODE')}</>}
                                errors={errors}
                                type='select'
                                register={register('country_code', {})}
                                disabled
                                selectOptions={[
                                  <option value='355' key='355'>
                                    +355
                                  </option>
                                ]}
                              />
                            </div>
                            <div className='relative z-0 md:col-span-4'>
                              <OInputField
                                greyClass
                                name='mobile'
                                inputLabel={<>{t('O_MOBILE_NUMBER')}</>}
                                errors={errors}
                                type='number'
                                onKeyDown={e => {
                                  if (['-', '+', 'e'].includes(e.key)) {
                                    e.preventDefault()
                                  }
                                }}
                                onInput={e => preventMax(e, 9)}
                                disabled
                                register={register('mobile', {})}
                              />
                            </div>
                          </div>
                          <div className='grid grid-cols-1 gap-4 md:gap-6 mt-5'>
                            <div className='relative'>
                              <OInputField
                                greyClass
                                name='email'
                                inputLabel={<>{t('O_EMAIL')}</>}
                                errors={errors}
                                type='email'
                                disabled={true}
                                register={register('email', {})}
                              />
                            </div>
                          </div>
                          <div className='grid grid-cols-1 gap-4 md:gap-6 mt-5'>
                            <div className='relative'>
                              <OInputField
                                name='business_name'
                                inputLabel={
                                  <>
                                    {t('O_BUSINESS_NAME')}
                                    <span className='text-red-500'>*</span>
                                  </>
                                }
                                errors={errors}
                                type='text'
                                onInput={e => preventMaxValueName(e)}
                                register={register('business_name', {
                                  required: t('PLEASE_ENTER_BUSINESS_NAME'),
                                  minLength: {
                                    value: 3,
                                    message: t('MINIMUM_LENGTH_MUST_BE_3')
                                  },
                                  maxLength: {
                                    value: 50,
                                    message: t('MAXIMUM_LENGTH_MUST_BE_50')
                                  }
                                })}
                              />
                            </div>
                          </div>
                          <div className='grid grid-cols-1 gap-4 md:gap-6 mt-5'>
                            <div className='relative'>
                              <PlacesAutocomplete
                                value={address}
                                onChange={e => {
                                  setAddress(e)
                                  if (e) {
                                    setAddressError(false)
                                  }
                                }}
                                onSelect={handleSelect}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading
                                }) => (
                                  <div class='relative w-full '>
                                    <OInputField
                                      {...getInputProps({
                                        type: 'text',
                                        name: 'business_address',
                                        errors: errors,
                                        id: 'business_address',
                                        inputLabel: (
                                          <>
                                            {t('O_BUSINESS_ADDRESS')}
                                            <span className='text-red-500'>
                                              *
                                            </span>
                                          </>
                                        ),
                                        onInput: e => preventMaxAddress(e)
                                      })}
                                    />
                                    {addressError && (
                                      <ErrorMessage
                                        message={t(
                                          'PLEASE_SELECT_BUSINESS_ADDRESS'
                                        )}
                                      />
                                    )}
                                    <span className='absolute right-[10px] top-[10px] p-2 bg-white '>
                                      {' '}
                                      <img
                                        src='./images/location-outline.png'
                                        alt='location-logo'
                                      />
                                    </span>
                                    <div className='autocomplete-dropdown-container'>
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                          ? 'suggestion-item--active'
                                          : 'suggestion-item'
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: '#fafafa',
                                              cursor: 'pointer'
                                            }
                                          : {
                                              backgroundColor: '#ffffff',
                                              cursor: 'pointer'
                                            }
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style
                                              }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </div>
                          </div>
                          <div className='grid md:grid-cols-2 gap-4 md:gap-6 mt-5'>
                            <div className='relative z-0   w-full group'>
                              <div className='flex justify-between items-center py-2 h-[46px] px-3 w-full text-sm text-gray-900 bg-transparent border border-1  rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                                <div className='w-full'>
                                  <input
                                    type='file'
                                    accept='image/png, image/jpg, image/jpeg'
                                    id='business_logo'
                                    className='form-control relative z-20 w-full'
                                    placeholder='image'
                                    name='business_logo'
                                    {...register('business_logo', {})}
                                  />
                                </div>
                                <label
                                  htmlFor='business_logo'
                                  className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                                >
                                  Business logo
                                  <span className='text-red-500'>*</span>
                                </label>
                              </div>
                              <ErrorMessage
                                message={
                                  errors?.business_logo?.message ||
                                  fileMsg?.business_logo
                                }
                              />
                              {(watch('business_logo')?.length > 0 ||
                                businessImg?.url !== null) && (
                                <div className='mt-1'>
                                  <OImage
                                    src={
                                      businessImg?.url !== null
                                        ? businessImg?.url
                                        : URL.createObjectURL(
                                            watch('business_logo')[0]
                                          )
                                    }
                                    className='w-24 h-24'
                                    alt='card'
                                    style={{ borderRadius: '5%' }}
                                  />
                                </div>
                              )}
                            </div>

                            <div class='relative w-full '>
                              <OInputField
                                type='text'
                                name='license'
                                id='license'
                                inputLabel={
                                  <>
                                    {t('O_LICENSE')}
                                    <span className='text-red-500'>*</span>
                                  </>
                                }
                                onInput={e => preventMaxValueLicense(e)}
                                register={register('license', {
                                  required: t(
                                    'PLEASE_ENTER_SHOP_ACT_NUMBER_LICENSE'
                                  ),
                                  pattern: {
                                    value: validationRules.removeWhitespace,
                                    message:
                                      'Shop act number/license should be alphanumeric.'
                                  },
                                  minLength: {
                                    value: 3,
                                    message: t('MINIMUM_LENGTH_MUST_BE_3')
                                  }
                                })}
                                errors={errors}
                              />
                            </div>
                          </div>
                          <div className='grid md:grid-cols-2 gap-4 md:gap-6 mt-5'>
                            <div className='relative z-0 '>
                              <select
                                class='bg-white border border-gray-300 text-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:text-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                value={cat}
                                errors={errors}
                                onChange={e => {
                                  setCat(e.target.value)
                                  if (e.target.value === '') setSubCat('')
                                  if (e.target.value) {
                                    setIsCatError(false)
                                  }
                                }}
                              >
                                <option value={''}>
                                  {t('O_PLEASE_SELECT_CATEGORY')}
                                  <span className='text-red-500'>*</span>
                                </option>
                                {recordsTemp?.length &&
                                  recordsTemp?.map((i, key) => (
                                    <option key={key} value={i._id}>
                                      {i.name}
                                    </option>
                                  ))}
                              </select>
                              {isCatError && (
                                <ErrorMessage
                                  message={t('PLEASE_SELECT_CATEGORY')}
                                />
                              )}
                            </div>
                            <div class='relative w-full '>
                              <select
                                class='bg-white border border-gray-300 text-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:text-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                value={subCat}
                              >
                                <option value={''}>
                                  {t('O_PLEASE_SELECT_SUBCATEGORY')}
                                </option>
                                {listTemp?.length &&
                                  listTemp?.map(
                                    (i, key) =>
                                      i.category_id === cat && (
                                        <option key={key} value={i._id}>
                                          {i.name}
                                        </option>
                                      )
                                  )}
                              </select>
                            </div>
                          </div>
                          <div className='grid grid-cols-1 gap-4 md:gap-6 mt-5'>
                            <div className='relative'>
                              <textarea
                                name='description'
                                rows='3'
                                id='description'
                                className='dark:text-black block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                                placeholder=' '
                                maxLength={200}
                                onInput={e => preventMaxValueDescription(e)}
                                {...register('description', {
                                  required: t('PLEASE_ENTER_DESCRIPTION'),
                                  minLength: {
                                    value: 10,
                                    message: t('MINIMUM_LENGTH_MUST_BE_10')
                                  },
                                  maxLength: {
                                    value: 200,
                                    message: t('MAXIMUM_CHARACTER_CAN_BE_200')
                                  }
                                })}
                              />
                              <label
                                htmlFor='description'
                                className='dark:text-black peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                              >
                                {t('DESCRIPTION')}
                                <span className='text-red-500'>*</span>
                              </label>
                              <ErrorMessage
                                message={errors?.description?.message}
                              />
                            </div>
                          </div>

                          <button
                            onClick={() => handleCheckError()}
                            className='bg-[#7100fa] hover:bg-DarkBlue cursor-pointer w-full mt-3 text-white active:bg-slate-100 text-md px-8 py-4 rounded-lg font-normal shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'
                          >
                            {t('O_UPDATE')}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='md:flex justify-center items-center hidden bg-[#f8f9fa]'>
                <img src='./images/register_img.png' alt='' className='' />
              </div>
            </div>
          </div>
        </div>

        <div className='xl:flex justify-between py-3 sm:py-4 text-center'>
          <p className='mb-0 text-white'>{t('O_ALPAY')} </p>
          <ul className='list-none m-0 text-white flex flex-wrap justify-center'>
            <li className=''>
              <Link to='/privacy-policy' className='hover:text-black'>
                {t('O_PRIVACY_POLICY')}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/terms-and-conditions' className='hover:text-black'>
                {t('O_TERMS_AND_CONDITION')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/about-us' className='hover:text-black'>
                {t('O_ABOUT_US')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/contact-us' className='hover:text-black'>
                {t('O_CONTACT_US')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/faqs' className='hover:text-black'>
                {t('O_FAQS')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CompleteProfile
