import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { apiPost } from '../../utils/apiFetch'
import pathObj from '../../utils/pathObj'
import helpers from '../../utils/helpers'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'

const Table = ({ reports }) => {
  const { t } = useTranslation()
  const [data, setData] = useState(reports)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    setData(reports)
  }, [reports])

  const handleDateChangeE = (start, end) => {
    setStartDate(start)
    setEndDate(end)
  }

  const handleDownloadReport = async reportType => {
    let obj = data.find(res => res.key === reportType)
    try {
      if (isEmpty(obj.type)) {
        obj.type = 'pdf'
      }
      let sendData = {}
      sendData.reportType = obj.key
      sendData.type = obj.type
      if (startDate !== null)
        sendData.startDate = dayjs(startDate).format('YYYY-MM-DD')
      if (endDate !== null)
        sendData.endDate = dayjs(endDate).format('YYYY-MM-DD')
      const res = await apiPost(pathObj.downloadReports, sendData)
      if (res.data.success) {
        helpers.downloadFile(res?.data?.results?.filePath)
      }
    } catch (error) {
      console.log('error:', error)
    }
  }

  const handleDateChange = (e, key) => {
    setData(current => {
      return current.map(res =>
        res.key === key ? { ...res, [e.target.name]: e.target.value } : res
      )
    })
  }

  return (
    <div className='pt-4'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-max md:w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-medium text-xs text-gray-900 border border-[#E1E6EE]  bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('REPORTS')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('REPORTS_FROM')}
              </th>
              {/* <th scope='col' className='py-3 px-6 text-center'>
                {t('REPORTS_TO')}
              </th> */}
              <th scope='col' className='py-3 px-6 text-left'>
                {t('REPORTS_FORMAT')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('REPORTS_DOWNLOAD')}
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data?.map(
                (
                  {
                    key,
                    value,

                    type
                  },
                  i
                ) => (
                  <tr
                    key={i}
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  >
                    {console.log('key: ', key)}
                    <td className='py-3 px-6 text-black text-base border-r'>
                      {i + 1}
                    </td>
                    <td className='py-3 px-6 border-r font-medium'>{value} </td>
                    <td className='py-3 px-6 border-r flex justify-center flex-wrap'>
                      <ODateRangePicker
                        fromDate
                        toDate
                        handleDateChange={handleDateChangeE}
                      />
                    </td>

                    <td className='py-3 px-6 border-r'>
                      <select
                        id='countries'
                        value={type}
                        name='type'
                        onChange={e => handleDateChange(e, key)}
                        class='text-base text-gray-900 w-20 mx-auto text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      >
                        <option value='pdf'>PDF</option>
                        <option value='csv'>CSV</option>
                      </select>
                    </td>
                    <td className='py-3 px-6 border-r text-center'>
                      <button
                        title={t('DASHBOARD_AGENT_DOWNLOAD')}
                        onClick={() => handleDownloadReport(key)}
                        className='bg-gradblue mx-auto block hover:bg-DarkBlue cursor-pointer text-white font-medium active:bg-slate-100 text-sm py-2 px-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 '
                      >
                        {t('DASHBOARD_AGENT_DOWNLOAD')}
                      </button>
                    </td>
                  </tr>
                )
              )}
            {isEmpty(reports) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={8}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
