import React, { useState } from 'react'
import { apiPost } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import OInputField from 'components/reusable/OInputField'
import { useTranslation } from 'react-i18next'
import { validationRules } from '../../utils/constants'
import ErrorMessage from 'components/ErrorMessage'
import { preventMax } from 'utils/validations'

const AddInvoice = ({ setShowAddInvoiceModal, getAllInvoices }) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {}
  })

  const notification = useToastContext()
  const [addInvoiceLoading, setAddInvoiceLoading] = useState(false)

  const handleSubmitForm = async ({
    name,
    mobile,
    countryCode,

    amount,

    description
  }) => {
    try {
      setAddInvoiceLoading(true)

      const sendData = {
        name,
        mobile,
        countryCode,
        amount,
        description
      }
      const res = await apiPost(apiPath.merchantCreateInvoice, sendData)
      if (res.data.success === true) {
        setShowAddInvoiceModal(false)
        notification.success(res.data.message)
      } else {
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setAddInvoiceLoading(false)
      getAllInvoices()
    }
  }
  const preventMaxValueName = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 15) {
      e.target.value = e.target.value.slice(0, 15)
    }
  }
  const preventMaxValueDescription = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 200) {
      e.target.value = e.target.value.slice(0, 200)
    }
  }

  const handleAmount = e => {
    var value = e.target.value
    const pattern = /^\d{0,5}(\.\d{0,2})?$/
    if (e.target.value === '') {
      e.target.value = e.target.value.slice(e.target.value?.length - 1, -1)
    }
    const isValid = pattern.test(value)
    if (!isValid) {
      value = value.slice(0, -1)
    }
    setValue('amount', value)
  }
  var pressedKeys = []

  return (
    <div>
      <div
        id='add-offer'
        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
        aria-labelledby='drawer-label'
      >
        <div>
          <button
            onClick={() => setShowAddInvoiceModal(false)}
            title={t('O_CLOSE')}
            type='button'
            data-drawer-hide='drawer-example'
            aria-controls='drawer-example'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <img src='./images/close-circle.png' alt='close' />

            <span className='sr-only'>{t('CLOSE_MENU')}</span>
          </button>
        </div>
        <h3 className='font-medium text-[18px] mb-4'>
          {t('CREATE_NEW_INVOICE')}
        </h3>
        <form>
          <div className='relative my-5'>
            <OInputField
              type='text'
              name='name'
              inputLabel={
                <>
                  {t('INVOICE_CUSTOMER_NAME')}
                  <span className='text-red-500'>*</span>
                </>
              }
              autoFocus
              onInput={e => preventMaxValueName(e)}
              register={register('name', {
                required: t('PLEASE_ENTER_CUSTOMER_NAME'),
                minLength: {
                  value: 3,
                  message: t('MINIMUM_LENGTH_MUST_BE_3')
                },
                maxLength: {
                  value: 15,
                  message: t('MAXIMUM_LENGTH_MUST_BE_15')
                },
                validate: value => {
                  return !!value?.trim() ? true : t('WHITE_SPACE_NOT_ALLOWED')
                }
              })}
              errors={errors}
            />
          </div>
          <div className='flex my-5 gap-4'>
            <div class='relative w-full col-span-1'>
              <OInputField
                name='countryCode'
                inputLabel={<>{t('O_COUNTRY_CODE')}</>}
                errors={errors}
                type='select'
                register={register('countryCode', {
                  required: t('PLEASE_SELECT_COUNTRY_CODE')
                })}
                selectOptions={[
                  <option value='' key='select_code'>
                    {t('O_SELECT_CODE')}
                  </option>,
                  <option value='355' key='355'>
                    +355
                  </option>
                ]}
              />
            </div>

            <div className='relative w-full '>
              <OInputField
                name='mobile'
                inputLabel={
                  <>
                    {t('O_MOBILE_NUMBER')}
                    <span className='text-red-500'>*</span>
                  </>
                }
                errors={errors}
                type='number'
                onKeyDown={e => {
                  if (['-', '+', 'e', '.'].includes(e.key)) {
                    e.preventDefault()
                  }
                }}
                onInput={e => preventMax(e, 9)}
                register={register('mobile', {
                  required: t('PLEASE_ENTER_MOBILE_NUMBER'),
                  minLength: {
                    value: 9,
                    message: t('MINIMUM_LENGTH_SHOULD_BE_9_DIGITS')
                  },
                  maxLength: {
                    value: 9,
                    message: t('MAXIMUM_LENGTH_SHOULD_BE_9_DIGITS')
                  },
                  pattern: {
                    value: validationRules.numbers,
                    message: t('INVALID_MOBILE_NUMBER')
                  },
                  min: {
                    value: 0,
                    message: t('VALUE_CAN_NOT_BE_LESS_THAN_0')
                  }
                })}
              />
            </div>
          </div>

          <div className='relative  my-5'>
            <OInputField
              name='amount'
              inputLabel={
                <>
                  {t('AMOUNT')}
                  <span className='text-red-500'>*</span>
                </>
              }
              errors={errors}
              type='number'
              step='any'
              register={register('amount', {
                required: t('PLEASE_ENTER_AMOUNT'),
                onChange: e => handleAmount(e),
                validate: value => {
                  return value !== '0'
                    ? true
                    : t('VALUE_CAN_NOT_BE_LESS_THAN_0.01')
                }
              })}
              onKeyDown={e => {
                pressedKeys.push(e.key)
                var lastKey = pressedKeys[pressedKeys.length - 2]
                if (lastKey === '.') {
                  if (['-', '.', '+', 'e'].includes(e.key)) {
                    e.preventDefault()
                  }
                } else if (['-', '+', 'e'].includes(e.key)) {
                  e.preventDefault()
                }
              }}
            />
          </div>
          <div className='relative my-5'>
            <textarea
              name='description'
              rows='4'
              id='description'
              className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
              placeholder=' '
              maxLength={200}
              onInput={e => preventMaxValueDescription(e)}
              {...register('description', {
                required: t('PLEASE_ENTER_DESCRIPTION'),
                minLength: {
                  value: 10,
                  message: t('MINIMUM_LENGTH_MUST_BE_10')
                },
                maxLength: {
                  value: 200,
                  message: t('MAXIMUM_CHARACTER_CAN_BE_200')
                },
                validate: value => {
                  return !!value?.trim() ? true : t('WHITE_SPACE_NOT_ALLOWED')
                }
              })}
            />
            <label
              htmlFor='description'
              className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
            >
              {t('DESCRIPTION')}
              <span className='text-red-500'>*</span>
            </label>
            <ErrorMessage message={errors?.description?.message} />
          </div>

          <button
            class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
            type='submit'
            onClick={handleSubmit(handleSubmitForm)}
          >
            {addInvoiceLoading ? 'Loading...' : <>{t('O_SUBMIT')}</>}
          </button>
        </form>
      </div>
      <div class='bg-gray-900  bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 overlay'></div>
    </div>
  )
}

export default AddInvoice
