import React, { useEffect, useState } from 'react'
import SplitBillPaymentList from './SplitBillPaymentList'
import SplitBillDetails from './SplitBillDetails'
import SplitBillGroup from './SplitBillGroup'
import SplitBillParticipant from './SplitBillParticipant'
import SplitBillRequest from './SplitBillRequest'
import apiPath from '../../utils/pathObj'
import { apiGet } from '../../utils/apiFetch'
import Pagination from '../Pagination'
import { useTranslation } from 'react-i18next'

function SplitBillPayment () {
  const { t } = useTranslation()
  const [showSplitBillDetailModal, setShowSplitBillDetailModal] =
    useState(false)
  const [sendData, setSendData] = useState({})
  const [participantData, setParticipantData] = useState([])
  const [showGroupModal, setShowGroupModal] = useState(false)
  const [showParticipantModal, setShowParticipantModal] = useState(false)
  const [showSplitBillRequestModal, setShowSplitBillRequestModal] =
    useState(false)
  const [page, setPage] = useState(1)
  const [splitBills, setSplitBills] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })

  const size = 10
  // get all invoices -
  const getAllSplitBills = async payload => {
    let result
    try {
      if (payload?.deletePage) {
        payload = {
          page,
          size
        }
        if (splitBills?.length >= 1) {
          setPage(page - 1)
          setIsDelete(true)
          setPaginationObj({ ...paginationObj, page: page - 1 })
        }
      } else {
        payload = {
          page,
          limit: size
        }
        setIsDelete(false)
      }
      const path = apiPath.merchantSplitBillPaymentList
      result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        setSplitBills(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: response.page,
          pageCount: response.totalPages,
          perPageItem: response?.docs?.length,
          totalItems: response.totalDocs
        })
      }
    } catch (error) {
      console.log('error in get all offers list==>>>> result:', error)
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  useEffect(() => {
    getAllSplitBills()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <div>
      <div className='main_wrap'>
        <div className=''>
          <div className=''>
            <div className='px-3 md:py-4 md:py-2 pt-1 '>
              <div className='bg-white'>
                <form className='px-0 md:px-4 py-3'>
                  <div
                    className='col-span-2 flex flex-wrap items-center justify-between pb-3'
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <div className='flex items-center lg:pt-0 pt-3 flex-wrap justify-center'>
                      <div className='2xl:ml-auto xl:ml-0 lg:pt-0 md:mt-5'>
                        <label
                          for='default-search'
                          className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
                        >
                          {t('O_SEARCH')}
                        </label>
                        <div className='flex'>
                          <button
                            title={t('ADD_NEW_SPLIT')}
                            onClick={() => {
                              setShowGroupModal(true)
                              setSendData({})
                            }}
                            type='button'
                            className='w-full bg-LightBlue text-sm px-4 ml-3 py-3 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue'
                          >
                            {t('ADD_NEW_SPLIT')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <SplitBillPaymentList splitBills={splitBills} page={page} />
                <Pagination
                  handlePageClick={handlePageClick}
                  options={paginationObj}
                  isDelete={isDelete}
                  page={page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSplitBillDetailModal ? (
        <SplitBillDetails
          setShowSplitBillDetailModal={setShowSplitBillDetailModal}
          getAllSplitBills={getAllSplitBills}
        />
      ) : null}
      {showGroupModal ? (
        <SplitBillGroup
          setShowGroupModal={setShowGroupModal}
          setShowParticipantModal={setShowParticipantModal}
          setSendData={setSendData}
          getAllSplitBills={getAllSplitBills}
          sendData={sendData}
        />
      ) : null}
      {showParticipantModal ? (
        <SplitBillParticipant
          setShowGroupModal={setShowGroupModal}
          setShowParticipantModal={setShowParticipantModal}
          setShowSplitBillRequestModal={setShowSplitBillRequestModal}
          setSendData={setSendData}
          sendData={sendData}
          setParticipantData={setParticipantData}
          participantData={participantData}
          getAllSplitBills={getAllSplitBills}
        />
      ) : null}
      {showSplitBillRequestModal ? (
        <SplitBillRequest
          setShowSplitBillRequestModal={setShowSplitBillRequestModal}
          setShowParticipantModal={setShowParticipantModal}
          participantData={participantData}
          setParticipantData={setParticipantData}
          setSendData={setSendData}
          sendData={sendData}
          getAllSplitBills={getAllSplitBills}
        />
      ) : null}
    </div>
  )
}

export default SplitBillPayment
