import React, { useState, useEffect } from 'react'
import Table from './Table'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'

function Reports () {
  const [reports, setReports] = useState([])

  const getReports = async () => {
    try {
      var path = apiPath.getReports
      const result = await apiGet(path, {})
      if (result?.status === 200) {
        var response = result?.data?.results
        setReports(response)
      }
    } catch (error) {
      console.log('error in get all transactions list==>>>>', error.message)
    }
  }

  useEffect(() => {
    getReports()
  }, [])

  return (
    <div>
      <div className=''>
        <div className='p-4 md:px-7 md:py-4 py-2'>
          {/* filter section */}

          {/* <div class='common-heading md:py-5 py-2 pb-4 flex border-b mb-3 items-center'>
            <img src='images/reports-icon.svg' alt='' class='mr-4' />
            <h3 class='text-sm xl:flex block items-center mb-0 text-slate-900 font-medium md:text-base'>
              Reports{' '}
            </h3>
          </div> */}

          <Table reports={reports} getReports={getReports} />
        </div>
      </div>
    </div>
  )
}

export default Reports
