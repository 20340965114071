import OButton from 'components/reusable/OButton'
import React, { useContext, useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useToastContext from 'hooks/useToastContext'
import pathObj from 'utils/pathObj'
import Loader from 'layout/Loader'
import AuthContext from 'context/AuthContext'
import OtpInput from 'react18-input-otp'
import { apiPost } from 'utils/apiFetch'
import { startCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import ErrorMessage from 'components/ErrorMessage'
import { generateRandomID } from 'utils/reusableMethods'
import Header from 'components/Header'

function LoginOTP () {
  const { t } = useTranslation()
  const location = useLocation()
  const notification = useToastContext()
  const isTimer =
    Number(localStorage.getItem('seconds')) > 0
      ? Number(localStorage.getItem('seconds'))
      : 60
  const [seconds, setSeconds] = useState(isTimer)
  const [minutes, setMinutes] = useState('00')
  const [timerActive, setTimerActive] = useState(true)
  const { setLoginObj, setUser } = useContext(AuthContext)

  const navigate = useNavigate()
  const [otp, setOtp] = useState(null)
  const [otpError, setOtpError] = useState('')
  const [otpId, setOtpId] = useState('')

  const handleOTPChange = enteredOtp => {
    setOtp(enteredOtp)
  }

  const handleOTPSubmit = async () => {
    const otpErrors = validateOTP()

    if (otpErrors) {
      setOtpError(otpErrors)
      return
    }

    try {
      const sendData = buildSendData()
      const res = await apiPost(pathObj.verifyLoginOtp, sendData)
      handleApiResponse(res)
    } catch (err) {
      console.log('err:', err)
      notification.error(err?.response?.data?.message || 'An error occurred')
    }
  }

  const validateOTP = () => {
    if (!otp || otp.length < 4) {
      return 'Please enter a valid OTP.'
    }
    return ''
  }

  const buildSendData = () => {
    return {
      otp_phone: otp,
      otpID: otpId || location.state?.otpID,
      mobile: location?.state?.mobile,
      device_id: generateRandomID(10),
      device_type: 'web',
      device_token: generateRandomID(20)
    }
  }

  const handleApiResponse = res => {
    const result = res?.data?.results

    if (res.data.success) {
      if (!result?.is_otp_verified) {
        navigate('/otp', {
          state: {
            mobile: result?.mobile,
            country_code: result?.country_code
          }
        })
      } else if (!result?.is_profile_completed) {
        navigate('/complete-profile', { state: result })
      } else if (!result?.kyc_verified) {
        // Handle KYC verification
      } else {
        handleLoggedInUser(result, res)
      }
    } else {
      notification.error(res?.data?.message)
    }
  }

  const handleLoggedInUser = (result, res) => {
    const token = res?.data?.results?.token || null
    const refresh_token = res?.data?.results?.refresh_token || null
    localStorage.setItem('token', token)
    localStorage.setItem('refresh_token', refresh_token)
    localStorage.setItem(
      'notifications',
      res?.data?.results?.notifications || false
    )
    setUser(jwt_decode(token))
    setLoginObj(result)
    notification.success(res?.data.message)

    switch(res?.data?.results?.type) {
      case 'agent':
        navigate('/dashboard-agent')
        break;
      case 'operator':
        navigate('/dashboard-operator')
        break;
      case 'merchant':
        navigate('/dashboard')
        break;
      default:
        break;
    }
  }

  const handleResendOTP = async () => {
    try {
      if (seconds !== 0) {
        return
      }
      let sendData = {}
      sendData.input = location?.state?.input

      sendData.password = location?.state?.password
      sendData.device_id = generateRandomID(10)
      sendData.device_type = 'web'
      sendData.device_token = generateRandomID(20)
      const res = await apiPost(pathObj.loginUser, sendData)
      if (res.data.success === true) {
        setOtpId(res?.data?.results?.otpID)
        notification.success(res.data.message)
        setSeconds(60)
        setMinutes(0)
      } else {
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    }
  }

  let timerRef
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timerRef = setInterval(() => {
      if (minutes >= 0) {
        if (seconds === 0) {
          if (minutes > 0) {
            setSeconds(60)
            setMinutes(prev => prev - 1)
          } else {
            setSeconds(0)
          }
        } else {
          setSeconds(prev => prev - 1)
        }
      } else {
        clearInterval(timerRef)
        setTimerActive(false)
      }
    }, 1000)
    return () => clearInterval(timerRef)
  })
  localStorage.setItem('seconds', seconds)

  return (
    <div className='login-page h-full'>
      <Header />
      <Loader />
      <div className='min-h-[767px] sm:min-h-[834px] main_wrap md:pl-40 md:pr-40 pl-4 pr-4 pt-16 flex flex-col justify-between'>
        <div className='bg-white main_panel p-0 rounded-3xl overflow-hidden register'>
          <div className='g-0 grid-rows-1'>
            <div className='grid xl:grid-cols-2'>
              <div>
                <div className=' flex justify-center '>
                  <div className='w-[352px] h-80 flex items-center '>
                    <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded'>
                      <div className=' py-5 flex-auto'>
                        <section className='w-full'>
                          <div className='w-full flex justify-between'>
                            <div className='font-semibold'>
                              {t('O_VERIFICATION_CODE')}
                            </div>{' '}
                          </div>
                          <div className='font-semibold mt-4 '>
                            {t('O_ENTER_OTP')}
                          </div>
                          <OtpInput
                            inputStyle={{
                              border: 'solid 1px #dfdfdf',
                              width: '5rem',
                              height: '2.4rem',
                              marginRight: '.5rem',
                              outline: 'none',
                              borderRadius: '8px'
                            }}
                            shouldAutoFocus
                            containerStyle='text-center mt-2'
                            value={otp}
                            onChange={handleOTPChange}
                            isInputNum={true}
                            numInputs={4}
                            separator={<span> </span>}
                          />
                          <ErrorMessage message={otpError} />
                          <OButton
                            label={startCase('Verify')}
                            extraClasses='w-full mt-4'
                            type='button'
                            onClick={handleOTPSubmit}
                            disabled={!timerActive}
                          />

                          <div className='text-xs mt-8 text-center'>
                            {t('O_DID_NOT_RECEIVE_OTP')}{' '}
                            <span
                              className='text-violet-700 '
                              onClick={handleResendOTP}
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              <span
                                style={{
                                  color: seconds === 0 ? 'blue' : 'gray'
                                }}
                              >
                                {' '}
                                {t('O_RESEND_OTP')}{' '}
                              </span>
                              ( {t('O_EXPIRING_IN')} {minutes}:
                              {seconds < 10 ? '0' + seconds : seconds})
                            </span>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='md:flex justify-center items-center hidden bg-[#f8f9fa]'>
                <img src='./images/register_img.png' alt='' className='' />
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-between py-3 sm:py-4'>
          <p className='mb-0 text-white'>{t('O_ALPAY')} </p>
          <ul className='list-none m-0 text-white flex'>
            <li className=''>
              <Link to='/privacy-policy' className='hover:text-black'>
                {t('O_PRIVACY_POLICY')}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/terms-and-conditions' className='hover:text-black'>
                {t('O_TERMS_AND_CONDITION')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/about-us' className='hover:text-black'>
                {t('O_ABOUT_US')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/contact-us' className='hover:text-black'>
                {t('O_CONTACT_US')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/faqs' className='hover:text-black'>
                {t('O_FAQS')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default LoginOTP
