import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import OImage from './reusable/OImage'
import Dashboard from '../assets/images/sidebar_icon1.svg'
import Setting from '../assets/images/sidebar_icon9.svg'
import Logout from '../assets/images/sidebar_icon11.svg'
import Offer from '../assets/images/offers.png'
import Transaction from '../assets/images/transactions_white.png'
import Invoice from '../assets/images/invoice.png'
import SBP from '../assets/images/split.png'
import PaymentSettlement from '../assets/images/pay_statement.png'
import Order from '../assets/images/orders.png'
import manageSalary from '../assets/images/manage_salary.png'
import Reports from '../assets/images/reports.png'
import AboutUs from '../assets/images/about.png'
import QR from '../assets/images/qr-code_white.png'
import ManageStaff from '../assets/images/staff.png'
import { useTranslation } from 'react-i18next'
import helper from '../utils/helpers'

const classNames = require('classnames')
const Sidebar = () => {
  const { t } = useTranslation()
  const {
    logoutUser,
    user,
    sidebarStatus,
    handleSidebar,
    updatePageName,
    language
  } = useContext(AuthContext)

  const handleLogout = () => {
    logoutUser()
  }

  const checkSidebarPermission = arg => {
    return true
  }

  const generateNavLink = (to, text, iconSrc, pageName, permissionCheck) => {
    return (
      permissionCheck &&
      user?.role === 'merchant' && (
        <NavLink
          to={to}
          className={getClassName}
          onClick={() => updatePageName(pageName)}
        >
          <span className='mr-2'>
            <img src={iconSrc} className='max-w-[18px]' alt='' />
          </span>
          {text}
        </NavLink>
      )
    )
  }

  const getClassName = ({ isActive }) =>
    classNames(
      'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
      {
        active: isActive
      }
    )
  return (
    <>
      {' '}
      <div
        className={`sidebar lg:block z-10  ${
          sidebarStatus === 'open' ? 'block' : 'hidden'
        } bg-gradient-to-t from-gradpurple to-gradblue w-[220px] xl:w-[280px] fixed h-full overflow-y-auto`}
        onClick={handleSidebar}
      >
        <div className='text-white'>
          {(user?.permission?.[13]?.view || user?.role === 'merchant') && (
            <Link
              to='/dashboard'
              onClick={() => updatePageName('Dashboard')}
              className='px-2 py-6 w-full text-center flex justify-center'
            >
              <img src='images/whitelogo.svg' className='inline' alt='' />
            </Link>
          )}
          {(user?.permission?.[13]?.view || user?.role === 'agent') && (
            <Link
              to='/dashboard-agent'
              onClick={() => updatePageName('Dashboard')}
              className='px-2 py-6 w-full text-center flex justify-center'
            >
              <img src='images/whitelogo.svg' className='inline' alt='' />
            </Link>
          )}
          {(user?.permission?.[13]?.view || user?.role === 'operator') && (
            <Link
              to='/dashboard'
              onClick={() => updatePageName('Dashboard')}
              className='px-2 py-6 w-full text-center flex justify-center'
            >
              <img src='images/whitelogo.svg' className='inline' alt='' />
            </Link>
          )}

          {/* <div className='profile text-center'>
            <figure className='inline-block overflow-hidden rounded-full border-4 border-lightpurple mb-2.5'>
              <OImage
                src={user?.business_logo}
                fallbackUrl='/images/user.png'
                className='w-20 h-20'
              />
            </figure>
            <small className='block text-sm'>{t('WELCOME')}</small>
            <strong className='block text-lg overflow-hidden text-ellipsis px-2 whitespace-nowrap '>{`${user?.first_name} ${user?.last_name}`}</strong>
          </div> */}

          <nav className='pt-4 pb-5 flex flex-col justify-center font-normal text-xs overflow-y-auto'>
            {user?.permission?.[13]?.view ||
              (user?.role === 'operator' && (
                <NavLink
                  to='/dashboard-operator'
                  className={getClassName}
                  onClick={() => updatePageName(t('NAV_DASHBOARD'))}
                >
                  <span className='mr-2'>
                    <img src={Dashboard} className='max-w-[18px]' alt='' />
                  </span>
                  {t('NAV_DASHBOARD')}
                </NavLink>
              ))}
            {user?.permission?.[13]?.view ||
              (user?.role === 'merchant' && (
                <NavLink
                  to='/dashboard'
                  className={getClassName}
                  onClick={() => updatePageName(t('NAV_DASHBOARD'))}
                >
                  <span className='mr-2'>
                    <img src={Dashboard} className='max-w-[18px]' alt='' />
                  </span>
                  {t('NAV_DASHBOARD')}
                </NavLink>
              ))}
            {user?.permission?.[13]?.view ||
              (user?.role === 'agent' && (
                <NavLink
                  to='/dashboard-agent'
                  className={getClassName}
                  onClick={() => updatePageName(t('NAV_DASHBOARD'))}
                >
                  <span className='mr-2'>
                    <img src={Dashboard} className='max-w-[18px]' alt='' />
                  </span>
                  {t('NAV_DASHBOARD')}
                </NavLink>
              ))}

            {
              <NavLink
                to='/my-qrcode'
                className={getClassName}
                onClick={() => updatePageName(t('O_MY_QR_CODE'))}
              >
                <span className='mr-2'>
                  <img src={QR} className='max-w-[18px]' alt='' />
                </span>
                {t('O_MY_QR_CODE')}
              </NavLink>
            }
            {generateNavLink(
              '/offers',
              t('NAV_OFFERS'),
              Offer,
              t('NAV_OFFERS'),
              checkSidebarPermission('Offers')
            )}
            {generateNavLink(
              '/invoices',
              t('Invoices'),
              Invoice,
              t('Invoices'),
              checkSidebarPermission('Invoices')
            )}
            {generateNavLink(
              '/refund',
              t('DASHBOARD_REFUND'),
              Offer,
              t('DASHBOARD_REFUND'),
              checkSidebarPermission('refund')
            )}

            {generateNavLink(
              '/split-bill-payment',
              t('DASHBOARD_SPLIT_BILL_PAYMENT'),
              SBP,
              t('DASHBOARD_SPLIT_BILL_PAYMENT'),
              checkSidebarPermission('split-bill-payment')
            )}
            {generateNavLink(
              '/manageStaffs',
              t('NAV_MANAGE_STAFF'),
              ManageStaff,
              t('NAV_MANAGE_STAFF'),
              checkSidebarPermission('Manage Staff')
            )}
            {generateNavLink(
              '/payment-settlement',
              t('DASHBOARD_PAY_SETTLEMENT'),
              PaymentSettlement,
              t('DASHBOARD_PAY_SETTLEMENT'),
              checkSidebarPermission('DASHBOARD_PAY_SETTLEMENT')
            )}
            {generateNavLink(
              '/manage-orders',
              t('DASHBOARD_MANAGE_ORDERS'),
              Order,
              t('DASHBOARD_MANAGE_ORDERS'),
              checkSidebarPermission('Manage Orders')
            )}
            {generateNavLink(
              '/manage-salary',
              t('DASHBOARD_MANAGE_SALARY'),
              manageSalary,
              t('DASHBOARD_MANAGE_SALARY'),
              checkSidebarPermission('Manage Salary')
            )}
            {checkSidebarPermission('Transactions') && (
              <NavLink
                to='/my-transaction'
                className={getClassName}
                onClick={() => updatePageName(t('TRANSACTIONS'))}
              >
                <span className='mr-2'>
                  <img src={Transaction} className='max-w-[18px]' alt='' />
                </span>
                {t('TRANSACTIONS')}
              </NavLink>
            )}

            {checkSidebarPermission('Reports') && (
              <NavLink
                to='/reports'
                className={getClassName}
                onClick={() => updatePageName(t('NAV_REPORTS'))}
              >
                <span className='mr-2'>
                  <img src={Reports} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_REPORTS')}
              </NavLink>
            )}

            <NavLink
              to='/about-us'
              className={getClassName}
              onClick={() => updatePageName(t('NAV_ABOUT_US'))}
            >
              <span className='mr-2'>
                <img src={AboutUs} className='max-w-[18px]' alt='' />
              </span>

              {t('NAV_ABOUT_US')}
            </NavLink>
            {generateNavLink(
              '/change_pwd',
              t('NAV_SETTINGS'),
              Setting,
              t('NAV_SETTINGS'),
              checkSidebarPermission('Settings')
            )}
            <Link
              onClick={() =>
                helper.alertFunction(
                  `${t('ARE_YOU_SURE_YOU_WANT_TO_LOGOUT')}`,
                  '',
                  handleLogout,
                  '',
                  language
                )
              }
              className='flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue'
            >
              <span className='mr-2'>
                <img src={Logout} className='max-w-[18px]' alt='' />
              </span>
              {t('NAV_LOGOUT')}
            </Link>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Sidebar
