import React, { useState, useEffect } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import OInputField from 'components/reusable/OInputField'
import { useTranslation } from 'react-i18next'
import { validationRules } from '../../utils/constants'
import { preventMax } from 'utils/validations'
import OImage from 'components/reusable/OImage'
import { BiArrowBack } from 'react-icons/bi'

const SplitBillParticipant = ({
  setShowParticipantModal,
  sendData,
  setSendData,
  participantData,
  setParticipantData,
  setShowSplitBillRequestModal,
  setShowGroupModal
}) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const [addParticipantLoading, setAddParticipantLoading] = useState(false)
  const [participantId, setParticipantId] = useState(
    sendData?.participantId || []
  )
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {}
  })

  const handleAddParticipant = async ({ countryCode, mobile }) => {
    try {
      let result
      setAddParticipantLoading(true)
      const payloadData = {
        mobile,
        countryCode
      }
      const res = await apiGet(
        apiPath.merchantSplitBillPaymentCheckMobile,
        payloadData
      )
      if (res.data.success === true) {
        reset()
        result = res.data.results
        const isExist = participantData.filter(item => {
          return item._id.toString() === result._id.toString()
        })
        if (isExist.length > 0) {
          notification.error(t('CUSTOMER_ALREADY_ADDED'))
        } else {
          setParticipantData([...participantData, result])
          setParticipantId([...participantId, result?._id])
        }
      } else {
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setAddParticipantLoading(false)
    }
  }

  const handleNext = () => {
    if (participantData.length > 1) {
      setSendData({
        name: sendData.name,
        amount: sendData.amount,
        participantId
      })
      setShowParticipantModal(false)
      setShowSplitBillRequestModal(true)
    } else {
      notification.error(t('PLEASE_ADD_ATLEAST_2_PARTICIPANTS'))
    }
  }

  const handleDelete = id => {
    const participants = participantData.filter(item => {
      return item._id.toString() !== id.toString()
    })
    const participantIds = participantId.filter(item => {
      return item.toString() !== id.toString()
    })
    setParticipantData(participants)
    setParticipantId(participantIds)
  }

  useEffect(() => {
    if (
      Object.keys(participantData).length !== 0 &&
      participantData !== undefined
    ) {
      setValue('countryCode', participantData[0].country_code ?? '')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div
        id='add-offer'
        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
        aria-labelledby='drawer-label'
      >
        <div>
          <button
            onClick={() => {
              setShowParticipantModal(false)
              setParticipantData([])
            }}
            title={t('O_CLOSE')}
            type='button'
            data-drawer-hide='drawer-example'
            aria-controls='drawer-example'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <img src='./images/close-circle.png' alt='close' />

            <span className='sr-only'>{t('CLOSE_MENU')}</span>
          </button>
        </div>

        <div className='flex justify-between items-center mb-4'>
          <span
            title={t('O_BACK')}
            className='hover:text-blue-700 transition duration-150 ease-in-out'
            data-bs-toggle='tooltip'
          >
            {' '}
            <BiArrowBack
              className='h-8 w-8 border text-white py-1 rounded-md bg-[#6236FF] cursor-pointer mb-2'
              onClick={() => {
                setShowGroupModal(true)
                setShowParticipantModal(false)
              }}
            />
          </span>

          <span className='flex'>
            <button
              title='Next'
              className='text-right font-medium text-LightBlue'
              onClick={() => handleNext()}
            >
              {t('NEXT')}
            </button>
          </span>
        </div>
        <h3 className='font-medium text-[18px]'>{t('ADD_PARTICIPANTS')}</h3>

        <form>
          <div className='flex my-5 gap-4'>
            <div class='relative col-span-1 w-[90px] text-center'>
              <OInputField
                name='countryCode'
                // inputLabel={<>{t('O_COUNTRY_CODE')}</>}
                errors={errors}
                type='select'
                register={register('countryCode', {
                  required: t('PLEASE_SELECT_COUNTRY_CODE')
                })}
                selectOptions={[
                  <option value='355' key='355'>
                    +355
                  </option>
                ]}
              />
            </div>
            <div className='relative w-full '>
              <OInputField
                name='mobile'
                inputLabel={
                  <>
                    {t('O_MOBILE_NUMBER')}
                    <span className='text-red-500'>*</span>
                  </>
                }
                errors={errors}
                type='number'
                onInput={e => preventMax(e, 9)}
                onKeyDown={e => {
                  if (['-', '+', 'e', '.'].includes(e.key)) {
                    e.preventDefault()
                  }
                }}
                register={register('mobile', {
                  required: t('PLEASE_ENTER_MOBILE_NUMBER'),
                  minLength: {
                    value: 9,
                    message: t('MINIMUM_LENGTH_SHOULD_BE_9_DIGITS')
                  },
                  maxLength: {
                    value: 9,
                    message: t('MAXIMUM_LENGTH_SHOULD_BE_9_DIGITS')
                  },
                  pattern: {
                    value: validationRules.numbers,
                    message: t('INVALID_MOBILE_NUMBER')
                  },
                  min: {
                    value: 0,
                    message: t('VALUE_CAN_NOT_BE_LESS_THAN_0')
                  }
                })}
              />
            </div>
          </div>
          <button
            class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
            type='submit'
            onClick={handleSubmit(handleAddParticipant)}
          >
            {addParticipantLoading ? 'Loading...' : <>{t('O_ADD')}</>}
          </button>
        </form>
        <h4 className='my-3 font-medium'>{t('PARTICIPANTS_LIST')}</h4>
        <table className='w-full'>
          <tbody>
            {participantData?.map(item => (
              <div>
                <tr className='block pb-3 border-b mb-4'>
                  <div className='flex justify-between items-center'>
                    <div className='flex items-center'>
                      <div>
                        <OImage
                          src={item?.profile_pic ? item?.profile_pic : ''}
                          fallbackUrl='/images/default-product.jpg'
                          className='w-8 h-8 rounded-full mr-3'
                          alt='card'
                        />
                      </div>
                      <div className='content-start'>
                        +{item?.country_code}-{item?.mobile}
                      </div>
                    </div>

                    <div
                      className='items-end'
                      onClick={() => handleDelete(item._id)}
                    >
                      <span
                        title={t('O_DELETE')}
                        className='hover:text-blue-700 transition duration-150 ease-in-out'
                        data-bs-toggle='tooltip'
                      >
                        {' '}
                        <img
                          src='images/delete.png'
                          alt='No_Image'
                          className='w-[18px]'
                        />
                      </span>
                    </div>
                  </div>
                </tr>
              </div>
            ))}
          </tbody>
        </table>
      </div>
      <div class='bg-gray-900  bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 overlay' />
    </div>
  )
}

export default SplitBillParticipant
