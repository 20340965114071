import React, { useContext, useEffect, useState } from 'react'
import ErrorMessage from 'components/ErrorMessage'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useToastContext from 'hooks/useToastContext'
import pathObj from 'utils/pathObj'
import { useForm } from 'react-hook-form'
import Loader from 'layout/Loader'
import AuthContext from 'context/AuthContext'
import { validationRules } from 'utils/constants'
import OtpInput from 'react18-input-otp'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import OInputField from 'components/reusable/OInputField'
import { apiPost } from 'utils/apiFetch'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import Header from 'components/Header'

function OtpScreen () {
  const { t } = useTranslation()
  const location = useLocation()
  const notification = useToastContext()
  const {
    register,
    watch,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const [icon, setIcon] = useState(true)
  const [passwordIcon, setPasswordIcon] = useState(true)
  var isTimer =
    Number(localStorage.getItem('seconds')) > 0
      ? Number(localStorage.getItem('seconds'))
      : 60
  const [seconds, setSeconds] = useState(isTimer)
  const [minutes, setMinutes] = useState('00')
  // eslint-disable-next-line no-unused-vars
  const [timerActive, setTimerActive] = useState(true)
  const { setLoginObj } = useContext(AuthContext)
  const navigate = useNavigate()
  const [otp, setOtp] = useState(null)
  const [otpError, setOtpError] = useState('')

  const handleOTPChange = enteredOtp => {
    setOtp(enteredOtp)
  }

  const onSubmit = async data => {
    if (!otp) {
      setOtpError('Please enter OTP.')
      return
    } else if (otp.length < 4) {
      setOtpError('Please enter OTP.')
      return
    } else {
      setOtpError('')
    }
    let res
    try {
      let sendData = {}
      if (location?.state?.type === 'email') {
        sendData.type = 'email'
        sendData.email = location?.state?.email
      } else if (location?.state?.type === 'mobile') {
        sendData.type = 'mobile'
        sendData.mobile = location?.state?.mobile
        sendData.country_code = '355'
      }
      sendData.otp = otp
      sendData.password = data.password
      res = await apiPost(pathObj.resetPassword, sendData)
      const result = res?.data?.results
      if (res.data.success) {
        setLoginObj(result)

        notification.success(res.data.message)
        navigate('/login')
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
      notification.error(res?.data?.message)
    }
  }

  const handleResendOTP = async () => {
    try {
      if (minutes === 0 && seconds === 0) {
        let sendData = {}
        if (location?.state?.type === 'email') {
          sendData.type = 'email'
          sendData.email = location?.state?.email
        } else if (location?.state?.type === 'mobile') {
          sendData.type = 'mobile'
          sendData.mobile = location?.state?.mobile
          sendData.country_code = '355'
        }
        sendData.resend = true
        const res = await apiPost(pathObj.forgotPassword, sendData)
        if (res.data.success === true) {
          notification.success(res.data.message)
          setSeconds(60)
          setMinutes(0)
        } else {
          notification.error(res.data.message)
        }
      } else {
        notification.error(t('WAIT_FOR_OTP'))
      }
    } catch (err) {
      console.log('err:', err)
    }
  }

  let timerRef
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timerRef = setInterval(() => {
      if (minutes >= 0) {
        if (seconds === 0) {
          if (minutes > 0) {
            setSeconds(60)
            setMinutes(prev => prev - 1)
          } else {
            setSeconds(0)
          }
        } else {
          setSeconds(prev => prev - 1)
        }
      } else {
        clearInterval(timerRef)
        setTimerActive(false)
      }
    }, 1000)
    return () => clearInterval(timerRef)
  })
  localStorage.setItem('seconds', seconds)

  function changeIcon () {
    setIcon(!icon)
  }
  function confirmPasswordIcon () {
    setPasswordIcon(!passwordIcon)
  }
  const preventMaxPassword = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  const password = watch('password')
  useEffect(() => {
    if (!isEmpty(password)) {
      trigger('confirm_password')
    }
  }, [password, trigger])

  return (
    <div className='login-page h-full'>
      <Header />
      <Loader />
      <div className='min-h-[767px] sm:min-h-[834px] main_wrap md:pl-40 md:pr-40 pl-4 pr-4 pt-16 flex flex-col justify-between'>
        <div className='bg-white main_panel p-0 rounded-3xl overflow-hidden register h-[calc(100%-94px)]'>
          <div className='g-0 grid-rows-1'>
            <div className='grid xl:grid-cols-2'>
              <div>
                <form
                  className='md:max-w-lg m-auto px-3 py-5'
                  onSubmit={handleSubmit(onSubmit, () => {
                    if (!otp) {
                      setOtpError(t('PLEASE_ENTER_OTP'))
                      return
                    } else {
                      setOtpError('')
                    }
                  })}
                >
                  <div className='w-full flex justify-between'>
                    <div className='font-semibold my-3'>
                      {t('O_VERIFICATION_CODE')}
                    </div>{' '}
                  </div>
                  <div className='font-semibold mt-4 '>{t('O_ENTER_OTP')}</div>
                  <OtpInput
                    inputStyle={{
                      border: 'solid 1px #dfdfdf',
                      width: '5rem',
                      height: '2.4rem',
                      marginRight: '.5rem',
                      outline: 'none',
                      borderRadius: '8px'
                    }}
                    shouldAutoFocus
                    containerStyle='text-center mt-2'
                    value={otp}
                    onChange={handleOTPChange}
                    isInputNum={true}
                    numInputs={4}
                    separator={<span> </span>}
                  />
                  <ErrorMessage message={otpError} />
                  <div className='text-xs mt-5'>
                    {t('O_DID_NOT_RECEIVE_OTP')}{' '}
                    <span
                      className='text-violet-700'
                      onClick={handleResendOTP}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      <span style={{ color: seconds === 0 ? 'blue' : 'gray' }}>
                        {' '}
                        {t('O_RESEND_OTP')}{' '}
                      </span>
                      ( {t('O_EXPIRING_IN')} {minutes}:
                      {seconds < 10 ? '0' + seconds : seconds})
                    </span>
                  </div>
                  <div className='grid grid-cols-1 gap-4 md:gap-6 mt-5'>
                    <div className='relative'>
                      <OInputField
                        type={icon ? 'password' : 'text'}
                        name='password'
                        id='password'
                        inputLabel={
                          <>
                            {t('O_PASSWORD')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        className='dark:text-black block py-3 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        maxLength={15}
                        onInput={e => preventMaxPassword(e)}
                        register={register('password', {
                          required: t('PLEASE_ENTER_PASSWORD'),
                          pattern: {
                            value: validationRules.password,
                            message: validationRules.passwordMessage
                          }
                        })}
                      />
                      {icon ? (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changeIcon()}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span className='password_view absolute top-[18px] right-[20px]'>
                          <AiFillEye />
                        </span>
                      )}
                      <ErrorMessage message={errors?.password?.message} />
                    </div>
                  </div>
                  <div className='grid grid-cols-1 gap-4 md:gap-6 mt-5'>
                    <div className='relative'>
                      <OInputField
                        type={passwordIcon ? 'password' : 'text'}
                        name='confirm_password'
                        id='confirm_password'
                        inputLabel={
                          <>
                            {t('O_CONFIRM_PASSWORD')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        className='dark:text-black block py-3 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        maxLength={15}
                        onInput={e => preventMaxPassword(e)}
                        register={register('confirm_password', {
                          required: t('PLEASE_ENTER_CONFIRM_PASSWORD'),
                          pattern: {
                            value: validationRules.password,
                            message: validationRules.passwordMessage
                          },
                          validate: value => {
                            const { password } = getValues()
                            return (
                              password === value ||
                              t('PASSWORD_AND_CONFIRM_PASSWORD_DOES_NOT_MATCH')
                            )
                          }
                        })}
                      />
                      {passwordIcon ? (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => confirmPasswordIcon()}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => confirmPasswordIcon()}
                        >
                          <AiFillEye />
                        </span>
                      )}
                      <ErrorMessage
                        message={errors?.confirm_password?.message}
                      />
                    </div>
                  </div>
                  <button className='bg-[#7100fa] hover:bg-DarkBlue cursor-pointer w-full mt-3 text-white active:bg-slate-100 text-md px-8 py-4 rounded-lg font-normal shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'>
                    {t('O_SUBMIT')}
                  </button>
                  <div className='text-center mt-4 text-sm font-medium'>
                    {t('O_BACK_TO')}{' '}
                    <Link to='/login'>
                      {' '}
                      <span className='hover:underline text-[#6236FF] hover:text-[#9D36FF]'>
                        {' '}
                        {t('O_FORGOT_LOGIN')}{' '}
                      </span>{' '}
                    </Link>
                  </div>
                </form>
              </div>
              <div className='md:flex justify-center items-center hidden bg-[#f8f9fa]'>
                <img src='./images/register_img.png' alt='' className='' />
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-between py-3 sm:py-4'>
          <p className='mb-0 text-white'>{t('O_ALPAY')} </p>
          <ul className='list-none m-0 text-white flex'>
            <li className=''>
              <Link to='/privacy-policy' className='hover:text-black'>
                {t('O_PRIVACY_POLICY')}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/terms-and-conditions' className='hover:text-black'>
                {t('O_TERMS_AND_CONDITION')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/about-us' className='hover:text-black'>
                {t('O_ABOUT_US')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/contact-us' className='hover:text-black'>
                {t('O_CONTACT_US')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/faqs' className='hover:text-black'>
                {t('O_FAQS')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default OtpScreen
