import React, { useState } from 'react'
import { apiGet, apiPost } from 'utils/apiFetch'
import pathObj from 'utils/pathObj'
import Papa from 'papaparse'
import useToastContext from 'hooks/useToastContext'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import helpers from '../../utils/helpers'

const ManageSalary = () => {
  const [file, setFile] = useState([])
  const [upload, setUpload] = useState(true)
  const [data, setData] = useState([])
  const { t } = useTranslation()
  const notification = useToastContext()

  const handleDownloadSample = async () => {
    try {
      const res = await apiGet(pathObj.sampleSalary, {})
      if (res.data.success) {
        helpers.downloadFile(res?.data?.results?.filename)
      }
    } catch (error) {
      console.log('error:', error)
    }
  }
  const handleUpload = async e => {
    try {
      if (e?.target?.files[0]?.type === 'text/csv') {
        setFile(e.target.files[0])
        setUpload(false)
        Papa.parse(e.target.files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            setData(results.data)
          }
        })
      } else {
        notification.error('Please select a valid csv file.')
      }
    } catch (error) {
      console.log('error:', error)
    }
  }
  const handleReset = async e => {
    setFile([])
    setUpload(true)
    setData([])
  }

  const handleSubmit = async () => {
    try {
      const formData = new FormData()
      formData.append('salary_csv', file)
      const res = await apiPost(pathObj.salaryPayment, formData)
      if (res.data.success) {
        notification.success(res?.data?.message)
        setUpload(true)
        setFile([])
        setData([])
      } else {
        notification.error(res?.data?.message)
      }
    } catch (error) {
      console.log('error:', error)
    }
  }

  return (
    <div className='p-4 md:p-8'>
      {upload ? (
        <div className='upload-csv mt-40'>
          <div class='flex items-center justify-center w-full'>
            <label
              for='dropzone-file'
              class='flex flex-col items-center justify-center w-40 h-40 rounded-full border-gray-300 border-dashed cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
            >
              <div class='flex flex-col items-center justify-center pt-5 pb-6'>
                <img src='images/upload-csv.svg' alt='' />
              </div>
              <input id='dropzone-file' type='file' class='hidden' />
            </label>
          </div>

          <div
            class='flex items-center justify-center w-full mt-8'
            for='dropzone-file'
          >
            <label class='bg-gradblue px-5 py-3 flex flex-col items-center justify-center rounded-lg cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
              <div class='flex items-center justify-center '>
                <img
                  src='images/upload-small-icon.svg'
                  alt=''
                  className='w-5 h-5 mr-3 filter white'
                />
                <p class=' text-base font-bold text-white dark:text-gray-400'>
                  {t('MANAGE_SALARY_UPLOAD_CSV_FILE')}
                </p>
              </div>
              <input
                id='dropzone-file'
                className='hidden'
                accept='.csv'
                type='file'
                onChange={e => handleUpload(e)}
              />
            </label>
          </div>
          <div class='flex items-center justify-center w-full mt-8'>
            <label class='bg-gradblue px-5 py-3 flex flex-col items-center justify-center rounded-lg cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
              <div class='flex items-center justify-center'>
                <img
                  src='images/download.svg'
                  alt=''
                  className='w-5 h-5 mr-3 filter white'
                />
                <p class=' text-base font-bold text-white dark:text-gray-400'>
                  {t('MANAGE_SALARY_DOWNLOAD_CSV_FILE')}
                </p>
              </div>
              <input
                className='hidden'
                onClick={() => handleDownloadSample()}
              />
            </label>
          </div>
        </div>
      ) : (
        <div className='overflow-x-auto relative rounded-lg border'>
          <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
            <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='py-3 px-6'>
                  #
                </th>
                <th scope='col' className='py-3 px-6 text-left'>
                  {t('MANAGE_SALARY_NAME')}
                </th>
                <th scope='col' className='py-3 px-6 text-left'>
                  {t('O_MOBILE_NUMBER')} (+355)
                </th>
                <th scope='col' className='py-3 px-6 text-left'>
                  {t('MANAGE_SALARY_SALARY')}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => {
                return (
                  <tr
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    style={i % 2 ? { background: '#F9F9F9' } : {}}
                  >
                    <td className='py-3 px-6 border-r font-medium text-gray-900 dark:text-white'>
                      {i + 1}
                    </td>
                    <td className='py-3 px-6 border-r text-left'>
                      {item?.Name}
                    </td>
                    <td className='py-3 px-6 border-r text-right'>
                      {item?.Mobile}
                    </td>
                    <td className='py-3 px-6 border-r text-right'>
                      {item?.Salary}
                    </td>
                  </tr>
                )
              })}
              {isEmpty(data) && (
                <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                  <td className='py-4 px-6 border-r' colSpan={4}>
                    {t('O_NO_RECORD_FOUND')}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className='text-center mb-3'>
            <button
              onClick={() => handleSubmit()}
              label='Submit'
              style={{ width: '150px' }}
              type='submit'
              className='bg-gradblue hover:bg-DarkBlue cursor-pointer w-full mt-3 text-white active:bg-slate-100 text-md px-8 py-4 rounded-lg font-normal shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'
            >
              {t('O_SUBMIT')}
            </button>
            <button
              onClick={() => handleReset()}
              label='Submit'
              style={{ width: '150px' }}
              type='submit'
              className='bg-gradblue hover:bg-DarkBlue cursor-pointer w-full mt-3 text-white active:bg-slate-100 text-md px-8 py-4 rounded-lg font-normal shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ml-4'
            >
              {t('O_RESET')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ManageSalary
