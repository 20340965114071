import React, { useState } from 'react'
import { apiPut } from '../../utils/apiFetch'
import { useForm } from 'react-hook-form'
import ErrorMessage from '../../components/ErrorMessage'
import pathObj from '../../utils/pathObj'
import useToastContext from 'hooks/useToastContext'
import CustomButton from 'components/reusable/OButton'
import { validationRules } from 'utils/constants'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

const MerchantEdit = ({ setEditShowModal, getAllMerchants, item }) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      businessName: item?.business_name,
      businessAddress: item?.business_address,
      mobile: item?.mobile,
      countryCode: item?.country_code,
      email: item?.email
    }
  })
  const [icon, setIcon] = useState(true)
  const [passwordIcon, setPasswordIcon] = useState(true)
  const [editMerchantLoading, setEditMerchantLoading] = useState(false)

  const handleSubmitForm = async data => {
    try {
      const sendData = {
        business_name: data.businessName,
        business_address: data.businessAddress,
        mobile: data.mobile,
        country_code: data.countryCode,
        email: data.email,
        password: data.password
      }
      const res = await apiPut(pathObj.editMerchant + '/' + item._id, sendData)
      if (res.data.success === true) {
        setEditShowModal(false)
        notification.success(res.data.message)
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setEditMerchantLoading(false)
      getAllMerchants()
    }
  }
  const preventMax = e => {
    if (e.target.value.length > 9) {
      e.target.value = e.target.value.slice(0, 9)
    }
  }

  function changeIcon () {
    setIcon(!icon)
  }

  function changePasswordIcon () {
    setPasswordIcon(!passwordIcon)
  }

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto max-w-3xl'>
          {/* content */}
          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none min-w-[762px]'>
            {/* header */}
            <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
              <h3 className='text-xl font-semibold'>
                {t('MERCHANT_EDIT_MERCHANT')}
              </h3>
              <button
                className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                onClick={() => setEditShowModal(false)}
              >
                <span
                  className=' text-[#B8BBBF]  text-4xl '
                  title='Close button'
                >
                  ×
                </span>
              </button>
            </div>
            {/* body */}
            <form onSubmit={handleSubmit(handleSubmitForm)} method='post'>
              <div className='relative p-6 flex-auto'>
                <div className='grid grid-cols-2'>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <input
                        type='text'
                        name='businessName'
                        id='businessName'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        maxLength={40}
                        {...register('businessName', {
                          required: 'Please enter business name.',
                          minLength: {
                            value: 3,
                            message: 'Minimum length must be 3.'
                          }
                        })}
                      />
                      <label
                        for='businessName'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('MERCHANT_BUSINESS_NAME')}
                      </label>
                      <ErrorMessage message={errors?.businessName?.message} />
                    </div>
                  </div>

                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <select
                        type='select'
                        name='countryCode'
                        id='countryCode'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        {...register('countryCode', {
                          required: 'Please enter country code.'
                        })}
                      >
                        <option value=''>{t('O_SELECT_COUNTRY_CODE')}</option>
                        <option value='355'>355</option>
                      </select>
                      <label
                        for='countryCode'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_COUNTRY_CODE')}
                      </label>
                      <ErrorMessage message={errors?.countryCode?.message} />
                    </div>
                  </div>

                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <input
                        type='number'
                        id='mobile'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        onInput={e => preventMax(e)}
                        onKeyDown={e => {
                          if (['-', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        }}
                        {...register('mobile', {
                          required: 'Please enter valid mobile number.',
                          minLength: {
                            value: 9,
                            message: 'Minimum length should be 9 digits.'
                          },
                          pattern: {
                            value: validationRules.numbers,
                            message: 'Invalid mobile number.'
                          },
                          min: {
                            value: 0,
                            message: 'Minimum value must is 0.'
                          },
                          maxLength: {
                            value: 9,
                            message: 'Maximum length should be 9 digits.'
                          }
                        })}
                      />
                      <label
                        for='mobile'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_MOBILE_NUMBER')}
                      </label>
                      <ErrorMessage message={errors?.mobile?.message} />
                    </div>
                  </div>

                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        maxLength={40}
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
                        placeholder=' '
                        {...register('email', {
                          required: 'Please enter email.',
                          pattern: {
                            value: validationRules.email,
                            message:
                              'Invalid email address (Ex: example@domain.com).'
                          }
                        })}
                      />
                      <label
                        for='email'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_EMAIL_ID')}
                      </label>
                      <ErrorMessage message={errors?.email?.message} />
                    </div>
                  </div>

                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <input
                        type={icon ? 'password' : 'text'}
                        name='password'
                        id='password'
                        maxLength={30}
                        autoComplete='new-password'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        {...register('password', {
                          required: {
                            value: false,
                            message: 'Please enter password.'
                          },
                          pattern: {
                            value: validationRules.password,
                            message:
                              'Password must contain lowercase,uppercase characters, numbers, special character and at least 8 character long.'
                          }
                        })}
                      />
                      <label
                        for='password'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_PASSWORD')}
                      </label>
                      {icon ? (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changeIcon()}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changeIcon()}
                        >
                          <AiFillEye />
                        </span>
                      )}
                      <ErrorMessage message={errors?.password?.message} />
                    </div>
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <input
                        type={passwordIcon ? 'password' : 'text'}
                        name='confirm_password'
                        id='confirm_password'
                        maxLength={30}
                        autoComplete='new-password'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        {...register('confirm_password', {
                          required: {
                            value: false,
                            message: 'Please enter confirm password.'
                          },
                          validate: val => {
                            if (watch('password') !== val) {
                              return 'Your passwords do no match.'
                            }
                          }
                        })}
                      />
                      <label
                        for='confirm_password'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_CONFIRM_PASSWORD')}
                      </label>
                      {passwordIcon ? (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changePasswordIcon()}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changePasswordIcon()}
                        >
                          <AiFillEye />
                        </span>
                      )}
                      <ErrorMessage
                        message={errors?.confirm_password?.message}
                      />
                    </div>
                  </div>

                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <textarea
                        name='businessAddress'
                        rows='3'
                        id='businessAddress'
                        maxLength={100}
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        {...register('businessAddress', {
                          required: 'Please enter business address.',
                          minLength: {
                            value: 10,
                            message: 'Minimum length must be 10.'
                          }
                        })}
                      />
                      <label
                        for='businessAddress'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('MERCHANT_BUSINESS_ADDRESS')}
                      </label>
                      <ErrorMessage
                        message={errors?.businessAddress?.message}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* footer */}
              <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                <button
                  className='text-black bg-[#E1E1E1] cursor-pointer font-normal uppercase px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                  type='button'
                  onClick={() => setEditShowModal(false)}
                >
                  {t('O_BACK')}
                </button>
                <CustomButton
                  disabled={!isDirty}
                  label={<>{t('O_EDIT')}</>}
                  type='submit'
                  loading={editMerchantLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default MerchantEdit
