import React, { useState, useEffect } from 'react'
import { apiGet } from 'utils/apiFetch'
import Pagination from '../Pagination'
import apiPath from '../../utils/pathObj'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import InitiateRefund from './InitiateRefund'
import className from 'classnames'

const Refund = () => {
  const [refund, setRefund] = useState([])
  const [sort, setSort] = useState({})
  const [showRefundModal, setShowRefundModal] = useState(false)
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })

  const [offerFilterState, setOfferFilterState] = useState({
    page: '',
    sortKey: '',
    sortType: ''
  })

  const { sortKey } = offerFilterState
  const { sortType } = offerFilterState

  const getRefundList = async () => {
    let result
    try {
      let payload = {
        page,
        sortType,
        sortKey
      }
      const path = apiPath.refundList
      result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        setRefund(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: response.page,
          pageCount: response.totalPages,
          perPageItem: response?.docs.length,
          totalItems: response.totalDocs
        })
      }
    } catch (error) {
      console.log('error in get all staffs list==>>>> result:', error)
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handleSort = (sortKeys, sortTypes, state) => {
    setOfferFilterState({ sortKey: sortKeys, sortType: sortTypes })
    setSort({ [state]: true })
  }

  useEffect(() => {
    getRefundList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, offerFilterState])

  return (
    <div className='p-4 md:p-8'>
      <div className='flex justify-between mb-4 items-center'>
        <h3 className='xl:flex block items-center mb-0 text-slate-900 font-medium md:text-base xl:text-base'>
          &nbsp;{' '}
        </h3>
        <button
          title={t('INITIATE_NEW_REFUND')}
          onClick={() => setShowRefundModal(true)}
          className='text-base bg-gradblue hover:bg-DarkBlue cursor-pointer text-white font-normal active:bg-slate-100 text-sm py-3 px-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150 flex items-center'
        >
          {t('INITIATE_NEW_REFUND')}
        </button>
      </div>

      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('REFUND_TRANSACTION_ID')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_MOBILE_NUMBER')} (+355)
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('O_CREATED_DATE')}
                  <button className='ml-3'>
                    <img
                      name='createdAt'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort.createdAtDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'createdAtUpArrow')
                      }
                    />
                    <img
                      name='createdAt'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.createdAtUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'createdAtDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('AMOUNT')} ({t('O_LEK')})
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('STATUS')}
                  <button className='ml-3'>
                    <img
                      name='status'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort.statusDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'statusUpArrow')
                      }
                    />
                    <img
                      name='status'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.statusUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'statusDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {refund.map((item, i) => {
              return (
                <tr
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  style={i % 2 ? { background: '#F9F9F9' } : {}}
                >
                  <td className='py-3 px-6 border-r font-medium text-gray-900 dark:text-white'>
                    {i + 1 + 10 * (page - 1)}
                  </td>
                  <td className='py-3 px-6 border-r text-left'>
                    {item?.transactionId}
                  </td>
                  <td className='py-3 px-6 border-r text-right'>
                    {item?.mobile}
                  </td>
                  <td className='py-3 px-6 border-r text-right'>
                    {dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                  </td>
                  <td className='py-3 px-6 border-r text-right'>
                    {item?.amount}
                  </td>
                  <td className='py-3 px-6 border-r text-center'>
                    <span className='text-green text-[#1B9B07]'>
                      {item?.status === 'success' ? t('SUCCESS') : 'N/A'}
                    </span>
                  </td>
                </tr>
              )
            })}
            {isEmpty(refund) && (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={6}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          handlePageClick={handlePageClick}
          options={paginationObj}
          page={page}
        />
      </div>
      {showRefundModal ? (
        <InitiateRefund
          setShowRefundModal={setShowRefundModal}
          getRefundList={getRefundList}
        />
      ) : null}
    </div>
  )
}

export default Refund
