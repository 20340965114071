import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from 'utils/apiFetch'
import apiPath from 'utils/pathObj'
import { useTranslation } from 'react-i18next'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js'
import AuthContext from 'context/AuthContext'
import { Link } from 'react-router-dom'
import CashIn from './CashIn'
import CashOut from './CashOut'
import TransferToBank from './TransferToBank'
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
)

export const lineGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: false,
      text: ''
    }
  },
  scales: {
    y: {
      beginAtZero: true
    }
  }
}

function Home () {
  const { t } = useTranslation()
  const { logoutUser, updatePageName, setLastActivity, lastActivity } =
    useContext(AuthContext)
  const [dashboardDetails, setDashboardDetails] = useState({})
  const [walletDetails, setWalletDetails] = useState({})
  const currentYear = new Date().getFullYear()
  const [lineGraphData, setLineGraphData] = useState({
    labels: [],
    datasets: []
  })
  const [showCashInModal, setShowCashInModal] = useState(false)
  const [showCashOutModal, setShowCashOutModal] = useState(false)
  const [showTransferBankModal, setShowTransferBankModal] = useState(false)

  useEffect(() => {
    if (dashboardDetails?.graph) {
      const graphRecords = {
        labels: Object.keys(dashboardDetails.graph),
        datasets: [
          {
            label: 'Orders',
            data: Object.values(dashboardDetails.graph),
            fill: false
          }
        ]
      }
      setLineGraphData(graphRecords)
    }
  }, [dashboardDetails])

  const getDropList = () => {
    const year = new Date().getFullYear()
    return Array.from(new Array(10), (v, i) => (
      <option key={i} value={year - i}>
        {year - i}
      </option>
    ))
  }

  const getDashboardDetails = async (year = new Date().getFullYear()) => {
    try {
      const payload = { year }
      const path = apiPath.getDashboardDetails
      const result = await apiGet(path, payload)
      setDashboardDetails({ ...dashboardDetails, ...result.data.results })
    } catch (error) {
      console.log('error:', error)
      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }

  useEffect(() => {
    getDashboardDetails(currentYear)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear])

  const getCompleteProfile = async () => {
    try {
      const path = apiPath.getCompleteProfile
      const result = await apiGet(path, {})
      setWalletDetails({ ...walletDetails, ...result.data.results })
    } catch (error) {
      console.log('error:', error)
    }
  }

  useEffect(() => {
    getCompleteProfile()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = e => {
    getDashboardDetails(e.target.value)
  }

  useEffect(() => {
    const activityListener = () => {
      setLastActivity(new Date())
    }
    window.addEventListener('mousemove', activityListener)

    return () => {
      window.removeEventListener('mousemove', activityListener)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const inactivityTimeout = setTimeout(() => {
      const currentTime = new Date()
      const inactivityDuration = currentTime - lastActivity
      if (inactivityDuration >= 60000) {
        logoutUser()
      }
    }, 60000)
    return () => {
      clearTimeout(inactivityTimeout)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActivity])

  return (
    <>
      {/* <div className='common-heading md:px-8 px-3 py-5 pb-2 flex items-center'>
        <img src='images/dashboard-icon.svg' alt='' className='mr-4' />
        <h3 className='xl:flex block items-center mb-0 text-slate-900 font-medium md:text-base'>
          &nbsp;
          {t('NAV_DASHBOARD')}{' '}
        </h3>
      </div> */}

      <div className='account-summary-sec py-4 px-4 md:px-8 bg-slate-100'>
        <div class='grid grid-rows-1 grid-flow-col rounded-lg overflow-hidden'>
          <div class='col-span-12 sm:col-span-1'>
            <div className='bg-[#2600AF] text-white p-4 py-10 relative h-full'>
              <h6 className='text-sm'>{t('DASHBOARD_ACCOUNT_SUMMARY')}</h6>
              <div className='mt-7'>
                <span className='mb-1 block text-sm'>
                  {t('DASHBOARD_WALLET_BALANCE')}
                </span>
                <h2 className='text-2xl font-bold'>
                  {t('O_LEK')} {walletDetails?.wallet_amount?.toFixed(2)}{' '}
                </h2>
              </div>
              <img
                src='images/account-summary-back.png'
                alt='No_image'
                className='absolute top-0 left-0'
              />
            </div>
          </div>
          <div class='col-span-12 sm:col-span-7 p-5 py-7 bg-LightBlue flex items-center m-0'>
            <div class='grid grid-rows-2 md:grid-rows-1 grid-flow-col gap-4 w-full max-w-lg'>
              {/* <div class='col-span-6'>
                <Link>
                  <div className='bg-[#63C3EB] p-4 py-4 md:py-7 rounded-lg flex justify-between items-center'>
                    <h6 className='text-white font-medium'>{t('DASHBOARD_TRANSFER_MONEY')}</h6>
                    <img src='images/arrow-icon.png' alt='No_image' />
                  </div>
                </Link>
              </div> */}
              <div class='col-span-6'>
                <Link
                  to='/wallet-statement'
                  onClick={() => updatePageName('Wallet statement')}
                >
                  <div className='bg-[#63C3EB] p-4  py-4 md:py-7 rounded-lg flex justify-between items-center'>
                    <h6 className='text-white font-medium'>
                      {t('DASHBOARD_WALLET_STATEMENT')}
                    </h6>
                    <img src='images/arrow-icon.png' alt='No_image' />
                  </div>
                </Link>
              </div>
              <div class='col-span-6'>
                <Link to='' onClick={() => setShowTransferBankModal(true)}>
                  <div className='bg-[#63C3EB] p-4  py-4 md:py-7 rounded-lg flex justify-between items-center'>
                    <h6 className='text-white font-medium'>
                      {t('DASHBOARD_TRANSFER_BANK')}
                    </h6>
                    <img src='images/arrow-icon.png' alt='No_image' />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='py-4 px-4 md:px-8'>
        <div className='sale_report grid  3xl:grid-cols-4 gap-4 2xl:grid-cols-3 sm:grid-cols-2 mb-3 '>
          <div className='flex items-center text-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
            <h3 className='text-left xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
              {t('O_LEK')} {dashboardDetails?.totalSales}
              <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                {t('DASHBOARD_TOTAL_SALES')}
              </span>
            </h3>
            <img
              src='./images/sales.svg'
              className='text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0'
              alt=''
            />
          </div>

          <div className='flex items-center text-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
            <h3 className='text-left xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
              {dashboardDetails?.totalProducts}{' '}
              <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                {t('DASHBOARD_TOTAL_PRODUCTS')}
              </span>
            </h3>
            <img
              src='./images/product.svg'
              className='text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0'
              alt=''
            />
          </div>
          <div className='flex items-center text-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
            <h3 className='text-left xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
              {dashboardDetails?.totalOrders}
              <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                {t('DASHBOARD_TOTAL_ORDERS')}
              </span>
            </h3>
            <img
              src='./images/order.svg'
              className='text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0'
              alt=''
            />
          </div>
          {/* <div className='flex items-center text-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
            <h3 className='text-left xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
              {dashboardDetails?.totalOrders}{' '}
              <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                Total Orders
              </span>
            </h3>
            <img
              src='./images/order.svg'
              className='text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0'
              alt=''
            />
          </div> */}
        </div>
      </div>
      <div className='py-7 px-4 md:px-8 bg-[#F9F9F9]'>
        <div className='sale_report grid lg:grid-cols-2 md:grid-cols-1 gap-5 sm:mb-7 mb-0'>
          <div className='bg-white p-5 rounded-lg border'>
            <div className='flex justify-between'>
              <h4 className='font-medium text-lg'>{t('DASHBOARD_SALES')}</h4>
              <div className='flex'>
                <div className='flex items-center mb-3 ml-4 sm:mb-0'>
                  <select
                    onChange={onChange}
                    className='flex flex-row-reverse border outline-none border-[#E9EDF9] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  >
                    {getDropList()}
                  </select>
                </div>
              </div>
            </div>
            <Line options={lineGraphOptions} data={lineGraphData} />
          </div>
          <div className='grid lg:grid-cols-3 sm:grid-cols-2 gap-4 bg-white p-4 rounded-lg border'>
            <div className='text-center bg-[#F6F8FA] rounded-lg p-4 drop-shadow'>
              <Link to='' onClick={() => setShowCashInModal(true)}>
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d1.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>
                  {t('DASHBOARD_TOP_UP_CASH_IN')}
                </h4>
              </Link>
            </div>
            <div className='text-center bg-[#F6F8FA] rounded-lg p-4 drop-shadow'>
              <Link to='' onClick={() => setShowCashOutModal(true)}>
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d2.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>
                  {t('DASHBOARD_TOP_UP_CASH_OUT')}
                </h4>
              </Link>
            </div>

            <div className='text-center bg-[#F6F8FA] rounded-lg p-4 drop-shadow'>
              <Link to='/invoices' onClick={() => updatePageName('Invoices')}>
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d3.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>{t('Invoices')}</h4>
              </Link>
            </div>

            <div className='text-center bg-[#F6F8FA] rounded-lg p-4 drop-shadow'>
              <Link to='/refund' onClick={() => updatePageName('Refund')}>
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d4.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>
                  {t('DASHBOARD_REFUND')}
                </h4>
              </Link>
            </div>

            <div className='text-center bg-[#F6F8FA] rounded-lg p-4 drop-shadow'>
              <Link to='/split-bill-payment'>
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d5.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>
                  {t('DASHBOARD_SPLIT_BILL_PAYMENT')}
                </h4>
              </Link>
            </div>

            <div className='text-center bg-[#F6F8FA] rounded-lg p-4 drop-shadow'>
              <Link
                to='/payment-settlement'
                onClick={() => updatePageName(t('DASHBOARD_PAY_SETTLEMENT'))}
              >
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d6.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>
                  {t('DASHBOARD_PAY_SETTLEMENT')}
                </h4>
              </Link>
            </div>
            <div className='text-center bg-[#F6F8FA] rounded-lg p-4 drop-shadow'>
              <Link
                to='/manage-salary'
                onClick={() => updatePageName('Manage Salary')}
              >
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d7.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>
                  {t('DASHBOARD_MANAGE_SALARY')}
                </h4>
              </Link>
            </div>

            <div className='text-center bg-[#F6F8FA] rounded-lg p-4 drop-shadow'>
              <Link
                to='/manageStaffs'
                onClick={() => updatePageName('Manage staff')}
              >
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d8.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>
                  {t('DASHBOARD_MANAGE_STAFF')}
                </h4>
              </Link>
            </div>

            <div className='text-center bg-[#F6F8FA] rounded-lg p-4 drop-shadow'>
              <Link
                to='/manage-orders'
                onClick={() => updatePageName('Manage Orders')}
              >
                <figure className='mx-auto d-block mb-3 rounded-full w-14 h-14 overflow-hidden'>
                  <img
                    src='./images/d9.png'
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
                <h4 className='xl:text-base lg:text-sm'>
                  {t('DASHBOARD_MANAGE_ORDERS')}
                </h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {showCashInModal && <CashIn setShowCashInModal={setShowCashInModal} />}
      {showCashOutModal && (
        <CashOut setShowCashOutModal={setShowCashOutModal} />
      )}
      {showTransferBankModal && (
        <TransferToBank setShowTransferBankModal={setShowTransferBankModal} />
      )}
    </>
  )
}

export default Home
