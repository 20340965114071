import React, { Fragment, useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import pathObj from '../../utils/pathObj'
import { capitalize } from 'lodash'
import parse from 'html-react-parser'
import { Menu, Transition } from '@headlessui/react'
import { Link, useLocation } from 'react-router-dom'
import Loader from '../../layout/Loader'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'

const PrivatePolicy = () => {
  const { t, i18n } = useTranslation()
  const [slug, setSlug] = useState()
  const [slugContent, setSlugContent] = useState()
  const { language, setLanguage } = useContext(AuthContext)

  const location = useLocation().pathname.slice(
    1,
    useLocation().pathname.length
  )
  const changeLanguageHandler = eventKey => {
    if (eventKey === 2) {
      localStorage.setItem('language', 'al')
    } else {
      localStorage.setItem('language', 'en')
    }
    setLanguage(eventKey)
    if (eventKey === 1) {
      i18n.changeLanguage('en')
    } else if (eventKey === 2) {
      i18n.changeLanguage('al')
    }
  }
  const staticContentSlug = async () => {
    const path = pathObj.staticContent
    const pathContent = pathObj.staticContentSlug
    const result = await apiGet(path)
    // console.log("result", result)
    const resultContent = await apiGet(pathContent + '=' + location)
    // console.log("resultContent", resultContent)
    setSlugContent(resultContent?.data?.results?.content)
    result?.data?.results?.forEach(val => {
      if (val.slug === location) {
        // console.log("val", val)
        setSlug(val || 0)
      }
    })
  }

  useEffect(() => {
    staticContentSlug()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className='login-page h-full'>
        <header className='py-2 bg-white px-8 flex items-center'>
          <Link to='/'>
            <img src='./images/logo.png' alt='' />
          </Link>

          <div className='ml-auto flex items-center'>
            <div className='mr-4'>
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button>
                    <img
                      src={
                        language === 1
                          ? './images/usFlag.svg'
                          : './images/albaniaFlag.svg'
                      }
                      className='h-[26px] w-[26px] rounded-full'
                      alt=''
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <div className='px-1 py-1 '>
                      <Menu.Item className='py-2 flex items-center w-full hover:bg-gray-100'>
                        {({ active }) => (
                          <button
                            className='flex items-center'
                            onClick={() => changeLanguageHandler(1)}
                          >
                            <img
                              src='./images/usFlag.svg'
                              alt=''
                              className='mr-2 h-[26px] w-[26px] rounded-full'
                            />{' '}
                            En
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item className='py-2 flex items-center w-full hover:bg-gray-100'>
                        {({ active }) => (
                          <button
                            className='flex items-center'
                            onClick={() => changeLanguageHandler(2)}
                          >
                            <img
                              src='./images/albaniaFlag.svg'
                              alt=''
                              className='mr-2 rounded-full '
                            />{' '}
                            Al
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>

            <Link
              to=''
              className='bg-violet hover:bg-DarkBlue cursor-pointer text-white font-normal active:bg-slate-100 text-sm py-2 px-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150 flex items-center'
            >
              <img src='./images/google-play.svg' alt='' className='mr-2' />
              {t('O_DOWNLOAD_APP')}
            </Link>
          </div>
        </header>
        <Loader />
        {/* <div className='main_wrap md:pl-40 md:pr-40 pl-4 pr-4 pt-16 h-[calc(100vh-54px)]'> */}
        <div className='main_wrap md:pl-40 md:pr-40 pl-4 pr-4 pt-16 h-[calc(100vh-54px)]'>
          <div className='bg-white main_panel p-0 rounded-3xl overflow-hidden register h-[calc(100%-94px)]'>
            <div className='g-0 grid-rows-1'>
              <div className='grid'>
                <section className='bg-[#F9F9F9] max-h-[800px] overflow-y-auto'>
                  <h1 className='pl-10 pt-5 font-semibold'>
                    {capitalize(slug?.title)}
                  </h1>
                  <div className='p-10'>
                    {/* <h1>{capitalize(slug?.title)}</h1> */}
                    {slugContent ? parse(slugContent) : null}
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className='flex justify-between py-3 sm:py-4'>
            <p className='mb-0 text-white'>{t('O_ALPAY')} </p>
            <ul className='list-none m-0 text-white flex'>
              <li className='ml-4'>
                <a href={'/privacy-policy'} className='hover:text-black'>
                  {t('O_PRIVACY_POLICY')}
                </a>
              </li>
              <li className='ml-4'>
                <a href={'/terms-and-conditions'} className='hover:text-black'>
                  {t('O_TERMS_AND_CONDITION')}
                </a>
              </li>
              <li className='ml-4'>
                <a href={'/about-us'} className='hover:text-black'>
                  {t('O_ABOUT_US')}
                </a>
              </li>
              <li className='ml-4'>
                <a href={'/contact-us'} className='hover:text-black'>
                  {t('O_CONTACT_US')}
                </a>
              </li>
              <li className='ml-4'>
                <a href={'/faqs'} className='hover:text-black'>
                  {t('O_FAQS')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivatePolicy
