import React, { useContext } from 'react'
import Sidebar from '../components/Sidebar'
import TopNavBar from '../components/TopNavBar'
import Loader from './Loader'
import Toast from './Toast'
import AuthContext from 'context/AuthContext'

function MainLayout ({ children }) {
  const { sidebarStatus } = useContext(AuthContext)

  return (
    <div className='main_wrap'>
      <Loader />
      <Toast />
      <Sidebar />
      <div
        className={`right_panel ${
          sidebarStatus === 'open' ? 'ml-[280px]' : 'ml-0'
        } xl:ml-[280px]`}
      >
        <TopNavBar />
        {children}
      </div>
    </div>
  )
}

export default MainLayout
