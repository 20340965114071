import React, { useEffect, useState } from 'react'
import InvoiceList from './invoiceList'
import AddInvoice from './AddInvoice'
import apiPath from '../../utils/pathObj'
import { apiGet } from '../../utils/apiFetch'
import Pagination from '../Pagination'
import { useTranslation } from 'react-i18next'

function Invoice () {
  const { t } = useTranslation()
  const [showAddInvoiceModal, setShowAddInvoiceModal] = useState(false)
  const [page, setPage] = useState(1)
  const [invoices, setInvoices] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [offerFilterState, setOfferFilterState] = useState({
    page: '',
    sortKey: '',
    sortType: ''
  })

  const { sortKey } = offerFilterState
  const { sortType } = offerFilterState

  const size = 10
  // get all invoices -
  const getAllInvoices = async payload => {
    let result
    try {
      if (payload?.deletePage) {
        payload = {
          page,
          size,
          sortType,
          sortKey
        }
        if (invoices?.length >= 1) {
          setPage(page - 1)
          setIsDelete(true)
          setPaginationObj({ ...paginationObj, page: page - 1 })
        }
      } else {
        payload = {
          page,
          size,
          sortType,
          sortKey
        }
        setIsDelete(false)
      }
      const path = apiPath.merchantInvoices
      result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        setInvoices(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: response.page,
          pageCount: response.totalPages,
          perPageItem: response?.docs?.length,
          totalItems: response.totalDocs
        })
      }
    } catch (error) {
      console.log('error in get all offers list==>>>> result:', error)
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  useEffect(() => {
    getAllInvoices()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, offerFilterState])

  return (
    <div>
      <div className='main_wrap'>
        <div className=''>
          <div className=''>
            <div className='px-3 md:py-4 md:py-2 pt-1 '>
              <div className='bg-white'>
                <form className='px-0 md:px-4'>
                  <div className='col-span-2 flex flex-wrap items-center justify-between pb-3'>
                    <div class='common-heading  py-2 md:py-5 pb-2 flex items-center'>
                      <h3 class='xl:flex block items-center mb-0 text-slate-900 font-medium md:text-base'>
                        &nbsp;
                      </h3>
                    </div>
                    <div className='flex items-center lg:pt-0 pt-3 flex-wrap justify-center'>
                      <div className='2xl:ml-auto xl:ml-0 lg:pt-0 md:mt-5'>
                        <label
                          for='default-search'
                          className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
                        >
                          Search
                        </label>
                        <div className='flex'>
                          <button
                            title={t('CREATE_NEW_INVOICE')}
                            onClick={() => setShowAddInvoiceModal(true)}
                            type='button'
                            className='w-full bg-LightBlue text-sm px-4 ml-3 py-3 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue'
                          >
                            {t('CREATE_NEW_INVOICE')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <InvoiceList
                  invoices={invoices}
                  setOfferFilterState={setOfferFilterState}
                  page={page}
                />
                <Pagination
                  handlePageClick={handlePageClick}
                  options={paginationObj}
                  isDelete={isDelete}
                  page={page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddInvoiceModal ? (
        <AddInvoice
          setShowAddInvoiceModal={setShowAddInvoiceModal}
          getAllInvoices={getAllInvoices}
        />
      ) : null}
    </div>
  )
}

export default Invoice
