import React, { useContext, useState } from 'react'
import { apiPut, apiDelete } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import dayjs from 'dayjs'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import className from 'classnames'
import helper from '../../utils/helpers'
import { isEmpty } from 'lodash'
import AuthContext from 'context/AuthContext'

const OfferList = ({
  offers,
  getAllOffers,
  handelEdit,
  setOfferFilterState,
  page
}) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const [sort, setSort] = useState({})
  const { language } = useContext(AuthContext)

  const handelStatusChange = async item => {
    try {
      const payload = {
        status: item?.status === 'active' ? 'inactive' : 'active'
      }
      const path = `${apiPath.merchantOfferChangeStatus}/${item?._id}`
      const result = await apiPut(path, payload)
      if (result?.status === 200) {
        notification.success(result.data.message)
        getAllOffers()
      }
    } catch (error) {
      console.log('error in get all offers list==>>>>', error.message)
    }
  }

  const handleSort = (sortKey, sortType, state) => {
    setOfferFilterState({ sortKey, sortType })
    setSort({ [state]: true })
  }

  const handelDelete = async item => {
    try {
      const path = apiPath.deleteMerchantOffer + '/' + item?._id
      const result = await apiDelete(path)
      if (result?.status === 200) {
        notification.success(result.data.message)

        getAllOffers({ deletePage: 1 })
      }
    } catch (error) {
      console.log('error in get all offers list==>>>>', error.message)
    }
  }

  return (
    <div className='p-1 md:p-3 pt-0'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('OFFER_TITLE')}
                  <button className='ml-3'>
                    <img
                      name='offerTitle'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort.titleDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'titleUpArrow')
                      }
                    />
                    <img
                      name='offerTitle'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.titleUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'titleDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('ACTUAL_AMOUNT')} ({t('O_LEK')})
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('OFFER_AMOUNT')} ({t('O_LEK')})
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('O_CREATED_DATE')}
                  <button className='ml-3'>
                    <img
                      name='createdAt'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort.createdAtDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'createdAtUpArrow')
                      }
                    />
                    <img
                      name='createdAt'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.createdAtUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'createdAtDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 '>
                <div className='flex justify-start'>
                  {t('OFFER_STATUS')}
                  <button className='ml-3'>
                    <img
                      name='status'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort.statusDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'statusUpArrow')
                      }
                    />
                    <img
                      name='status'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.statusUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'statusDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('OFFER_ACTION')}
              </th>
            </tr>
          </thead>
          <tbody>
            {offers &&
              offers?.map(
                (
                  {
                    title,
                    offerPrice,
                    actualPrice,
                    status,
                    image,
                    createdAt,
                    expiryFrom,
                    expiryTo,
                    description,
                    _id
                  },
                  i
                ) => {
                  return (
                    <tr
                      key={i}
                      className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                      style={i % 2 ? { background: '#F9F9F9' } : {}}
                    >
                      <td className='py-3 px-6 border-r font-medium text-gray-900  dark:text-white'>
                        {i + 1 + 10 * (page - 1)}
                      </td>
                      <td className='py-3 px-6 border-r text-left'>
                        <div class='flex items-center space-x-4'>
                          <img
                            class='w-10 h-10'
                            src={image ? image : 'images/default-product.jpg'}
                            alt=''
                          />
                          <div>{title}</div>
                        </div>
                      </td>
                      <td className='py-3 px-6 border-r text-right'>
                        {actualPrice}{' '}
                      </td>
                      <td className='py-3 px-6 border-r text-right'>
                        {offerPrice}{' '}
                      </td>
                      {/* <td className='py-3 px-6 text-black text-base'>
                      {t('O_LEK')} {actualPrice}
                    </td>
                    <td className='py-3 px-6 text-black text-base'>
                      {t('O_LEK')} {offerPrice}
                    </td> */}
                      <td className='py-3 px-6 border-r text-right'>
                        {dayjs(createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                      </td>

                      <td className='py-3 px-6 border-r text-center'>
                        <label
                          class='relative inline-flex items-center cursor-pointer'
                          title={`${
                            status === 'active'
                              ? t('O_ACTIVE')
                              : t('O_INACTIVE')
                          }`}
                        >
                          <input
                            type='checkbox'
                            value=''
                            class='sr-only peer'
                            checked={status === 'active'}
                            onChange={e =>
                              helper.alertFunction(
                                `${t('ARE_YOU_SURE_YOU_WANT_TO')} ${
                                  e.target.checked ? 'active' : 'inactive'
                                } '${title}' ${t('OFFER')}?`,
                                { _id, status },
                                handelStatusChange,
                                '',
                                language
                              )
                            }
                          />
                          <div class="w-11 h-6 bg-[#A7A4B2] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
                        </label>
                      </td>

                      <td className='py-4 px-6 border-r text-center'>
                        <div className=''>
                          <button
                            className='mr-5'
                            onClick={() =>
                              handelEdit({
                                title,
                                description,
                                offerPrice,
                                image,
                                actualPrice,
                                status,
                                expiryFrom,
                                expiryTo,
                                createdAt,
                                _id
                              })
                            }
                          >
                            <span
                              title={t('OFFER_EDIT_OFFER')}
                              className='hover:text-blue-700 transition duration-150 ease-in-out'
                              data-bs-toggle='tooltip'
                            >
                              {' '}
                              <img src='images/edit.svg' alt='' />
                            </span>
                          </button>
                          <button
                            className='mr-5'
                            onClick={e =>
                              helper.alertFunction(
                                `${t(
                                  'ARE_YOU_SURE_YOU_WANT_TO_DELETE'
                                )} '${title}' ${t('OFFER')}? `,
                                { _id },
                                handelDelete,
                                true,
                                language
                              )
                            }
                          >
                            <span
                              title={t('DELETE_OFFER')}
                              className='hover:text-blue-700 transition duration-150 ease-in-out'
                              data-bs-toggle='tooltip'
                            >
                              {' '}
                              <img src='images/delete.svg' alt='' />
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                }
              )}
            {isEmpty(offers) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={8}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OfferList
