import ErrorMessage from '../../components/ErrorMessage'
import React, { useContext, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { apiGet, apiPut } from '../../utils/apiFetch'
import pathObj from '../../utils/pathObj'
import useToastContext from 'hooks/useToastContext'
import OButton from 'components/reusable/OButton'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'

const Settings = () => {
  const { logoutUser } = useContext(AuthContext)
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {}
  })
  const [settingChangeLoading, setSettingChangeLoading] = useState(false)
  const notification = useToastContext()

  const handleSubmitForm = async data => {
    try {
      data.conversion_rate = parseInt(data.conversion_rate)
      setSettingChangeLoading(true)
      const res = await apiPut(pathObj.updateSettings, data)
      if (res.data.success === true) {
        getSettings()
        notification.success(res?.data?.message)
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setSettingChangeLoading(false)
    }
  }

  const getSettings = async () => {
    try {
      const res = await apiGet(pathObj.getSettings)
      if (res) {
        reset(res?.data?.results)
      }
    } catch (error) {
      console.log('error:', error)

      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }

  useEffect(() => {
    getSettings()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const preventMax = e => {
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.slice(0, 10)
    }
  }
  const preventMaxValue = e => {
    if (e.target.value.length > 5) {
      e.target.value = e.target.value.slice(0, 5)
    }
  }
  const preventMaxHundred = e => {
    if (e.target.value > 100) {
      e.target.value = e.target.value.slice(0, 2)
    }
  }

  const handleKeyDown = event => {
    if (
      !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
      !/[0-9.]/.test(event.key)
    ) {
      event.preventDefault()
    }
  }

  return (
    <section className=''>
      <form onSubmit={handleSubmit(handleSubmitForm)} method='post'>
        <section className='sm:px-8 px-4 py-4 '>
          <div className='border xl:w-full rounded'>
            <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md '>
              <div className='font-semibold'>
                {t('SETTINGS_GENERAL_SETTINGS')}
              </div>
            </header>
            <div className='bg-white py-6  rounded-b-md'>
              <main className='justify-center flex flex-wrap xl:[&>*]:mr-14 sm:[&>*]:mr-7 2xl:[&>*]:mr-14  sm:px-0 px-4 xl:[&>*]:w-3/12 sm:[&>*]:w-2/5 '>
                <div className='mb-4  w-full'>
                  <label className='block text-gray-700 text-sm font-medium mb-2'>
                    {t('SETTINGS_MINIMUM_WITHDRAWAL_AMOUNT')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      id='minimum_withdrawal_request'
                      {...register('minimum_withdrawal_request', {
                        required: {
                          value: true,
                          message: 'Please enter minimum withdrawal request.'
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: 'Decimals not allowed.'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Max limit is 10 characters.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=' '
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage
                    message={errors?.minimum_withdrawal_request?.message}
                  />
                </div>

                <div className='mb-4  w-full'>
                  <label className='block text-gray-700 text-sm font-medium mb-2'>
                    {t('SETTINGS_REFERRAL_BONUS')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      id='referral_bonus'
                      onInput={e => preventMaxValue(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('referral_bonus', {
                        required: {
                          value: true,
                          message: 'Please enter referral bonus.'
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: 'Only Digits Are Allowed.'
                        },
                        maxLength: {
                          value: 5,
                          message: 'Max limit is 5 characters.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=' '
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage message={errors?.referral_bonus?.message} />
                </div>

                <div className='mb-4   w-full'>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_CASH_IN_COMMISSION')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('cash_in_commission', {
                        required: {
                          value: true,
                          message: 'Please enter cash in commission.'
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: 'Only Digits Are Allowed.'
                        },
                        maxLength: {
                          value: 100,
                          message: 'Max limit is 100.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      %
                    </aside>
                  </div>
                  <ErrorMessage message={errors?.cash_in_commission?.message} />
                </div>

                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_CASH_OUT_COMMISSION')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('cash_out_commission', {
                        required: {
                          value: true,
                          message: 'Please enter cash out commission.'
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: 'Only Digits Are Allowed.'
                        },
                        maxLength: {
                          value: 100,
                          message: 'Max limit is 100.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      %
                    </aside>
                  </div>
                  <ErrorMessage
                    message={errors?.cash_out_commission?.message}
                  />
                </div>
                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_SIGN_IN_BONUS')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMaxValue(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('signin_bonus', {
                        required: {
                          value: true,
                          message: 'Please enter sign in bonus.'
                        },
                        maxLength: {
                          value: 5,
                          message: 'Max limit is 5 characters.'
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: 'Only Digits Are Allowed.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage message={errors?.signin_bonus?.message} />
                </div>
                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_ADD_MONEY_LIMIT')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMaxValue(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('add_money_limit', {
                        required: {
                          value: true,
                          message: 'Please enter Add money limit.'
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: 'Only Digits Are Allowed.'
                        },
                        maxLength: {
                          value: 5,
                          message: 'Max limit is 5 characters.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage message={errors?.add_money_limit?.message} />
                </div>
                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_WITHDRAW_MONEY_TO_BANK_LIMIT')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('witdraw_money_to_bank_limit', {
                        required: {
                          value: true,
                          message: 'Please enter withdraw money to bank limit.'
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: 'Only Digits Are Allowed.'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Max limit is 10 characters.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage
                    message={errors?.witdraw_money_to_bank_limit?.message}
                  />
                </div>
                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_TRANSFER_MONEY_TO_BANK_LIMIT')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('transfer_money_to_bank_limit', {
                        required: {
                          value: true,
                          message: 'Please enter transfer money to bank limit.'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Max limit is 10 characters.'
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: 'Only Digits Are Allowed.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage
                    message={errors?.transfer_money_to_bank_limit?.message}
                  />
                </div>

                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_TRANSACTION_FEE')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('transaction_fee', {
                        required: {
                          value: true,
                          message: 'Please enter transaction fee.'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Max limit is 10 characters.'
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: 'Only Digits Are Allowed.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage message={errors?.transaction_fee?.message} />
                </div>

                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_FACILITY_FEE')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('facility_fee', {
                        required: {
                          value: true,
                          message: 'Please enter facility fee.'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Max limit is 10 characters.'
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: 'Only Digits Are Allowed.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage message={errors?.facility_fee?.message} />
                </div>

                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_WALLET_TO_UTILITY_OR_RECHARGE')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('wallet_to_utility_or_recharge', {
                        required: {
                          value: true,
                          message: 'Please enter wallet to utility or recharge.'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Max limit is 10 characters.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage
                    message={errors?.wallet_to_utility_or_recharge?.message}
                  />
                </div>

                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_BUYING_DEALS')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('buying_deals', {
                        required: {
                          value: true,
                          message: 'Please enter buying deals.'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Max limit is 10 characters.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage message={errors?.buying_deals?.message} />
                </div>

                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('O_NFC_PAYMENT')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('nfc_payment', {
                        required: {
                          value: true,
                          message: 'Please enter nfc payment.'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Max limit is 10 characters.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage message={errors?.nfc_payment?.message} />
                </div>

                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_NUMBER_OF_LOYALTY_POINTS')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      id='conversion_point'
                      onInput={e => preventMax(e)}
                      {...register('conversion_point', {
                        required: {
                          value: true,
                          message:
                            'Please enter conversion rate of loyalty point.'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Max limit is 10 characters.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      Points
                    </aside>
                  </div>
                  <ErrorMessage message={errors?.conversion_point?.message} />
                </div>

                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_CONVERSION_RATE_OF_LOYALTY_POINT')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('conversion_rate', {
                        required: {
                          value: true,
                          message:
                            'Please enter conversion rate of loyalty point.'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Max limit is 10 characters.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage message={errors?.conversion_rate?.message} />
                </div>

                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_THRESHOLD_VALUE_OF_REDEEM_POINT')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('threshold_value_to_redeem_point', {
                        required: {
                          value: true,
                          message:
                            'Please enter threshold value to redeem point.'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Max limit is 10 characters.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      {t('O_LEK')}
                    </aside>
                  </div>
                  <ErrorMessage
                    message={errors?.threshold_value_to_redeem_point?.message}
                  />
                </div>
                <div className='mb-4   w-full  '>
                  <label className='block text-gray-700 text-sm font-medium mb-2 '>
                    {t('SETTINGS_DEALS_COMMISSION')}
                  </label>
                  <div className='flex border rounded'>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      type='number'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={event => handleKeyDown(event)}
                      {...register('deals_commission', {
                        required: {
                          value: true,
                          message: 'Please enter deals commission.'
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: 'Only Digits Are Allowed.'
                        },
                        maxLength: {
                          value: 100,
                          message: 'Max limit is 100.'
                        },
                        min: {
                          value: 1,
                          message: 'Minimum value must is 1.'
                        }
                      })}
                      placeholder=''
                    />
                    <aside className='text-center flex flex-col justify-center p-2 bg-gray-300 text-gray-700'>
                      %
                    </aside>
                  </div>
                  <ErrorMessage message={errors?.deals_commission?.message} />
                </div>
                <div className='mb-4   w-full  ' />
              </main>
              <div className='mt-4 text-center'>
                <OButton
                  disabled={!isDirty}
                  label={<>{t('O_UPDATE')}</>}
                  type='submit'
                  loading={settingChangeLoading}
                />
              </div>
            </div>
          </div>
        </section>
      </form>
    </section>
  )
}

export default Settings
