import React from 'react'

const OButton = ({
  label,
  disabled = false,
  type,
  loading,
  onClick,
  extraClasses,
  style
}) => {
  return (
    <button
      className={`${
        disabled === false
          ? 'bg-LightBlue hover:bg-DarkBlue cursor-pointer '
          : 'bg-blue-300'
      }  text-white font-normal active:bg-slate-100 text-sm px-8 py-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150
      ${extraClasses} `}
      style={style}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {loading ? 'Loading...' : label}
    </button>
  )
}

export default OButton
