import ErrorMessage from 'components/ErrorMessage'
import React from 'react'
import PropTypes from 'prop-types'
import helpers from '../../utils/helpers'

const OInputField = props => {
  const {
    labelClassName,
    inputClassName,
    selectOptions,
    name,
    inputLabel,
    type,
    placeholder,
    maxLength = 50,
    register,
    errors,
    greyClass,
    ...rest
  } = props

  const generateClassName = () => {
    const defaultClass =
      'border font-normal block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 rounded-lg border-1 border-gray-300 appearance-none focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'

    if (greyClass) {
      return `${defaultClass} bg-zinc-200`
    } else if (inputClassName) {
      return inputClassName
    }

    return defaultClass
  }

  const generateInputClassName = () => {
    const baseClass =
      'absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-72 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-72 peer-focus:-translate-y-4 left-1'

    if (greyClass) {
      return `${baseClass} bg-transparent`
    } else if (labelClassName) {
      return labelClassName
    }

    return baseClass
  }
  const generateInputTextClassName = () => {
    const baseClass =
      'border font-normal block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'

    if (greyClass) {
      return `${baseClass} bg-zinc-200`
    } else if (inputClassName) {
      return inputClassName
    }

    return baseClass
  }

  const baseStyles =
    'border font-normal block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'

  const finalClassName = greyClass
    ? `${baseStyles} dark:text-black bg-zinc-200`
    : inputClassName
    ? inputClassName
    : baseStyles

  switch (type) {
    case 'hidden':
      return <input type={type} name={name} id={name} {...register} {...rest} />

    case 'select':
      return (
        <>
          <div className='relative'>
            <select
              id={name}
              className={generateClassName()}
              placeholder=' '
              {...register}
              {...rest}
            >
              {selectOptions?.map(item => item)}
            </select>

            <label htmlFor={name} className={generateInputClassName()}>
              {inputLabel}
            </label>
          </div>

          <ErrorMessage message={errors?.[name]?.message} />
        </>
      )

    case 'number':
      return (
        <>
          <div className='relative'>
            <input
              type={type}
              id={name}
              className={finalClassName}
              placeholder={helpers?.ternaryCondition(
                placeholder,
                placeholder,
                ' '
              )}
              {...register}
              {...rest}
            />
            <label
              htmlFor={name}
              className={helpers?.ternaryCondition(
                labelClassName,
                labelClassName,
                'absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-72 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-72 peer-focus:-translate-y-4 left-1 '
              )}
            >
              {inputLabel}
            </label>
          </div>

          <ErrorMessage message={errors?.[name]?.message} />
        </>
      )
    case 'text':
    case 'email':
      return (
        <>
          <div className='relative'>
            <input
              type='text'
              name={name}
              id={name}
              className={generateInputTextClassName()}
              placeholder={helpers?.ternaryCondition(
                placeholder,
                placeholder,
                ' '
              )}
              maxLength={helpers?.ternaryCondition(maxLength, maxLength, 40)}
              {...register}
              {...rest}
            />
            <label
              htmlFor={name}
              className={helpers?.ternaryCondition(
                labelClassName,
                labelClassName,
                'text-[13px] absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-72 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-72 peer-focus:-translate-y-4 left-1'
              )}
            >
              {inputLabel}
            </label>
          </div>

          <ErrorMessage message={errors?.[name]?.message} />
        </>
      )

    case 'password':
      return (
        <>
          <div className='relative'>
            <input
              autocomplete='new-password'
              type={type}
              name={name}
              id={name}
              className={helpers?.ternaryCondition(
                inputClassName,
                inputClassName,
                'dark:text-black border font-normal block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
              )}
              placeholder={helpers?.ternaryCondition(
                placeholder,
                placeholder,
                ' '
              )}
              maxLength={maxLength}
              {...register}
              {...rest}
            />
            <label
              htmlFor={name}
              className={helpers?.ternaryCondition(
                labelClassName,
                labelClassName,
                'dark:bg-white dark:text-black absolute text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-72 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-72 peer-focus:-translate-y-4 left-1 '
              )}
            >
              {inputLabel}
            </label>
          </div>

          <ErrorMessage message={errors?.[name]?.message} />
        </>
      )

    default:
      return <div>Please provide some input props</div>
  }
}

export default OInputField

OInputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  inputLabel: PropTypes.string.isRequired
}
