import React, { useState } from 'react'
import { apiPost } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import OInputField from 'components/reusable/OInputField'
import { useTranslation } from 'react-i18next'
import { validationRules } from '../../utils/constants'
import { preventMax } from '../../utils/validations'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import helpers from 'utils/helpers'

const AddStaff = ({ setShowStaffModal, getAllStaffs }) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const notification = useToastContext()
  const [icon, setIcon] = useState(true)
  const [addStaffLoading, setAddStaffLoading] = useState(false)

  const handleSubmitForm = async ({
    email,
    mobile,
    country_code,
    first_name,
    last_name,
    password,
    wallet_limit
  }) => {
    try {
      setAddStaffLoading(true)

      const sendData = {
        email,
        mobile,
        country_code,
        first_name,
        last_name,
        password,
        wallet_limit
      }
      const res = await apiPost(apiPath.addStaffs, sendData)
      if (res.data.success === true) {
        setShowStaffModal(false)
        notification.success(res.data.message)
      } else {
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setAddStaffLoading(false)
      getAllStaffs()
    }
  }

  function changeIcon () {
    setIcon(!icon)
  }

  const preventMaxValueName = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 15) {
      e.target.value = e.target.value.slice(0, 15)
    }
  }
  const preventMaxEmail = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  const handleAmount = e => {
    var value = e.target.value
    const pattern = /^\d{0,7}(\.\d{0,2})?$/
    if (e.target.value === '') {
      e.target.value = e.target.value.slice(e.target.value?.length - 1, -1)
    }
    const isValid = pattern.test(value)
    if (!isValid) {
      value = value.slice(0, -1)
    }
    setValue('wallet_limit', value)
  }
  var pressedKeys = []

  const codeValue = helpers?.ternaryCondition(
    watch('email'),
    watch('email'),
    ''
  )

  const handleKeyDown = e => {
    pressedKeys.push(e.key)
    var lastKey = pressedKeys[pressedKeys.length - 2]
    if (lastKey === '.') {
      if (['-', '.', '+', 'e'].includes(e.key)) {
        e.preventDefault()
      }
    } else if (['-', '+', 'e'].includes(e.key)) {
      e.preventDefault()
    }
  }

  return (
    <>
      <div
        id='add-agent'
        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
        aria-labelledby='drawer-label'
      >
        <div>
          <button
            onClick={() => setShowStaffModal(false)}
            title={t('O_CLOSE')}
            type='button'
            data-drawer-hide='drawer-example'
            aria-controls='drawer-example'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <img src='./images/close-circle.png' alt='close' />

            <span className='sr-only'>{t('CLOSE_MENU')}</span>
          </button>
        </div>
        <h3 className='font-medium text-[18px] mb-4 '>
          {t('STAFF_ADD_NEW_AGENT')}
        </h3>
        <form>
          <div className='relative my-5'>
            <OInputField
              type='text'
              name='first_name'
              autoFocus
              inputLabel={
                <>
                  {t('O_FIRST_NAME')}
                  <span className='text-red-500'>*</span>
                </>
              }
              onInput={e => preventMaxValueName(e)}
              register={register('first_name', {
                required: t('PLEASE_ENTER_FIRST_NAME'),
                minLength: {
                  value: 3,
                  message: t('MINIMUM_LENGTH_MUST_BE_3')
                },
                maxLength: {
                  value: 15,
                  message: t('MAXIMUM_LENGTH_MUST_BE_15')
                }
              })}
              errors={errors}
            />
          </div>
          <div className='relative my-5'>
            <OInputField
              type='text'
              name='last_name'
              inputLabel={
                <>
                  {t('O_LAST_NAME')}
                  <span className='text-red-500'>*</span>
                </>
              }
              onInput={e => preventMaxValueName(e)}
              register={register('last_name', {
                required: t('PLEASE_ENTER_LAST_NAME'),
                minLength: {
                  value: 3,
                  message: t('MINIMUM_LENGTH_MUST_BE_3')
                },
                maxLength: {
                  value: 15,
                  message: t('MAXIMUM_LENGTH_MUST_BE_15')
                }
              })}
              errors={errors}
            />
          </div>

          <div className='flex my-5 gap-4'>
            <div class='relative w-full col-span-1'>
              <OInputField
                greyClass
                name='country_code'
                inputLabel={<>{t('O_COUNTRY_CODE')}</>}
                errors={errors}
                type='select'
                register={register('country_code', {})}
                disabled
                selectOptions={[
                  <option value='355' key='355'>
                    +355
                  </option>
                ]}
              />
            </div>

            <div className='relative w-full '>
              <OInputField
                name='mobile'
                inputLabel={
                  <>
                    {t('O_MOBILE_NUMBER')}
                    <span className='text-red-500'>*</span>
                  </>
                }
                errors={errors}
                type='number'
                onKeyDown={e => {
                  if (['-', '+', 'e', '.'].includes(e.key)) {
                    e.preventDefault()
                  }
                }}
                onInput={e => preventMax(e, 9)}
                register={register('mobile', {
                  required: t('PLEASE_ENTER_MOBILE_NUMBER'),
                  minLength: {
                    value: 9,
                    message: t('MINIMUM_LENGTH_SHOULD_BE_9_DIGITS')
                  },
                  maxLength: {
                    value: 9,
                    message: t('MAXIMUM_LENGTH_SHOULD_BE_9_DIGITS')
                  },
                  pattern: {
                    value: validationRules.numbers,
                    message: t('INVALID_MOBILE_NUMBER')
                  },
                  min: {
                    value: 0,
                    message: t('VALUE_CAN_NOT_BE_LESS_THAN_0')
                  }
                })}
              />
            </div>
          </div>

          <div className='relative  my-5'>
            <OInputField
              name='email'
              inputLabel={
                <>
                  {t('O_CUSTOMER_EMAIL_ID')}
                  <span className='text-red-500'>*</span>
                </>
              }
              errors={errors}
              type='email'
              onInput={e => preventMaxEmail(e)}
              value={codeValue.toLowerCase()}
              register={register('email', {
                required: t('PLEASE_ENTER_AGENT_EMAIL_ID'),
                pattern: {
                  value: validationRules.email,
                  message: t('PLEASE_ENTER_VALID_AGENT_EMAIL_ID')
                }
              })}
            />
          </div>

          <div className='relative  my-5'>
            <OInputField
              type={helpers?.ternaryCondition(icon, 'password', 'text')}
              name='password'
              inputLabel={
                <>
                  {t('O_PASSWORD')}
                  <span className='text-red-500'>*</span>
                </>
              }
              errors={errors}
              maxLength={15}
              onInput={e => preventMaxEmail(e)}
              register={register('password', {
                required: t('PLEASE_ENTER_PASSWORD'),
                pattern: {
                  value: validationRules.password,
                  message: t('PASSWORD_MUST_CONTAIN_LOWERCASE_UPPERCASE_')
                }
              })}
            />
            {icon ? (
              <span
                className='password_view absolute top-[18px] right-[20px]'
                onClick={() => changeIcon()}
              >
                <AiFillEyeInvisible />
              </span>
            ) : (
              <span
                className='password_view absolute top-[18px] right-[20px]'
                onClick={() => changeIcon()}
              >
                <AiFillEye />
              </span>
            )}
          </div>

          <div className='relative  my-5'>
            <OInputField
              name='wallet_limit'
              inputLabel={
                <>
                  {t('O_WALLET LIMITATION_AMOUNT')}
                  <span className='text-red-500'>*</span>
                </>
              }
              errors={errors}
              type='number'
              step='any'
              register={register('wallet_limit', {
                required: t('PLEASE_ENTER_WALLET_LIMITATION_AMOUNT'),
                onChange: e => handleAmount(e),
                validate: value => {
                  return value !== '0'
                    ? true
                    : t('VALUE_CAN_NOT_BE_LESS_THAN_0.01')
                }
              })}
              onKeyDown={e => handleKeyDown(e)}
            />
          </div>

          <button
            class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
            type='submit'
            onClick={handleSubmit(handleSubmitForm)}
          >
            {helpers?.ternaryCondition(
              addStaffLoading,
              'Loading...',
              <>{t('O_ADD')}</>
            )}
          </button>
        </form>
      </div>

      <div class='bg-gray-900  bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 overlay' />
    </>
  )
}

export default AddStaff
