import React, { useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import pathObj from '../../utils/pathObj'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import Loader from '../../layout/Loader'
import { useTranslation } from 'react-i18next'
import Header from 'components/Header'

function FAQ () {
  const { t } = useTranslation()
  const [faqs, setFaqs] = useState([])

  const getFaqs = async () => {
    try {
      const res = await apiGet(pathObj.getFaqs)
      setFaqs(res?.data?.results)
    } catch (err) {
      console.log('err:', err)
    }
  }

  useEffect(() => {
    getFaqs()
  }, [])

  return (
    <div className='login-page'>
      <Header />
      <Loader />
      <div className='main_wrap md:pl-40 md:pr-40 pl-4 pr-4 pt-10 pb-10'>
        <h2 className='section-title text-white mb-5'>FAQ's</h2>
        <div className='w-full  bg-white p-8 rounded-3xl'>
          {faqs?.map((item, i) => {
            return (
              <div className='mx-auto w-full rounded-2xl bg-white p-2 mb-4 border '>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className='flex w-full justify-between rounded-lg bg-purple-100 px-4 py-3 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                        <span> {item?.title}</span>
                        <ChevronUpIcon
                          className={`${
                            open ? 'rotate-180 transform' : ''
                          } h-5 w-5 text-purple-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className='px-4 pt-4 pb-2 text-sm text-gray-500'>
                        {item?.content}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            )
          })}
        </div>
        <div className='flex justify-between py-3 sm:py-4'>
          <p className='mb-0 text-white'>{t('O_ALPAY')} </p>
          <ul className='list-none m-0 text-white flex'>
            <li className='ml-4'>
              <a href={'/privacy-policy'} className='hover:text-black'>
                {t('O_PRIVACY_POLICY')}
              </a>
            </li>
            <li className='ml-4'>
              <a href={'/terms-and-conditions'} className='hover:text-black'>
                {t('O_TERMS_AND_CONDITION')}
              </a>
            </li>
            <li className='ml-4'>
              <a href={'/about-us'} className='hover:text-black'>
                {t('O_ABOUT_US')}
              </a>
            </li>
            <li className='ml-4'>
              <a href={'/contact-us'} className='hover:text-black'>
                {t('O_CONTACT_US')}
              </a>
            </li>
            <li className='ml-4'>
              <a href={'/faqs'} className='hover:text-black'>
                {t('O_FAQS')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default FAQ
