import React, { useContext, useRef, useState, useEffect } from 'react'
import ErrorMessage from 'components/ErrorMessage'
import AuthContext from 'context/AuthContext'
import { useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'
import OInputField from 'components/reusable/OInputField'
import OImage from 'components/reusable/OImage'
import { validationRules } from 'utils/constants'
import { AiFillCamera } from 'react-icons/ai'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { preventMax } from '../../utils/validations'
import { BsCloudDownloadFill } from 'react-icons/bs'
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'

function ProfileNew () {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const [profilePicError, setProfilePicError] = useState('')
  const [updateProfileLoading, setUpdateProfileLoading] = useState(false)
  const [profilePic, setProfilePic] = useState(null)
  const [passportPic, setPassportPic] = useState(null)
  const [identityPic, setIdentityPic] = useState(null)
  const { updateProfile, user, language } = useContext(AuthContext)
  const profilePicRef = useRef()
  const [recordsTemp, setRecordTemp] = useState('')
  const [listTemp, setListTemp] = useState('')
  const [cat, setCat] = useState(user?.category)
  const [subCat, setSubCat] = useState(user?.sub_category)
  const [isCatError, setIsCatError] = useState(false)
  const [isSubCatError, setIsSubCatError] = useState(false)
  const [address, setAddress] = useState('')
  const [addressError, setAddressError] = useState('')
  const [coordinates, setCoordinates] = useState({
    lat: '',
    lng: ''
  })
  useEffect(() => {
    reset({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      country_code: user.country_code,
      mobile: user.mobile,
      business_name: user.business_name,
      business_address: address,
      license: user.license,
      business_logo: user.business_logo,
      description: user.description,
      identity_card: user.identity_card,
      passport: user.passport
    })
    setAddress(user?.business_address)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, language])

  const checkFields = () => {
    let isValid = true
    if (!cat && !subCat) {
      setIsCatError(true)
      setIsSubCatError(true)
      isValid = false
    }
    if (!cat) {
      setIsCatError(true)
      isValid = false
    } else {
      setIsCatError(false)
    }
    if (!subCat) {
      setIsSubCatError(true)
      isValid = false
    } else {
      setIsSubCatError(false)
    }
    if (address === '') {
      setAddressError(true)
      isValid = false
    } else {
      setAddressError(false)
    }
    return isValid
  }
  const handleSubmitForm = async data => {
    try {
      const isValid = checkFields()
      if (!isValid) return
      setUpdateProfileLoading(true)
      const formData = new FormData()
      if (dirtyFields.first_name) {
        formData.append('first_name', data.first_name)
      }
      if (dirtyFields.last_name) {
        formData.append('last_name', data.last_name)
      }
      if (dirtyFields.email) {
        formData.append('email', data.email)
      }
      if (dirtyFields.country_code) {
        formData.append('country_code', data.country_code)
      }
      if (dirtyFields.mobile) {
        formData.append('mobile', data.mobile)
      }
      if (dirtyFields.business_name) {
        formData.append('business_name', data.business_name)
      }
      if (address) {
        formData.append('business_address', address)
      }
      if (coordinates) {
        formData.append('latitude', coordinates?.lat)
        formData.append('longitude', coordinates?.lng)
      }
      if (dirtyFields.license) {
        formData.append('license', data.license)
      }
      if (dirtyFields.description) {
        formData.append('description', data.description)
      }
      if (cat) {
        formData.append('category', cat)
      }
      if (subCat) {
        formData.append('sub_category', subCat)
      }
      if (profilePic) {
        formData.append('business_logo', profilePic)
      }

      reset()

      updateProfile(formData)
    } catch (err) {
      console.log('err:', err)
    } finally {
      setUpdateProfileLoading(false)
    }
  }

  const getCategoryList = async () => {
    try {
      const res = await apiGet(apiPath.getCategoryList, {})

      setRecordTemp(res?.data?.results?.category_list)
      setListTemp(res?.data?.results?.sub_category_list)
    } catch (error) {
      console.log('error:', error)
    }
  }

  useEffect(() => {
    getCategoryList()
  }, [language])

  const validateFiles = profilePics => {
    let picError = ''
    const supportedTypes = ['image/jpeg', 'image/jpg', 'image/png']

    if (!isEmpty(supportedTypes.includes(profilePics.type))) {
      picError = 'Only jpeg, jpg and png are supported.'
    }

    if (picError) {
      setProfilePicError(picError)
      return false
    } else {
      setProfilePicError('')
      return true
    }
  }

  const handleFileChange = e => {
    const image = e?.target?.files[0]
    const isValidImage = validateFiles(image)
    if (isValidImage) {
      setProfilePic(image)
    } else {
      setProfilePic(null)
    }
  }

  const handlePassportChange = e => {
    const image = e?.target?.files[0]
    if (image) {
      setPassportPic(image)
    } else {
      setPassportPic(null)
    }
  }

  const handleIdentityChange = e => {
    const image = e?.target?.files[0]
    if (image) {
      setIdentityPic(image)
    } else {
      setIdentityPic(null)
    }
  }
  const downloadImage = () => {
    saveAs(
      identityPic ? URL.createObjectURL(identityPic) : user?.identity_card,
      'image.jpg'
    )
  }
  const downloadImagePassport = () => {
    saveAs(
      passportPic ? URL.createObjectURL(passportPic) : user?.passport,
      'images.jpg'
    )
  }
  const preventMaxValue = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 15) {
      e.target.value = e.target.value.slice(0, 15)
    }
  }
  const preventMaxValueName = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 50) {
      e.target.value = e.target.value.slice(0, 50)
    }
  }
  const preventMaxValueDescription = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 200) {
      e.target.value = e.target.value.slice(0, 200)
    }
  }
  const preventMaxEmail = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }

  const handleSelect = async value => {
    const result = await geocodeByAddress(value)
    const ll = await getLatLng(result[0])
    setAddress(value)
    setCoordinates(ll)
  }
  const preventMaxValueLicense = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 30) {
      e.target.value = e.target.value.slice(0, 30)
    }
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(handleSubmitForm, () => {
          const isValid = checkFields()
          if (!isValid) return
        })}
        method='post'
      >
        <div className='main_wrap'>
          <div className=''>
            <div className='bg-[#F9F9F9]'>
              <div className='px-6 py-4'>
                <div className='bg-white border border-[#E9EDF9] rounded-lg'>
                  <div className='p-3'>
                    <div className='grid xl:grid-cols-3 grid-cols-1'>
                      <div className='flex items-center w-full justify-center xl:border-r border-0 xl:mb-0 mb-4'>
                        <div className='relative text-center '>
                          <div className='w-32 h-30 rounded-full relative mx-auto'>
                            <input
                              type='file'
                              accept='image/png, image/jpg, image/jpeg'
                              name='image'
                              ref={profilePicRef}
                              onChange={handleFileChange}
                              className='hidden'
                            />
                            <OImage
                              src={
                                profilePic
                                  ? URL.createObjectURL(profilePic)
                                  : user?.business_logo
                              }
                              fallbackUrl='/images/user.png'
                              className='w-24 h-24'
                              alt=''
                              style={{ borderRadius: '50%' }}
                            />
                            <AiFillCamera
                              className=' bg-gray-100  absolute w-4 rounded-xl cursor-pointer'
                              style={{
                                width: '1.5rem',
                                height: '1.5rem',
                                bottom: '3px',
                                right: '31',
                                background: '',
                                padding: '1px'
                              }}
                              onClick={() => profilePicRef?.current?.click()}
                            />
                          </div>
                          <div className='text-center mt-4'>
                            <ErrorMessage message={profilePicError} />
                          </div>

                          <h4 className='font-medium text-[18px] mt-6'>
                            {user.first_name} {user.last_name}
                          </h4>
                          <button
                            class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium   active:bg-slate-100 text-sm px-8 py-4 xl:mt-10 mt-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined '
                            type='submit'
                            label='Submit'
                            loading={updateProfileLoading}
                          >
                            {t('PROFILE_SAVE_CHANGES')}
                          </button>
                        </div>
                      </div>
                      <div className='col-span-2'>
                        <div className='grid md:grid-cols-7 grid-cols-4 gap-3 md:p-4 p-2'>
                          <div class='relative w-full col-span-2 '>
                            <OInputField
                              type='text'
                              name='first_name'
                              id='first_name'
                              onInput={e => preventMaxValue(e)}
                              inputLabel={
                                <>
                                  {t('O_FIRST_NAME')}
                                  <span className='text-red-500'>*</span>
                                </>
                              }
                              register={register('first_name', {
                                required: 'Please enter first name.',
                                minLength: {
                                  value: 3,
                                  message: 'Minimum length must be 3.'
                                },
                                maxLength: {
                                  value: 15,
                                  message: 'Minimum length must be 15.'
                                }
                              })}
                              errors={errors}
                            />
                          </div>
                          <div class='relative w-full col-span-2 '>
                            <OInputField
                              type='text'
                              name='last_name'
                              id='last_name'
                              inputLabel={
                                <>
                                  {t('O_LAST_NAME')}
                                  <span className='text-red-500'>*</span>
                                </>
                              }
                              onInput={e => preventMaxValue(e)}
                              register={register('last_name', {
                                required: 'Please enter last name.',
                                minLength: {
                                  value: 3,
                                  message: 'Minimum length must be 3.'
                                },
                                maxLength: {
                                  value: 15,
                                  message: 'Minimum length must be 15.'
                                }
                              })}
                              errors={errors}
                            />
                          </div>
                          <div class='relative w-full col-span-1'>
                            <OInputField
                              greyClass
                              name='country_code'
                              inputLabel={<>{t('O_COUNTRY_CODE')}</>}
                              errors={errors}
                              type='select'
                              register={register('country_code', {})}
                              disabled
                              selectOptions={[
                                <option value='355' key='355'>
                                  +355
                                </option>
                              ]}
                            />
                          </div>
                          <div class='relative w-full md:col-span-2 col-span-3'>
                            <OInputField
                              greyClass
                              type='number'
                              name='mobile'
                              id='mobile'
                              disabled
                              inputLabel={<>{t('O_MOBILE_NUMBER')}</>}
                              onInput={e => preventMax(e, 9)}
                              register={register('mobile', {})}
                              errors={errors}
                            />
                          </div>
                        </div>
                        <div className='grid sm:grid-cols-2  gap-3 md:p-4 p-2'>
                          <div class='relative w-full'>
                            <OInputField
                              greyClass
                              name='email'
                              inputLabel={<>{t('O_EMAIL')}</>}
                              errors={errors}
                              type='email'
                              disabled
                              onInput={e => preventMaxEmail(e)}
                              register={register('email', {})}
                            />
                          </div>
                          <div class='relative w-full'>
                            <OInputField
                              type='text'
                              name='business_name'
                              id='business_name'
                              inputLabel={
                                <>
                                  {t('O_BUSINESS_NAME')}
                                  <span className='text-red-500'>*</span>
                                </>
                              }
                              onInput={e => preventMaxValueName(e)}
                              register={register('business_name', {
                                required: 'Please enter business name.',
                                minLength: {
                                  value: 3,
                                  message: 'Minimum length must be 3.'
                                },
                                maxLength: {
                                  value: 50,
                                  message: 'Minimum length must be 50.'
                                }
                              })}
                              errors={errors}
                            />
                          </div>
                        </div>
                        <div className='grid sm:grid-cols-2  gap-3 md:p-4 p-2'>
                          <PlacesAutocomplete
                            value={address}
                            onChange={e => {
                              setAddress(e)
                              if (e) {
                                setAddressError(false)
                              }
                            }}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading
                            }) => (
                              <div class='relative w-full '>
                                <OInputField
                                  {...getInputProps({
                                    type: 'text',
                                    name: 'business_address',
                                    errors: errors,
                                    id: 'business_address',
                                    inputLabel: (
                                      <>
                                        {t('O_BUSINESS_ADDRESS')}
                                        <span className='text-red-500'>*</span>
                                      </>
                                    ),
                                    onInput: e => preventMaxEmail(e)
                                  })}
                                />
                                {addressError && (
                                  <ErrorMessage message='Please select business address.' />
                                )}
                                <span className='absolute right-[10px] top-[10px] p-2 bg-white '>
                                  {' '}
                                  <img src='./images/location-outline.png' alt='locationOutline' />
                                </span>
                                <div className='autocomplete-dropdown-container'>
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item'

                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: '#fafafa',
                                          cursor: 'pointer'
                                        }
                                      : {
                                          backgroundColor: '#ffffff',
                                          cursor: 'pointer'
                                        }
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <div class='relative w-full '>
                            <OInputField
                              type='text'
                              name='license'
                              id='license'
                              inputLabel={
                                <>
                                  {t('O_LICENSE')}
                                  <span className='text-red-500'>*</span>
                                </>
                              }
                              onInput={e => preventMaxValueLicense(e)}
                              register={register('license', {
                                required:
                                  'Please enter shop act number/license.',
                                pattern: {
                                  value: validationRules.removeWhitespace,
                                  message:
                                    'Shop act number/license should be alphanumeric.'
                                },
                                minLength: {
                                  value: 3,
                                  message: 'Minimum length must be 3.'
                                }
                              })}
                              errors={errors}
                            />
                          </div>
                        </div>

                        <div className='grid sm:grid-cols-2 gap-3 md:p-4 p-2'>
                          <div class='relative w-full '>
                            <select
                              class='bg-white border border-gray-300 text-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 dark:focus:border-blue-500'
                              value={cat}
                              errors={errors}
                              onChange={e => {
                                setCat(e.target.value)
                                if (e.target.value === '') setSubCat('')
                                if (e.target.value) {
                                  setIsCatError(false)
                                }
                              }}
                            >
                              <option value={''}>
                                {t('O_PLEASE_SELECT_CATEGORY')}
                                <span className='text-red-500'>*</span>
                              </option>
                              {recordsTemp.length &&
                                recordsTemp?.map((i, key) => (
                                  <option key={key} value={i._id}>
                                    {i.name}
                                  </option>
                                ))}
                            </select>
                            {isCatError && (
                              <ErrorMessage message='Please select category.' />
                            )}
                          </div>
                          <div class='relative w-full '>
                            <select
                              class='bg-white border border-gray-300 text-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 dark:focus:border-blue-500'
                              value={subCat}
                              onChange={e => {
                                setSubCat(e.target.value)
                                if (e.target.value) {
                                  setIsSubCatError(false)
                                }
                              }}
                            >
                              <option value={''}>
                                {t('O_PLEASE_SELECT_SUBCATEGORY')}
                                <span className='text-red-500'>*</span>
                              </option>
                              {listTemp.length &&
                                listTemp?.map(
                                  (i, key) =>
                                    i.category_id === cat && (
                                      <option key={key} value={i._id}>
                                        {i.name}
                                      </option>
                                    )
                                )}
                            </select>
                            {isSubCatError && (
                              <ErrorMessage message='Please select subcategory.' />
                            )}
                          </div>
                        </div>
                        <div className='grid grid-cols-1 gap-3 md:p-4 p-2'>
                          <div class='relative w-full '>
                            <textarea
                              type='text'
                              name='description'
                              rows='3'
                              id='description'
                              class='border font-normal block px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 w-full focus:border-blue-600 peer'
                              maxLength={200}
                              onInput={e => preventMaxValueDescription(e)}
                              {...register('description', {
                                required: 'Please enter description.',
                                maxLength: {
                                  value: 200,
                                  message: 'Maximum character can be 200.'
                                },
                                minLength: {
                                  value: 10,
                                  message: 'Minimum character must be 10.'
                                }
                              })}
                            />
                            <label
                              htmlFor='description'
                              class='absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-72 top-2  origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-72 peer-focus:-translate-y-4 left-1 '
                            >
                              {t('O_DESCRIPTION')}
                              <span className='text-red-500'>*</span>
                            </label>
                            <ErrorMessage
                              message={errors?.description?.message}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bg-white border border-[#E9EDF9] rounded-lg mt-4'>
                  <div className='py-4 px-8 border-b'>
                    <h4 className='font-medium text-lg flex items-center '>
                      {t('O_KYC_DETAILS')}{' '}
                      {user?.kyc_verified === true ? (
                        <img src='images/verified.png' alt='' />
                      ) : (
                        <span className='ml-8' style={{ color: 'red' }}>
                          Not Verified
                        </span>
                      )}
                    </h4>
                  </div>
                  <div className='p-8 border-b flex gap-5 '>
                    <div>
                      <h5 className='text-sm text-[#A5A5A5] font-normal'>
                        {t('O_PASSPORT')}
                      </h5>
                      <div className='border p-3 sm:w-96 rounded-lg mt-3'>
                        <div className='flex items-center'>
                          <input
                            type='file'
                            accept='image/png, image/jpg, image/jpeg'
                            name='image'
                            onChange={handlePassportChange}
                            className='hidden'
                          />
                          <OImage
                            src={
                              passportPic
                                ? URL.createObjectURL(passportPic)
                                : user?.passport
                            }
                            fallbackUrl='/images/user.png'
                            className='w-24 h-24'
                            alt='passport'
                          />
                          <BsCloudDownloadFill
                            onClick={downloadImagePassport}
                            style={{
                              width: '1.5rem',
                              height: '1.5rem',
                              marginLeft: '15rem'
                            }}
                            title='Download'
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5 className='text-sm text-[#A5A5A5] font-normal'>
                        {t('O_IDENTITY_CARD')}
                      </h5>
                      <div className='border p-3 sm:w-96 rounded-lg mt-3'>
                        <div className='flex items-center'>
                          <input
                            type='file'
                            accept='image/png, image/jpg, image/jpeg'
                            name='image'
                            onChange={handleIdentityChange}
                            className='hidden'
                          />
                          <OImage
                            src={
                              identityPic
                                ? URL.createObjectURL(identityPic)
                                : user?.identity_card
                            }
                            fallbackUrl='/images/user.png'
                            className='w-24 h-24'
                            alt=''
                          />
                          <BsCloudDownloadFill
                            onClick={downloadImage}
                            style={{
                              width: '1.5rem',
                              height: '1.5rem',
                              marginLeft: '15rem'
                            }}
                            title='Download'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProfileNew
