import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import pathObj from '../../utils/pathObj'
import parse from 'html-react-parser'
import { useLocation } from 'react-router-dom'
import AuthContext from 'context/AuthContext'

const AboutUs = () => {
  // eslint-disable-next-line no-unused-vars
  const [slug, setSlug] = useState()
  const { language } = useContext(AuthContext)

  const [slugContent, setSlugContent] = useState()
  const location = useLocation().pathname.slice(
    1,
    useLocation().pathname.length
  )

  const staticContentSlug = async () => {
    const path = pathObj.staticContent
    const pathContent = pathObj.staticContentSlug
    const result = await apiGet(path)

    const resultContent = await apiGet(pathContent + '=' + location)

    setSlugContent(resultContent?.data?.results?.content)
    result?.data?.results?.forEach(val => {
      if (val.slug === location) {
        setSlug(val || 0)
      }
    })
  }

  useEffect(() => {
    staticContentSlug()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <>
      <div className='main_wrap md:pl-20 md:pr-20 pl-4 pr-4 pt-16'>
        <div className='bg-white main_panel p-0 rounded-3xl overflow-hidden register'>
          <div className='g-0 grid-rows-1'>
            <div className='grid'>
              <section className='bg-[#F9F9F9]'>
                <div className='p-10'>
                  {slugContent ? parse(slugContent) : null}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AboutUs
