import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import className from 'classnames'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import AuthContext from 'context/AuthContext'

const Albanian = require('flatpickr/dist/l10n/sq.js').default.sq
const ODateRangePicker = props => {
  const [isFirstRenderDone, setIsFirstRenderDone] = useState(0)

  const { language } = useContext(AuthContext)
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [addFlex, setAddFlex] = useState(true)

  useEffect(() => {
    setIsFirstRenderDone(1)
    if (!isEmpty(props.expiryFrom)) {
      setStartDate(dayjs(props.expiryFrom).format('DD-MM-YYYY'))
    }
    if (!isEmpty(props.expiryTo)) {
      setEndDate(dayjs(props.expiryTo).format('DD-MM-YYYY'))
    }
  }, [props.expiryFrom, props.expiryTo])

  const onChangeStartDate = ([date]) => {
    setStartDate(date)
    props?.handleDateChange(date, endDate)
  }
  const onChangeEndDate = ([date]) => {
    setEndDate(date)
    props.handleDateChange(startDate, date)
  }
  const handleReset = () => {
    setStartDate('')
    setEndDate('')
  }
  useEffect(() => {
    if (isFirstRenderDone === 1) {
      handleReset()
      if (props?.isReset) {
        props?.setIsReset(false)
      }
    }
    if (props?.addFlex === false) {
      setAddFlex(props?.addFlex)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.isReset])

  return (
    <>
      <div
        className={className('items-center mb-3 font-medium', {
          flex: addFlex
        })}
      >
        <label className='mx-2 text-[#000] text-xs font-bold'>
          {props.fromDate ? '' : t('O_FROM')}
        </label>
        <div className='relative'>
          <Flatpickr
            className='cursor-pointer bg-hero bg-[right_10px_top_12px] bg-[length:18px_18px] bg-no-repeat py-3 flex flex-row-reverse border border-[#E9EDF9] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
            name='start_date'
            placeholder={t('O_START_DATE')}
            value={startDate}
            options={{
              maxDate: endDate,
              dateFormat: 'd-m-Y',
              locale: language === 2 ? Albanian : undefined
            }}
            onChange={onChangeStartDate}
          />
        </div>
      </div>
      <div
        className={className('items-center mb-3 font-medium', {
          flex: addFlex
        })}
      >
        <label className='mx-2 text-[#000] text-xs font-bold'>
          {props.toDate ? '' : t('O_TO')}
        </label>
        <div className='relative'>
          <Flatpickr
            className='cursor-pointer bg-hero bg-[right_10px_top_12px] bg-[length:18px_18px] bg-no-repeat py-3 flex flex-row-reverse border border-[#E9EDF9] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
            name='end_date'
            placeholder={t('O_END_DATE')}
            value={endDate}
            options={{
              minDate: startDate,
              dateFormat: 'd-m-Y',
              locale: language === 2 ? Albanian : undefined
            }}
            onChange={onChangeEndDate}
          />
        </div>
      </div>
    </>
  )
}

export default ODateRangePicker
