import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import Loader from '../layout/Loader'
import RegisterForm from './auth/RegisterForm'
import LoginForm from './auth/LoginForm'
import { useTranslation } from 'react-i18next'
import Header from 'components/Header'

function Login () {
  const { t } = useTranslation()
  const location = useLocation()
  const [openTab, setOpenTab] = React.useState(
    location?.pathname?.split('/')[1] === 'login' ? 2 : 1
  )

  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  const [showClass, setShowClass] = useState(false)

  const handleShow = () => {
    setShowClass(!showClass)
  }

  const { reset } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {}
  })
  const [rememberMe] = useState(
    localStorage.getItem('rememberMe')
      ? localStorage.getItem('rememberMe')
      : false
  )

  useEffect(() => {
    if (rememberMe) {
      console.log('useEffect username:', localStorage.getItem('username'))
      console.log('useEffect password:', localStorage.getItem('password'))
      reset({
        username: localStorage.getItem('username'),
        password: localStorage.getItem('password')
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(user !== null) {
      switch(user.type){
        case 'agent':
          navigate('/dashboard-agent')
          break;
        case 'operator':
          navigate('/dashboard-operator')
          break;
        case 'merchant':
          navigate('/dashboard')
          break;
        default:
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <div className='login-page'>
      <Header />
      <Loader />
      {/* <TopNavBar /> */}
      <div className='min-h-[767px] sm:min-h-[834px] main_wrap md:pl-40 md:pr-40 pl-4 pr-4 pt-16 flex flex-col justify-between'>
        <div className='bg-white main_panel p-0 rounded-3xl overflow-hidden register'>
          <div className='g-0 grid-rows-1'>
            <div className='grid xl:grid-cols-2'>
              <div>
                <div className='flex flex-wrap'>
                  <div className='w-full'>
                    <ul
                      className='flex mb-0 list-none flex-wrap p-0  flex-row border-b border-fray-600'
                      role='tablist'
                    >
                      <li className='-mb-px mr-2 last:mr-0 flex-auto text-center'>
                        <a
                          className={
                            'text-md font-semibold  p-5  rounded block leading-normal ' +
                            (openTab === 1
                              ? 'text-[#6236FF] bg-[#f1eff8]'
                              : 'text-[#a5a5a5]')
                          }
                          onClick={e => {
                            e.preventDefault()
                            setOpenTab(1)
                            handleShow()
                          }}
                          data-toggle='tab'
                          href='#link1'
                          role='tablist'
                        >
                          {t('O_REGISTER')}
                        </a>
                      </li>
                      <li className='-mb-px mr-2 last:mr-0 flex-auto text-center'>
                        <a
                          className={
                            'text-md font-semibold p-5  rounded block leading-normal ' +
                            (openTab === 2
                              ? 'text-[#6236FF] bg-[#f1eff8]'
                              : 'text-[#a5a5a5]')
                          }
                          onClick={e => {
                            e.preventDefault()
                            setOpenTab(2)
                            handleShow()
                          }}
                          data-toggle='tab'
                          href='#link2'
                          role='tablist'
                        >
                          {t('O_LOGIN')}
                        </a>
                      </li>
                    </ul>
                    <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded'>
                      <div className=' py-5 flex-auto'>
                        <div className='tab-content tab-space '>
                          <div
                            className={openTab === 1 ? 'block' : 'hidden'}
                            id='link1'
                          >
                            <RegisterForm openTab={openTab} />
                          </div>
                          <div
                            className={openTab === 2 ? 'block' : 'hidden'}
                            id='link2'
                          >
                            <LoginForm openTab={openTab} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='md:flex justify-center items-center hidden bg-[#f8f9fa]'>
                <div>
                  <img src='./images/register_img.png' alt='' className='' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-between py-3 sm:py-4 flex-wrap'>
          <p className='mb-0 text-white'>{t('O_ALPAY')} </p>
          <ul className='list-none m-0 text-white flex flex-wrap'>
            <li className=''>
              <Link to='/privacy-policy' className='hover:text-black'>
                {t('O_PRIVACY_POLICY')}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/terms-and-conditions' className='hover:text-black'>
                {t('O_TERMS_AND_CONDITION')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/about-us' className='hover:text-black'>
                {t('O_ABOUT_US')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/contact-us' className='hover:text-black'>
                {t('O_CONTACT_US')}{' '}
              </Link>
            </li>
            <li className='ml-4'>
              <Link to='/faqs' className='hover:text-black'>
                {t('O_FAQS')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Login
