import { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'
function Header () {
  const { t, i18n } = useTranslation()
  const { language, setLanguage } = useContext(AuthContext)

  const changeLanguageHandler = eventKey => {
    setLanguage(eventKey)
    if (eventKey === 2) {
      localStorage.setItem('language', 'al')
    } else {
      localStorage.setItem('language', 'en')
    }
    if (eventKey === 1) {
      i18n.changeLanguage('en')
    } else if (eventKey === 2) {
      i18n.changeLanguage('al')
    }
  }
  return (
    <header className='py-2 bg-white px-8 flex items-center'>
      <Link to='/'>
        <img src='./images/logo.png' alt='' />
      </Link>

      <div className='ml-auto flex items-center'>
        <div className='mr-4'>
          <Menu as='div' className='relative flex items-center text-left'>
            <Menu.Button>
              <img
                src={
                  language === 1
                    ? './images/usFlag.svg'
                    : './images/albaniaFlag.svg'
                }
                className=' h-[26px] w-[26px] rounded-full'
                alt=''
              />
            </Menu.Button>
            <Menu.Button className='flex items-center block pl-3'>
              {language === 1 ? 'En' : 'Al'}
            </Menu.Button>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='z-50 absolute right-0 top-[40px] w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className='px-1 py-1 '>
                  <Menu.Item className='py-2 flex items-center w-full hover:bg-gray-100'>
                    {({ active }) => (
                      <button
                        className='flex items-center'
                        onClick={() => changeLanguageHandler(1)}
                      >
                        <img
                          src='./images/usFlag.svg'
                          alt=''
                          className='mr-2 h-[26px] w-[26px] rounded-full'
                        />{' '}
                        English
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item className='py-2 flex items-center w-full hover:bg-gray-100'>
                    {({ active }) => (
                      <button
                        className='flex items-center'
                        onClick={() => changeLanguageHandler(2)}
                      >
                        <img
                          src='./images/albaniaFlag.svg'
                          alt=''
                          className='mr-2 rounded-full'
                        />{' '}
                        Shqip
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>

        <Link
          to=''
          className='bg-violet hover:bg-DarkBlue cursor-pointer text-white font-normal active:bg-slate-100 text-sm py-2 px-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150 flex items-center'
        >
          <img src='./images/google-play.svg' alt='' className='mr-2' />
          {t('O_DOWNLOAD_APP')}
        </Link>
      </div>
    </header>
  )
}

export default Header
