import React, { useEffect, useState } from 'react'
import OfferList from './OfferList'
import EditOffer from './EditOffer'
import AddOffer from './AddOffer'
import apiPath from '../../utils/pathObj'
import { apiGet } from '../../utils/apiFetch'
import Pagination from '../Pagination'
import { useTranslation } from 'react-i18next'

function Offers () {
  const { t } = useTranslation()
  const [editShowModal, setEditShowModal] = useState(false)
  const [showAddOfferModal, setShowAddOfferModal] = useState(false)
  const [page, setPage] = useState(1)
  const [offers, setOffers] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  const [item, setItem] = useState('')
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [offerFilterState, setOfferFilterState] = useState({
    page: '',
    keyword: '',
    sortKey: '',
    sortType: ''
  })

  const { keyword } = offerFilterState
  const { sortKey } = offerFilterState
  const { sortType } = offerFilterState

  const size = 10
  // get all offers -
  const getAllOffers = async payload => {
    let result
    try {
      if (payload?.deletePage) {
        payload = {
          page,
          size,
          keyword,
          sortType,
          sortKey
        }
        if (offers?.length >= 1) {
          setPage(page - 1)
          setIsDelete(true)
          setPaginationObj({ ...paginationObj, page: page - 1 })
        }
      } else {
        payload = {
          page,
          size,
          keyword,
          sortType,
          sortKey
        }
        setIsDelete(false)
      }
      const path = apiPath.getAllMerchantOffers
      result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        setOffers(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: response.page,
          pageCount: response.totalPages,
          perPageItem: response?.docs?.length,
          totalItems: response.totalDocs
        })
      }
    } catch (error) {
      console.log('error in get all offers list==>>>> result:', error)
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handleChange = event => {
    setOfferFilterState({
      ...offerFilterState,
      [event.target.name]: event.target.value
    })
    setPage(1)
  }

  const handelEdit = items => {
    setItem(items)
    setEditShowModal(true)
  }

  useEffect(() => {
    getAllOffers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, keyword, offerFilterState])

  return (
    <div>
      <div className='main_wrap'>
        <div className=''>
          <div className=''>
            <div className='px-3 md:py-4 md:py-2 pt-1 '>
              <div className='bg-white'>
                <form className='px-0 md:px-4 py-3'>
                  <div className='col-span-2 flex flex-wrap items-center justify-between pb-3'>
                    <div class='common-heading  py-2 md:py-5 pb-2 flex items-center'>
                      <h3 class='xl:flex block items-center mb-0 text-slate-900 font-medium md:text-base'>
                        &nbsp;{' '}
                      </h3>
                    </div>
                    <div className='flex items-center lg:pt-0 pt-3 flex-wrap justify-center'>
                      <div className='2xl:ml-auto xl:ml-0 lg:pt-0 md:mt-5'>
                        <label
                          for='default-search'
                          className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
                        >
                          {t('O_SEARCH')}
                        </label>
                        <div className='flex'>
                          <div className='relative w-full'>
                            <div className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'>
                              {!keyword ? (
                                <svg
                                  aria-hidden='true'
                                  className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                                  fill='none'
                                  stroke='currentColor'
                                  viewBox='0 0 24 24'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                                  />
                                </svg>
                              ) : (
                                ''
                              )}
                            </div>

                            <input
                              type='search'
                              name='keyword'
                              id='default-search'
                              className='dark:text-black border block w-full p-2 py-3 text-sm text-gray-900 2xl:min-w-[400px] xl:min-w-[300px] rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 focus:ring-blue-500 dark:focus:border-blue-500'
                              placeholder={t('SEARCH_BY_VOUCHER_OFFER_TITLE')}
                              value={keyword}
                              onChange={handleChange}
                            />
                          </div>
                          <button
                            title={t('OFFERS_ADD_NEW_OFFER')}
                            onClick={() => setShowAddOfferModal(true)}
                            type='button'
                            className='w-full bg-LightBlue text-sm px-4 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue'
                          >
                            + {t('OFFERS_ADD_NEW_OFFER')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <OfferList
                  offers={offers}
                  getAllOffers={getAllOffers}
                  handelEdit={handelEdit}
                  page={page}
                  setOfferFilterState={setOfferFilterState}
                  offerFilterState={offerFilterState}
                />
                <Pagination
                  handlePageClick={handlePageClick}
                  options={paginationObj}
                  isDelete={isDelete}
                  page={page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddOfferModal ? (
        <AddOffer
          setShowAddOfferModal={setShowAddOfferModal}
          getAllOffers={getAllOffers}
        />
      ) : null}
      {editShowModal ? (
        <EditOffer
          setEditShowModal={setEditShowModal}
          getAllOffers={getAllOffers}
          item={item}
        />
      ) : null}
    </div>
  )
}

export default Offers
