import React, { useContext, useState } from 'react'
import { apiPost } from 'utils/apiFetch'
import apiPath from 'utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import OInputField from 'components/reusable/OInputField'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'

const CashAdd = ({ setShowCashAddModal }) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const notification = useToastContext()
  const [cashInLoading, setCashInLoading] = useState(false)
  const [cashInData, setCashInData] = useState(false)

  const { user } = useContext(AuthContext)
  const handleSubmitForm = async data => {
    try {
      setCashInLoading(true)

      const path = `${apiPath.AddBalance}`
      const res = await apiPost(path, { amount: data.amount })
      if (res.data.success === true) {
        setCashInData(true)
        notification.success(res.data.message)
      } else {
        setShowCashAddModal(false)
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setCashInLoading(false)
    }
  }


  const handleAmount = e => {
    var value = e.target.value
    const pattern = /^\d{0,5}(\.\d{0,2})?$/
    if (e.target.value === '') {
      e.target.value = e.target.value.slice(e.target.value?.length - 1, -1)
    }
    const isValid = pattern.test(value)
    if (!isValid) {
      value = value.slice(0, -1)
    }
    setValue('amount', value)
  }
  var pressedKeys = []

  return (
    <>
      <div
        id='add-agent'
        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
        aria-labelledby='drawer-label'
      >
        <div>
          <button
            onClick={() => setShowCashAddModal(false)}
            title='Close'
            type='button'
            data-drawer-hide='drawer-example'
            aria-controls='drawer-example'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <img src='./images/close-circle.png' alt='close' />

            <span className='sr-only'>{t('CLOSE_MENU')}</span>
          </button>
        </div>
        <h3 className='font-medium text-[18px] mb-4'>
          {t('DASHBOARD_TOP_UP_CASH_ADD')}
        </h3>
        <form>
          <div className='relative  my-5'>
            <OInputField
              name='amount'
              errors={errors}
              inputLabel={
                <>
                  {t('CASH_IN_COLLECTED_AMOUNT')}
                  <span className='text-red-500'>*</span>
                </>
              }
              type='number'
              register={register('amount', {
                required: t('PLEASE_ENTER_COOLECTED_AMOUNT'),
                onChange: e => handleAmount(e),
                validate: value => {
                  return value !== '0'
                    ? true
                    : t('VALUE_CAN_NOT_BE_LESS_THAN_0.01')
                }
              })}
              onKeyDown={e => {
                pressedKeys.push(e.key)
                var lastKey = pressedKeys[pressedKeys.length - 2]
                if (lastKey === '.') {
                  if (['-', '.', '+', 'e'].includes(e.key)) {
                    e.preventDefault()
                  }
                } else if (['-', '+', 'e'].includes(e.key)) {
                  e.preventDefault()
                }
              }}
            />
          </div>
          <button
            class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
            type='submit'
            onClick={handleSubmit(handleSubmitForm)}
          >
            {cashInLoading ? 'Loading...' : <>{t('DASHBOARD_TOP_UP_CASH_ADD')}</>}
          </button>
          {cashInData ? (
                    <div
                      id='add-agent'
                      className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
                      aria-labelledby='drawer-label'
                    >
                      <div>
                        <button
                          onClick={() => setShowCashAddModal(false)}
                          title='Close'
                          type='button'
                          data-drawer-hide='drawer-example'
                          aria-controls='drawer-example'
                          className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                        >
                          <img src='./images/close-circle.png' alt='close' />

                          <span className='sr-only'>{t('CLOSE_MENU')}</span>
                        </button>
                      </div>
                      <div className='flex items-center w-full justify-center mb-4'>
                        <div className='relative text-center '>
                          <div className='w-32 h-30 relative mx-auto'>
                            <img src='images/check.png' alt='No_image' />
                          </div>
                          <h3 className='font-medium text-[18px] mb-4'>
                            {t('CASH_BALANCE_ADDED')}{' '}
                          </h3>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
        </form>
      </div>

      <div class='bg-gray-900  bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 overlay' />
    </>
  )
}

export default CashAdd
