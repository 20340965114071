import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import className from 'classnames'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import ErrorMessage from 'components/ErrorMessage'
import AuthContext from 'context/AuthContext'
import helpers from 'utils/helpers'

const Albanian = require('flatpickr/dist/l10n/sq.js').default.sq
const FormDateRangePicker = props => {
  const [isFirstRenderDone, setIsFirstRenderDone] = useState(0)
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [addFlex, setAddFlex] = useState(true)
  const { language } = useContext(AuthContext)

  useEffect(() => {
    setIsFirstRenderDone(1)
    if (!isEmpty(props.expiryFrom)) {
      setStartDate(dayjs(props.expiryFrom).toDate())
    }
    if (!isEmpty(props.expiryTo)) {
      setEndDate(dayjs(props.expiryTo).toDate())
    }
  }, [props.expiryFrom, props.expiryTo])

  const onChangeStartDate = ([date]) => {
    setStartDate(date)
    props?.handleDateChange(date, endDate)
  }
  const onChangeEndDate = ([date]) => {
    setEndDate(date)
    props.handleDateChange(startDate, date)
  }
  const handleReset = () => {
    setStartDate('')
    setEndDate('')
  }
  useEffect(() => {
    if (isFirstRenderDone === 1) {
      handleReset()
      if (props?.isReset) {
        props?.setIsReset(false)
      }
    }
    if (props?.addFlex === false) {
      setAddFlex(props?.addFlex)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.isReset])

  return (
    <>
      <div
        className={className('items-center mb-3 font-small', {
          flex: addFlex
        })}
      >
        <label className='mx-2 text-[#000] text-xs'>
          {props.expiryDateFrom ? (
            <>
              <span className='text-gray-400'>
                {t('EXPIRY_DATE_FROM')}
                <span className='text-red-500'>*</span>
              </span>
            </>
          ) : (
            t('O_FROM')
          )}
        </label>
        <div className='relative'>
          <Flatpickr
            className='cursor-pointer bg-hero bg-[right_10px_top_10px] bg-[length:18px_18px] bg-no-repeat flex flex-row-reverse border border-[#E9EDF9] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2 focus:ring-blue-500 focus:border-blue-500'
            name='start_date'
            placeholder={t('O_START_DATE')}
            value={startDate}
            options={{
              maxDate: endDate,
              dateFormat: 'd-m-Y',
              minDate: helpers.ternaryCondition(
                props?.isEditForm,
                props?.isEditForm,
                'today'
              ),
              locale: helpers.ternaryCondition(
                language === 2,
                Albanian,
                undefined
              )
            }}
            onChange={onChangeStartDate}
          />
          <ErrorMessage message={props.errors?.startDate} />
        </div>
      </div>
      <div
        className={className('items-center mb-3 font-medium', {
          flex: addFlex
        })}
      >
        <label className='mx-1 text-[#000] text-xs'>
          {props.expiryDateTo ? (
            <span className='text-gray-400'>
              {t('EXPIRY_DATE_TO')}
              <span className='text-red-500'>*</span>
            </span>
          ) : (
            t('O_TO')
          )}
        </label>
        <div className='relative'>
          <Flatpickr
            className='cursor-pointer bg-hero bg-[right_10px_top_10px] bg-[length:18px_18px] bg-no-repeat flex flex-row-reverse border border-[#E9EDF9] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 focus:ring-blue-500 focus:border-blue-500'
            name='end_date'
            placeholder={t('O_END_DATE')}
            value={endDate}
            options={{
              minDate: startDate,
              dateFormat: 'd-m-Y',
              locale: language === 2 ? Albanian : undefined
            }}
            onChange={onChangeEndDate}
          />
          <ErrorMessage message={props.errors?.endDate} />
        </div>
      </div>
    </>
  )
}

export default FormDateRangePicker
