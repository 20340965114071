import ChangePassword from 'pages/auth/ChangePassword'
import ChangePwd from 'pages/ChangePwd'
import Home from 'pages/Home'
import Invoice from 'pages/invoice/Invoice'
import ManageSalary from 'pages/manageSalary/ManageSalary'
import ManageStaff from '../pages/manageStaffs/ManageStaff'
import Merchant from 'pages/merchants/Merchant'
import MyTransaction from 'pages/my-transaction/MyTransaction'
import Notifications from 'pages/notification/Notifications'
import Offers from 'pages/offers/Offers'
import PaymentSettlement from 'pages/paymentSettlement/PaymentSettlement'
import ProfileNew from 'pages/ProfileNew/ProfileNew'
import Reports from 'pages/reports/Reports'
import Settings from 'pages/settings/Settings'
import SplitBillPayment from 'pages/splitBillPayment/SplitBillPayment'
import TransactionManager from 'pages/transaction_manager/TransactionManager'
import Wallet from 'pages/walletNew/Wallet'
import SharedLayout from 'utils/SharedLayout'
import { HomeRedirect } from './HomeRedirect'
import Refund from 'pages/refund/Refund'
import ManageOrders from 'pages/manage_orders/ManageOrders'
import MyQrCode from 'pages/MyQrCode/MyQrCode'
import AboutUs from 'pages/static_content/AboutUs'
import { useTranslation } from 'react-i18next'

const UseChange = ({ data }) => {
  let { t } = useTranslation()
  return t(data)
}

const MerchantRoutes = {
  // All common routes
  path: '/',
  element: <SharedLayout />,
  children: [
    {
      path: '/dashboard',
      element: <Home />,
      name: <>{<UseChange data='NAV_DASHBOARD' />}</>
    },
    {
      path: '/offers',
      element: <Offers />,
      name: <>{<UseChange data='NAV_OFFERS' />}</>
    },
    {
      path: '/manageStaffs',
      element: <ManageStaff />,
      name: <>{<UseChange data='NAV_MANAGE_STAFF' />}</>
    },
    {
      path: '/payment-settlement',
      element: <PaymentSettlement />,
      name: <>{<UseChange data='NAV_PAYMENT_SETTLEMENT' />}</>
    },
    {
      path: '/wallet-statement',
      element: <Wallet />,
      name: <>{<UseChange data='DASHBOARD_WALLET_STATEMENT' />}</>
    },
    {
      path: '/manage-salary',
      element: <ManageSalary />,
      name: <>{<UseChange data='DASHBOARD_MANAGE_SALARY' />}</>
    },
    {
      path: '/my-transaction',
      element: <MyTransaction />,
      name: <>{<UseChange data='TRANSACTIONS' />}</>
    },
    {
      path: '/change_pwd',
      element: <ChangePwd />,
      name: <>{<UseChange data='NAV_SETTINGS' />}</>
    },
    {
      path: '/notifications',
      element: <Notifications />,
      name: <>{<UseChange data='NOTIFICATIONS' />}</>
    },
    {
      path: '/profile_new',
      element: <ProfileNew />,
      name: <>{<UseChange data='TOP_NAV_PROFILE' />}</>
    },
    {
      path: '/invoices',
      element: <Invoice />,
      name: <>{<UseChange data='Invoices' />}</>
    },
    {
      path: '/split-bill-payment',
      element: <SplitBillPayment />,
      name: <>{<UseChange data='DASHBOARD_SPLIT_BILL_PAYMENT' />}</>
    },
    {
      path: '/SplitBillPayment',
      element: <SplitBillPayment />,
      name: <>{<UseChange data='DASHBOARD_SPLIT_BILL_PAYMENT' />}</>
    },
    {
      path: '/refund',
      element: <Refund />,
      name: <>{<UseChange data='DASHBOARD_REFUND' />}</>
    },
    {
      path: '/manage-orders',
      element: <ManageOrders />,
      name: <>{<UseChange data='DASHBOARD_MANAGE_ORDERS' />}</>
    },
    {
      path: '/my-qrcode',
      element: <MyQrCode />,
      name: <>{<UseChange data='O_MY_QR_CODE' />}</>
    },

    {
      path: '/services',
      element: <Wallet />,
      name: <>{<UseChange data='DASHBOARD_WALLET_STATEMENT' />}</>
    },
    {
      path: '/merchants',
      element: <Merchant />,
      name: 'Merchant'
    },
    {
      path: '/settings',
      element: <Settings />,
      name: <>{<UseChange data='NAV_SETTINGS' />}</>
    },
    {
      path: '/transaction-manager',
      element: <TransactionManager />,
      name: <>{<UseChange data='TRANSACTIONS' />}</>
    },
    {
      path: '/reports',
      element: <Reports />,
      name: <>{<UseChange data='NAV_REPORTS' />}</>
    },
    {
      path: '/change-password',
      element: <ChangePassword />,
      name: <>{<UseChange data='SETTING_CHANGE_PASSWORD' />}</>
    },
    // {
    //     path: '/primaryPament',
    //     element: <PrimaryPament />,
    //     name: 'Primary payment'
    // },
    {
      path: '/about-us',
      element: <AboutUs />,
      name: <>{<UseChange data='NAV_ABOUT_US' />}</>
    },
    {
      path: '*',
      element: <HomeRedirect to='/dashboard' />
    }
  ]
}

export default MerchantRoutes
