/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OImage from 'components/reusable/OImage'
import dayjs from 'dayjs'
import className from 'classnames'
import { startCase } from 'lodash'

const SplitBillDetails = ({
  setShowSplitBillDetailModal,
  splitBillDetails
}) => {
  const [amountPercentagePaid, setAmountPercentagePaid] = useState(0)
  const [amountPaid, setAmountPaid] = useState(0)
  const { t } = useTranslation()

  const calculateIsPaid = () => {
    splitBillDetails?.participants.forEach(item => {
      if (item.status === 'paid') {
        setAmountPaid(amountPaid + item?.splitAmount)
      }
    })
  }
  const calculateAmountPercentagePaid = () => {
    setAmountPercentagePaid((amountPaid / splitBillDetails?.amount) * 100)
  }
  useEffect(() => {
    calculateAmountPercentagePaid()
  }, [amountPaid])
  useEffect(() => {
    calculateIsPaid()
  }, [])

  return (
    <div>
      <div
        id='split-bill'
        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 dark:bg-gray-800  right-0 top-0 '
        aria-labelledby='drawer-label'
      >
        <div>
          <button
            onClick={() => setShowSplitBillDetailModal(false)}
            title={t('O_CLOSE')}
            type='button'
            data-drawer-hide='drawer-example'
            aria-controls='drawer-example'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <img src='./images/close-circle.png' alt='close' />
            <span className='sr-only'>{t('CLOSE_MENU')}</span>
          </button>
        </div>

        <h3 className='font-medium text-[18px] mb-5'>
          {t('FRIENDS')} ({splitBillDetails.participants.length} {t('MEMBERS')})
        </h3>

        <div className='text-center mb-4'>
          <h2 className='font-bold text-[22px]'>Total</h2>
          <h2 className='font-bold text-[22px] text-LightBlue'>
            {t('O_LEK')} {splitBillDetails?.amount?.toFixed(2)}
          </h2>
          {splitBillDetails?.note ? (
            <p className='text-sm'>
              {t('NOTE')}:
              <strong className='pl-1 text-base break-all'>
                {splitBillDetails?.note}
              </strong>
            </p>
          ) : (
            ''
          )}
        </div>

        <div class='w-full h-4 mb-10 bg-gray-200 rounded-full dark:bg-gray-700'>
          <div
            class='h-4 bg-LightBlue rounded-l-lg dark:bg-blue-500 '
            style={{ width: `${amountPercentagePaid}%` }}
          ></div>
          <span className='block text-sm pt-1 font-medium'>
            Rs. {amountPaid} {t('PAID')}
          </span>
        </div>

        <p className='mb-4 font-medium'>
          {t('SPLIT_BETWEEN')} {splitBillDetails.participants.length}{' '}
          {t('PEOPLE')}
        </p>
        <table className='w-full'>
          <tbody>
            {splitBillDetails.participants?.map(item => (
              <div>
                <tr className='block mb-4 border-b pb-2'>
                  <div className='flex justify-between w-full'>
                    <div>
                      <div className='flex'>
                        <div>
                          <OImage
                            src={
                              item?.participantDetails?.profile_pic
                                ? item?.participantDetails?.profile_pic
                                : ''
                            }
                            fallbackUrl='/images/default-product.jpg'
                            className='w-8 h-8 rounded-full mr-3'
                            alt='card'
                          />
                        </div>

                        <div>
                          <div className='text-sm font-[500]'>
                            +{item?.participantDetails?.country_code}{' '}
                            {item?.participantDetails?.mobile}
                          </div>
                          <div className='text-gray-400 text-sm ml-1 '>
                            {item.status === 'paid'
                              ? 'Date: ' +
                                dayjs(item.updatedAt).format('DD-MM-YYYY')
                              : ''}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='text-right'>
                      <div className='items-end text-LightBlue font-medium text-sm '>
                        {item?.splitAmount} {t('O_LEK')}
                      </div>
                      <div
                        className={className('text-sm', {
                          'text-green-600': item?.status === 'paid',
                          'text-yellow-500': item?.status === 'unpaid'
                        })}
                      >
                        {startCase(item?.status)}
                      </div>
                    </div>
                  </div>
                </tr>
              </div>
            ))}
          </tbody>
        </table>
      </div>
      <div class='bg-gray-900  bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 overlay' />
    </div>
  )
}

export default SplitBillDetails
