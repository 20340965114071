const pathObj = {
  register: '/v1/merchant/signup',
  loginUser: '/v1/merchant/login',
  verifyOtp: '/v1/merchant/verify-otp',
  resendOtp: '/v1/merchant/resend-verify-otp',

  getUsers: '/v1/merchant/users',
  createUser: '/v1/merchant/add-user',
  updateUser: '/v1/admin/user',
  changeUserStatus: '/v1/merchant/change-user-status',
  refreshToken: '/v1/user/refresh-token',
  seriesList: '/v1/user/match/series',
  matchList: '/v1/user/match/list',
  refreshAmount: '/v1/admin/refresh-amount',
  userProfile: '/v1/user/profile',
  userProfileUpdate: '/v1/user/profile-update',
  userEditStake: '/v1/user/edit-stake',
  userEditOneClickStake: '/v1/user/edit-one-click-stake',
  activityLogs: '/v1/user/activity-logs',
  transactionLogs: '/v1/user/transaction/logs',
  matchDetail: '/v1/user/match/detail',
  matchScore: '/v1/user/match/score',
  sportsList: '/v1/user/sport/list',
  inPlayCount: '/v1/user/sport/inplay-count',
  betfairPlaceBet: '/v1/user/match/betfaire-place-bet',
  soccerPlaceBet: '/v1/user/match/soccer-place-bet',
  tennisPlaceBet: '/v1/user/match/tennis-place-bet',
  userInPlaySport: '/v1/user/sport',
  bookmakerBet: '/v1/user/match/bookmaker-place-bet',
  betPosition: '/v1/user/match/get-bet-positions',
  betEventsBetPosition: '/v1/user/match/get-events-bet-positions',
  multiEventPlaceBet: '/v1/user/match/multiple-event-place-bet',
  getCurrentBetsExchanges: '/v1/user/match/my-bets',
  cancelUnmatchedBets: '/v1/user/match/clear-unmatched-bet',
  getBookmakerBets: '/v1/user/match/my-bookmaker-bets',
  getFancyBets: '/v1/user/match/my-fancy-bets',
  getSportsBook: '/v1/user/match/my-sport-book-bets',
  fancyBetPlace: '/v1/user/match/fancy-place-bet',
  multiMarket: '/v1/user/sport/multi-market',
  preminumFancyBets: '/v1/user/match/premium-fancy-place-bet',
  addMatchInMultiMarket: '/v1/user/sport/multi-market',
  getPremiumFancy: '/v1/cron/premium-fancy',
  getBetsData: '/v1/user/match/events-bets',
  getHistory: '/v1/user/match/bet-history',
  messageList: '/v1/user/message/list',
  userSubscribe: '/v1/user/subscribe',
  adminSetting: '/v1/user/site-setting/site-setting-list',
  getWalletTransactions: '/v1/admin/wallet-transaction',
  getTransactions: '/v1/admin/transaction',
  statususer: '/v1/admin/user/change-status',
  getsubadmin: '/v1/admin/subadmin/list',
  editSubadmin: '/v1/admin/subadmin/edit',
  addSubadmin: '/v1/admin/subadmin/add',
  deleteSubadmin: '/v1/admin/subadmin/delete',
  statusSub: '/v1/admin/subadmin/change-status',
  addMerchant: '/v1/admin/merchant',
  editMerchant: '/v1/admin/merchant',
  getMerchantList: '/v1/admin/merchant/list',
  deleteMerchant: '/v1/admin/merchant',
  changeMerchantStatus: '/v1/admin/merchant/status',
  updateSettings: '/v1/admin/setting',
  updateMcrSettings: '/v1/admin/mcr-settings',
  getSettings: '/v1/admin/setting',
  getMcrSettings: '/v1/admin/mcr-settings',
  changeMerchantVerified: '/v1/admin/merchant/verify',
  getContactUs: '/v1/admin/contact-us',
  changeFAQStatus: '/v1/admin/faqs/status',
  getStaticContent: '/v1/admin/static-content',
  forgotPassword: '/v1/merchant/forgot-password',
  resetPassword: '/v1/merchant/reset-password',
  changePassword: '/v1/merchant/change-password',
  editProfile: '/v1/merchant/edit-profile',
  getCategoryList: '/v1/merchant/list',
  getAllOffers: '/v1/admin/offers',
  getAllStaffs: '/v1/merchant/agents-list',
  addStaffs: '/v1/merchant/add-agent',
  editStaffs: '/v1/merchant/edit-agent',
  changeStatus: '/v1/merchant/change-status',
  deleteStaff: '/v1/merchant/delete-agent',
  notificationToggle: '/v1/merchant/notification-toggle',
  getUserDetails: '/v1/admin/user/details',
  getDashboardDetails: '/v1/merchant/dashboard',
  getCompleteProfile: '/v1/merchant',
  getTransaction: '/v1/merchant/transactions',
  updateCompleteProfile: '/v1/merchant/complete-profile',
  getAllMerchantOffers: '/v1/merchant/list-offer',
  merchantOfferChangeStatus: '/v1/merchant/offer-status-update',
  deleteMerchantOffer: '/v1/merchant/delete-offer',
  editMerchantOffer: '/v1/merchant/edit-offer',
  addMerchantOffer: '/v1/merchant/add-offer',
  merchantNotifications: '/v1/merchant/notifications',
  merchantInvoices: '/v1/merchant/wallet/invoice',
  merchantCreateInvoice: '/v1/merchant/wallet/create-invoice',
  agentPaymentList: '/v1/merchant/wallet/agent-wallet-list',
  paymentSettlementRequest: '/v1/merchant/wallet/transfer-request',
  transferMoneyMerchant: '/v1/merchant/wallet/accept-transfer-request',
  AddCashIn: '/v1/merchant/wallet/cash-in',
  AddBalance: '/v1/merchant/wallet/add-operator-balance',
  cashInPreview: '/v1/merchant/wallet/cash-in-preview',
  TransferCash: '/v1/merchant/wallet/cash-transfer',
  cashTransferPreview: '/v1/merchant/wallet/cash-transfer-preview',
  cashTransferSendOtp: '/v1/merchant/wallet/cash-transfer-send-otp',
  AddCashOut: '/v1/merchant/wallet/cash-out',
  cashOutPreview: '/v1/merchant/wallet/cash-out-preview',
  cashOutSendOtp: '/v1/merchant/wallet/cash-out-send-otp',
  getAllWallet: '/v1/merchant/wallet/statement',
  refundList: '/v1/merchant/wallet/refunds',
  initiateRefund: '/v1/merchant/wallet/refund',
  merchantSplitBillPaymentList: '/v1/merchant/split-bill-payment/group-list',
  merchantSplitBillPaymentDetail:
    '/v1/merchant/split-bill-payment/group-detail',
  merchantSplitBillPaymentCheckMobile:
    '/v1/merchant/split-bill-payment/check-mobile',
  merchantSplitBillPaymentSendRequest:
    '/v1/merchant/split-bill-payment/send-request',
  sampleSalary: '/v1/merchant/wallet/salary-sample',
  salaryPayment: '/v1/merchant/wallet/salary-payment',
  transactions: '/v1/merchant/transactions',
  getReports: '/v1/merchant/reports/types',
  downloadReports: '/v1/merchant/reports',
  orders: '/v1/merchant/orders',
  merchantQR: '/v1/merchant/merchant-qr-code',
  staticContent: 'v1/user/static-content/list',
  staticContentSlug: 'v1/user/static-content?slug',
  getFaqs: '/v1/user/static-content/faqs',
  forgotPasswordOtp: '/v1/user/forgot-password',
  bankList: '/v1/merchant/wallet/bank-accounts',
  bankCommission: '/v1/user/wallet/transfer-commissions',
  transferToBank: '/v1/merchant/wallet/transfer-to-bank',
  downloadTransactions: '/v1/merchant/download-transactions',
  verifyLoginOtp: '/v1/merchant/login-verify-otp'
}

export default pathObj
