import React, { useContext, useState } from 'react'
import { apiPut, apiDelete } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import className from 'classnames'
import helper from '../../utils/helpers'
import AuthContext from 'context/AuthContext'

const StaffList = ({
  staffs,
  getAllStaffs,
  handelEdit,
  page,
  setOfferFilterState
}) => {
  const { t } = useTranslation()
  const [sort, setSort] = useState({})
  const notification = useToastContext()
  const { language } = useContext(AuthContext)

  const handelStatusChange = async item => {
    try {
      const payload = {
        status: item?.status === 'active' ? 'inactive' : 'active'
      }
      const path = `${apiPath.changeStatus}/${item?._id}`
      const result = await apiPut(path, payload)
      if (result?.status === 200) {
        notification.success(result.data.message)
        getAllStaffs()
      }
    } catch (error) {
      console.log('error in get all staffs list==>>>>', error.message)
    }
  }

  const handleSort = (sortKey, sortType, state) => {
    setOfferFilterState({ sortKey, sortType })
    setSort({ [state]: true })
  }

  const handelDelete = async item => {
    try {
      const path = apiPath.deleteStaff + '/' + item?._id
      const result = await apiDelete(path)
      if (result?.status === 200) {
        notification.success(result.data.message)

        getAllStaffs({ deletePage: 1 })
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error.message)
    }
  }

  return (
    <div className='p-1 md:p-3 pt-0'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('STAFF_AGENT')}
                  <button className='ml-3'>
                    <img
                      name='first_name'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort?.firstNameDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'firstNameUpArrow')
                      }
                    />
                    <img
                      name='first_name'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.firstNameUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'firstNameDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('O_EMAIL')}
                  <button className='ml-3'>
                    <img
                      name='email'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort?.emailDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'emailUpArrow')
                      }
                    />
                    <img
                      name='email'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.emailUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'emailDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('O_MOBILE_NUMBER')} (+355)
                  <button className='ml-3'>
                    <img
                      name='mobile'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort?.mobileDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'mobileUpArrow')
                      }
                    />
                    <img
                      name='mobile'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.mobileUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'mobileDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('STAFF_WALLET_LIMITATION')} ({t('O_LEK')})
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('O_CREATED_DATE')}
                  <button className='ml-3'>
                    <img
                      name='createdAt'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort?.createdAtDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'createdAtUpArrow')
                      }
                    />
                    <img
                      name='createdAt'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.createdAtUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'createdAtDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('STAFF_ACTIVE')}
                  <button className='ml-3'>
                    <img
                      name='status'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort?.statusDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'statusUpArrow')
                      }
                    />
                    <img
                      name='status'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.statusUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'statusDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('STAFF_ACTION')}
              </th>
            </tr>
          </thead>
          <tbody>
            {staffs &&
              staffs?.map(
                (
                  {
                    first_name,
                    last_name,
                    email,
                    country_code,
                    mobile,
                    status,
                    wallet_limit,
                    createdAt,
                    _id
                  },
                  i
                ) => (
                  <tr
                    key={i}
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    style={i % 2 ? { background: '#F9F9F9' } : {}}
                  >
                    <td className='py-3 px-6 border-r font-medium text-gray-900 dark:text-white'>
                      {i + 1 + 10 * (page - 1)}
                    </td>
                    <td className='py-3 px-6 border-r text-left'>
                      {first_name} {last_name}{' '}
                    </td>
                    <td className='py-3 px-6 t border-r text-left'>{email}</td>
                    <td className='py-3 px-6 border-r text-right'>{mobile}</td>
                    <td className='py-3 px-6 border-r text-right'>
                      {' '}
                      {wallet_limit}{' '}
                    </td>
                    <td className='py-3 px-6 border-r text-right'>
                      {dayjs(createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                    </td>
                    <td className='py-3 px-6 border-r text-center'>
                      <label
                        class='relative inline-flex items-center cursor-pointer'
                        title={`${
                          status === 'active' ? t('O_ACTIVE') : t('O_INACTIVE')
                        }`}
                      >
                        <input
                          type='checkbox'
                          className='sr-only peer'
                          checked={status === 'active'}
                          onChange={e =>
                            helper.alertFunction(
                              `${t('ARE_YOU_SURE_YOU_WANT_TO')} ${
                                e.target.checked ? 'active' : 'inactive'
                              } '${first_name} ${last_name}' ${t('STAFF')}?`,
                              { _id, status },
                              handelStatusChange,
                              '',
                              language
                            )
                          }
                        />
                        <div class="w-11 h-6 bg-[#A7A4B2] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
                      </label>
                    </td>
                    <td className='text-center'>
                      <div className=''>
                        <button
                          className='mr-5'
                          onClick={() =>
                            handelEdit({
                              first_name,
                              last_name,
                              email,
                              country_code,
                              mobile,
                              status,
                              wallet_limit,
                              createdAt,
                              _id
                            })
                          }
                        >
                          <span
                            title={t('O_EDIT')}
                            className='hover:text-blue-700 transition duration-150 ease-in-out'
                            data-bs-toggle='tooltip'
                          >
                            {' '}
                            <img src='images/edit.svg' alt='' />
                          </span>
                        </button>
                        <button
                          className='mr-5'
                          onClick={e =>
                            helper.alertFunction(
                              `${t(
                                'ARE_YOU_SURE_YOU_WANT_TO_DELETE'
                              )} '${first_name} ${last_name}' ${t('STAFF')}? `,
                              { _id },
                              handelDelete,
                              true,
                              language
                            )
                          }
                        >
                          <span
                            title={t('O_DELETE')}
                            className='hover:text-blue-700 transition duration-150 ease-in-out'
                            data-bs-toggle='tooltip'
                          >
                            {' '}
                            <img src='images/delete.svg' alt='' />
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              )}
            {isEmpty(staffs) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={8}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default StaffList
