import React, { useEffect, useState } from 'react'
import { apiGet, apiPost } from 'utils/apiFetch'
import apiPath from 'utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import OInputField from 'components/reusable/OInputField'
import { useTranslation } from 'react-i18next'
import { validationRules } from 'utils/constants'
import { preventMax } from 'utils/validations'
import helpers from 'utils/helpers'

const TransferToBank = ({ setShowTransferBankModal }) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const notification = useToastContext()
  const [cashInLoading, setCashInLoading] = useState(false)
  const [showFirst, setShowFirst] = useState(true)
  const [showSecond, setShowSecond] = useState(false)
  const [showThird, setShowThird] = useState(false)
  const [amount, setAmount] = useState(null)
  const [bankList, setBankList] = useState([])
  const [bank, setBank] = useState(null)
  const [commission, setCommission] = useState(null)

  const handleSubmitData = async data => {
    try {
      setCashInLoading(true)
      const result = await apiPost(apiPath.transferToBank, {
        amount,
        bankAccountId: bank?._id
      })
      if (result?.data?.success === true) {
        notification.success(result?.data?.message)
        setShowTransferBankModal(false)
      } else {
        notification.error(result?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setCashInLoading(false)
    }
  }

  const handleSubmitPreview = async data => {
    try {
      setCashInLoading(true)
      setShowFirst(false)
      setShowSecond(true)
    } catch (err) {
      console.log('err:', err)
    } finally {
      setCashInLoading(false)
    }
  }
  const handleChangeAmount = e => {
    setAmount(parseFloat(e.target.value))
  }
  const bankListData = async () => {
    const path = apiPath.bankList
    const result = await apiGet(path)
    setBankList(result?.data?.results)
  }
  const bankCommissionData = async () => {
    const path = apiPath.bankCommission
    const result = await apiGet(path)
    setCommission(result?.data?.results)
  }
  const handleBankChange = async (e, item) => {
    setBank(item)
  }

  const SubmitBankChange = async () => {
    console.log('here')
    if (bank != null) {
      setShowSecond(false)
      setShowThird(true)
    } else {
      notification.error('Please select a bank.')
    }
  }

  useEffect(() => {
    bankListData()
    bankCommissionData()
  }, [])

  console.log('bank', bank, amount)

  return (
    <>
      <div
        id='add-agent'
        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
        aria-labelledby='drawer-label'
      >
        <div>
          <button
            onClick={() => setShowTransferBankModal(false)}
            title={t('O_CLOSE')}
            type='button'
            data-drawer-hide='drawer-example'
            aria-controls='drawer-example'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <img src='./images/close-circle.png' alt='close' />

            <span className='sr-only'>title={t('CLOSE_MENU')}</span>
          </button>
        </div>
        {showFirst ? (
          <>
            <h3 className='font-medium text-[18px] mb-4 '>
              {t('DASHBOARD_TRANSFER_BANK')}
            </h3>
            <form>
              <div className='flex my-5 gap-4'>
                <div className='relative w-full '>
                  <OInputField
                    name='amount'
                    inputLabel={
                      <>
                        {t('O_AMOUNT')}
                        <span className='text-red-500'>*</span>
                      </>
                    }
                    errors={errors}
                    type='number'
                    onInput={e => preventMax(e, 5)}
                    register={register('amount', {
                      required: t('PLEASE_ENTER_AMOUNT'),
                      maxLength: {
                        value: 5,
                        message: t('MAXIMUM_LENGTH_SHOULD_BE_5_DIGITS')
                      },
                      pattern: {
                        value: validationRules.numbers,
                        message: t('PLEASE_ENTER_A_VALID_AMOUNT')
                      },
                      min: {
                        value: 1,
                        message: t('VALUE_CAN_NOT_BE_LESS_THAN_1')
                      }
                    })}
                    onChange={e => handleChangeAmount(e)}
                  />
                </div>
              </div>
            </form>
            <button
              class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
              type='submit'
              onClick={handleSubmit(handleSubmitPreview)}
            >
              {helpers?.ternaryCondition(
                cashInLoading,
                'Loading...',
                <>{t('O_PROCEED')}</>
              )}
            </button>
          </>
        ) : (
          ''
        )}
        {showSecond ? (
          <>
            <h3 className='font-medium text-[18px] mb-4 dark:text-white'>{t('SELECT_BANK')}</h3>
            {bankList?.map(item => (
              <div class='flex items-center mb-4'>
                <input
                  id='default-radio-1'
                  type='radio'
                  value={item?._id}
                  name='default-radio'
                  class='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                  onChange={e => handleBankChange(e, item)}
                />
                <label
                  for='default-radio-1'
                  class='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                >
                  {item?.bankName}
                </label>
              </div>
            ))}
            <button
              class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
              // type='submit'
              onClick={() => SubmitBankChange()}
            >
              {t('O_PROCEED')}
            </button>
          </>
        ) : (
          ''
        )}
        {showThird ? (
          <>
            <h3 className='font-medium text-[18px] mb-4 dark:text-white'>
              {t('SENDING_MONEY_WALLET_TO_BANK')}
            </h3>
            <ul className='list-unstyled'>
              <li>
                {' '}
                {t('A/C_NO')} {bank?.accountNo}
              </li>
              <li className='d-flex align-items-center justify-content-between'>
                <span>{t('AMOUNT_TO_BE_SENT')}</span>
                <span>
                  {t('O_LEK')} {amount}
                </span>
              </li>
              <li className='d-flex align-items-center justify-content-between'>
                <span>
                  {t('CHARGES')} ({commission?.withdrawToBankCommission}%)
                </span>
                <span>
                  {(
                    (amount * commission?.withdrawToBankCommission) /
                    100
                  ).toFixed(2)}
                </span>
              </li>
            </ul>

            <div className='totel_amount d-flex align-items-center justify-content-between'>
              <span>{t('TO_PAYABLE')}</span>
              <span>
                {t('O_LEK')}{' '}
                {(
                  amount +
                  (amount * commission?.withdrawToBankCommission) / 100
                ).toFixed(2)}
              </span>
            </div>
            <button
              class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
              // type='submit'
              onClick={() => handleSubmitData()}
            >
              {t('O_PROCEED')}
            </button>
          </>
        ) : (
          ''
        )}
      </div>

      <div class='bg-gray-900  bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 overlay' />
    </>
  )
}

export default TransferToBank
