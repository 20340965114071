import React, { useState, useEffect } from 'react'
import apiPath from '../../utils/pathObj'
import { apiGet, apiPost } from '../../utils/apiFetch'
import { isEmpty, startCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import Pagination from '../Pagination'
import className from 'classnames'
import helpers from '../../utils/helpers'
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const MyTransaction = () => {
  const [isReset, setIsReset] = useState(false)
  const [page, setPage] = useState(1)
  const [category, setCategory] = useState('')
  const { t } = useTranslation()
  const [sort, setSort] = useState({})
  const [transaction, setTransaction] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState('')
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [transactionFilterState, setTransactionFilterState] = useState({
    page: '',
    sortKey: '',
    sortType: '',
    startDate: '',
    endDate: ''
  })

  const { sortKey, sortType } = transactionFilterState

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handleSubmit = async () => {
    try {
      const payload = {
        page,
        status: category,
        sortType,
        sortKey,
        startDate: startDate ? dayjs(startDate).format('MM-DD-YYYY') : null,
        endDate: endDate ? dayjs(endDate).format('MM-DD-YYYY') : null
      }
      const path = apiPath.transactions
      const result = await apiGet(path, payload)
      if (result?.status === 200) {
        setTransaction(result?.data?.results?.docs)
        setPaginationObj({
          ...paginationObj,
          page: result.data.results?.page,
          pageCount: result.data.results?.totalPages,
          perPageItem: result?.data?.results?.docs.length,
          totalItems: result.data.results?.totalDocs
        })
      }
    } catch (error) {
      console.log('error in transaction list==>>>>', error.message)
    }
  }

  const handleSort = (sortKeys, sortTypes, state) => {
    setTransactionFilterState({
      ...transactionFilterState,
      sortKey: sortKeys,
      sortType: sortTypes
    })
    setSort({ [state]: true })
  }

  const handleDownloadTransactions = async () => {
    try {
      console.log('in handleDownloadTransactions')
      let sendData = {}
      if (category) sendData.status = category
      if (startDate) sendData.startDate = dayjs(startDate).format('YYYY-MM-DD')
      if (endDate) sendData.endDate = dayjs(endDate).format('YYYY-MM-DD')
      console.log('sendData: ', sendData)
      const res = await apiPost(apiPath.downloadTransactions, sendData)
      console.log('res: ', res)

      if (res.data.success) {
        helpers.downloadFile(res?.data?.results?.filePath)
      }
    } catch (error) {
      console.log('error:', error)
    } finally {
      console.log('in finally transaaction')
    }
  }

  useEffect(() => {
    handleSubmit()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, category, transactionFilterState, startDate, endDate])

  const handleDateChange = (start, end) => {
    setStartDate(start)
    setEndDate(end)
    setPage(1)
  }

  const handleReset = () => {
    setEndDate('')
    setStartDate('')
    setIsReset(true)
    setCategory('')
  }

  return (
    <div className='p-4 md:p-8'>
      <div className=' px-4 py-3'>
        <div className='col-span-2 flex flex-wrap items-center justify-between pb-2'>
          <h4 className='m-0 text-[#222] md:text-md text-lg md:text-start  md:w-auto w-full font-medium mr-12'>
            &nbsp;
          </h4>
          <div className='flex lg:justify-end flex-wrap'>
            <ODateRangePicker
              handleDateChange={handleDateChange}
              isReset={isReset}
              setIsReset={setIsReset}
            />
            <div className='flex items-center  ml-3 mb-3'>
              <select
                id=''
                type='text'
                name='category'
                className='block p-2 py-3 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#DFDFDF]  focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                placeholder=' '
                onChange={e => setCategory(e.target.value)}
                value={category}
              >
                <option defaultValue value=''>
                  {t('O_ALL')}
                </option>
                <option value='completed'>{t('O_COMPLETED')}</option>
                <option value='failed'>{t('O_FAILED')}</option>
                <option value='pending'>{t('O_PENDING')}</option>
              </select>
            </div>
            <button
              title={t('O_RESET')}
              type='button'
              onClick={handleReset}
              className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
            >
              {t('O_RESET')}
            </button>
            <button
              title={t('DASHBOARD_AGENT_DOWNLOAD')}
              onClick={() => handleDownloadTransactions()}
              className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
            >
              {t('DASHBOARD_AGENT_DOWNLOAD')}
            </button>
          </div>
        </div>
      </div>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('INVOICE_TRANSACTION_ID')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('DASHBOARD_AGENT_TRANSACTION_TYPE')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_MOBILE_NUMBER')} (+355)
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('O_CREATED_DATE')}
                  <button className='ml-3'>
                    <img
                      name='createdAt'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort.createdAtDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'createdAtUpArrow')
                      }
                    />
                    <img
                      name='createdAt'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.createdAtUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'createdAtDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_OFFER_AMOUNT')} ({t('O_LEK')})
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex items-center justify-start'>
                  {t('STATUS')}
                  <button className='ml-3'>
                    <img
                      name='status'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5 rotate-180', {
                        'opacity-20': sort.statusDownArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'asc', 'statusUpArrow')
                      }
                    />
                    <img
                      name='status'
                      src='images/caret-down.png'
                      alt=''
                      className={className('w-2.5', {
                        'opacity-20': sort?.statusUpArrow
                      })}
                      onClick={e =>
                        handleSort(e.target.name, 'desc', 'statusDownArrow')
                      }
                    />
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {transaction.map((item, i) => {
              return (
                <tr
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  style={i % 2 ? { background: '#F9F9F9' } : {}}
                >
                  <td className='py-3 px-6 border-r font-medium text-gray-900 dark:text-white'>
                    {i + 1 + 10 * (page - 1)}
                  </td>
                  <td className='py-3 px-6 border-r' width='13%'>
                    {item?.transactionId}
                  </td>
                  <td className='py-3 px-6 border-r text-left'>
                    {startCase(item?.transaction_type)}
                  </td>
                  <td className='py-3 px-6 border-r text-right'>
                    {item?.mobile}
                  </td>
                  <td className='py-3 px-6 border-r text-right'>
                    {dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A')}
                  </td>
                  <td className='py-3 px-6 border-r text-right'>
                    {item?.transaction_amount}
                  </td>
                  <td className='py-3 px-6 border-r text-center'>
                    <span className='text-green text-[#1B9B07]'>
                      {startCase(item?.status)}
                    </span>
                  </td>
                </tr>
              )
            })}
            {isEmpty(transaction) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={7}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
        <Pagination
          handlePageClick={handlePageClick}
          options={paginationObj}
          page={page}
        />
      </div>
    </div>
  )
}

export default MyTransaction
