import ErrorMessage from 'components/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
import React, { useState, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { apiPost, apiPut, apiGet } from 'utils/apiFetch'
import pathObj from 'utils/pathObj'
import { validationRules } from 'utils/constants'
import AuthContext from 'context/AuthContext'
import { useTranslation } from 'react-i18next'
import OInputField from 'components/reusable/OInputField'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import helpers from 'utils/helpers'
import { isEmpty } from 'lodash'

function ChangePwd () {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    trigger,
    formState: { errors, isDirty }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })

  const [changePasswordLoading, setChangePasswordLoading] = useState(false)
  const [switchStatus, setSwitchStatus] = useState({ status: false })
  const notification = useToastContext()
  const { logoutUser } = useContext(AuthContext)
  const [icon, setIcon] = useState(true)
  const [icon2, setIcon2] = useState(true)
  const [icon3, setIcon3] = useState(true)

  const handleSubmitForm = async data => {
    try {
      if (!isDirty) {
        notification.info('Please enter something')
        return
      }
      setChangePasswordLoading(true)

      const res = await apiPost(pathObj.changePassword, {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword
      })
      if (res.data.success) {
        reset()
        notification.success(res?.data?.message)
        logoutUser()
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setChangePasswordLoading(false)
    }
  }

  const switchChange = async event => {
    try {
      const path = pathObj.notificationToggle
      const toggleValue = event.target.checked
      const payload = {
        status: toggleValue
      }
      const { data: response } = await apiPut(path, payload)
      if (response.success) {
        notification.success(response?.message)
        setSwitchStatus(toggleValue)
        notificationApi()
        localStorage.setItem('notifications', toggleValue)
      } else {
        notification.error(response?.message || 'Error')
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const notificationApi = async () => {
    const res = await apiGet(pathObj.getCompleteProfile)
    setSwitchStatus({ status: res?.data?.results?.notifications })
  }

  useEffect(() => {
    notificationApi()
  }, [])
  function changeIcon () {
    setIcon(!icon)
  }
  function changeIcon2 () {
    setIcon2(!icon2)
  }
  function changeIcon3 () {
    setIcon3(!icon3)
  }

  const newPassword = watch('newPassword')
  useEffect(() => {
    if (!isEmpty(newPassword)) {
      trigger('confirm_password')
    }
  }, [newPassword, trigger])

  return (
    <>
      <div className='p-4 md:p-8'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center  px-0'>
            <h3 class='block mb-0 text-slate-900 font-medium text-base md:text-xl'>
              &nbsp;
            </h3>
          </div>
          <div className='flex items-center'>
            <span class='text-base md:text-xl font-semibold block mr-3 md:mr-5'>
              {t('SETTING_NOTIFICATIONS')}
            </span>
            <div className='d-flex'>
              <label class='relative inline-flex items-center cursor-pointer'>
                <input
                  class='sr-only peer'
                  type='checkbox'
                  id='custom-switch'
                  onChange={switchChange}
                  checked={switchStatus?.status}
                />
                <div class="w-11 h-6 bg-[#A7A4B2] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
              </label>
            </div>
          </div>
        </div>

        <form
          className='w-80 mx-auto mt-10 xl:mt-44'
          onSubmit={handleSubmit(handleSubmitForm)}
          method='post'
        >
          <div className='text-xl font-semibold py-5 block'>
            {t('SETTING_CHANGE_PASSWORD')}
          </div>
          <div class='relative w-full col-span-2  mb-4'>
            <OInputField
              type={helpers?.ternaryCondition(icon, 'password', 'text')}
              name='oldPassword'
              id='oldPassword'
              inputLabel={
                <>
                  {t('SETTING_OLD_PASSWORD')}
                  <span className='text-red-500'>*</span>
                </>
              }
              className='block py-3 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
              register={register('oldPassword', {
                required: t('PLEASE_ENTER_OLD_PASSWORD'),

                pattern: {
                  value: validationRules.password,
                  message: t('OLD_PASSWORD_MUST_CONTAIN_LOWERCASE_UPPERCASE_')
                }
              })}
            />
            {icon ? (
              <span
                className='password_view absolute top-[18px] right-[20px]'
                onClick={() => changeIcon()}
              >
                <AiFillEyeInvisible />
              </span>
            ) : (
              <span
                className='password_view absolute top-[18px] right-[20px]'
                onClick={() => changeIcon()}
              >
                <AiFillEye />
              </span>
            )}
            <ErrorMessage message={errors?.oldPassword?.message} />
          </div>

          <div class='relative w-full col-span-2  mb-4'>
            <OInputField
              type={helpers?.ternaryCondition(icon2, 'password', 'text')}
              name='newPassword'
              id='newPassword'
              inputLabel={
                <>
                  {t('SETTING_NEW_PASSWORD')}
                  <span className='text-red-500'>*</span>
                </>
              }
              className='block py-3 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
              register={register('newPassword', {
                required: t('PLEASE_ENTER_NEW_PASSWORD'),
                pattern: {
                  value: validationRules.password,
                  message: validationRules.newPasswordMessage
                }
              })}
            />
            {icon2 ? (
              <span
                className='password_view absolute top-[18px] right-[20px]'
                onClick={() => changeIcon2()}
              >
                <AiFillEyeInvisible />
              </span>
            ) : (
              <span
                className='password_view absolute top-[18px] right-[20px]'
                onClick={() => changeIcon2()}
              >
                <AiFillEye />
              </span>
            )}
            <ErrorMessage message={errors?.newPassword?.message} />
          </div>

          <div class='relative w-full col-span-2  mb-4'>
            <OInputField
              type={helpers?.ternaryCondition(icon3, 'password', 'text')}
              name='confirm_password'
              id='confirm_password'
              inputLabel={
                <>
                  {t('SETTING_CONFIRM_PASSWORD')}
                  <span className='text-red-500'>*</span>
                </>
              }
              className='block py-3 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
              register={register('confirm_password', {
                required: t('PLEASE_ENTER_CONFIRM_PASSWORD'),
                pattern: {
                  value: validationRules.password,
                  message: validationRules.confirmPasswordMessage
                },
                validate: value => {
                  const { newPassword } = getValues()
                  return (
                    newPassword === value ||
                    t('NEW_PASSWORD_AND_CONFIRM_PASSWORD_DOES_NOT_MATCH')
                  )
                }
              })}
            />
            {icon3 ? (
              <span
                className='password_view absolute top-[18px] right-[20px]'
                onClick={() => changeIcon3()}
              >
                <AiFillEyeInvisible />
              </span>
            ) : (
              <span
                className='password_view absolute top-[18px] right-[20px]'
                onClick={() => changeIcon3()}
              >
                <AiFillEye />
              </span>
            )}
            <ErrorMessage message={errors?.confirm_password?.message} />
          </div>

          <button
            label='Submit'
            type='submit'
            loading={changePasswordLoading}
            className='bg-gradblue hover:bg-DarkBlue cursor-pointer w-full mt-3 text-white active:bg-slate-100 text-md px-8 py-4 rounded-lg font-normal shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'
          >
            {t('O_SUBMIT')}
          </button>
        </form>
      </div>
    </>
  )
}

export default ChangePwd
