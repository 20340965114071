import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import apiPath from '../../utils/pathObj'
import { apiGet } from '../../utils/apiFetch'
import SplitBillDetails from './SplitBillDetails'

const SplitBillPaymentList = ({ splitBills, page }) => {
  const { t } = useTranslation()
  const [splitBillDetails, setSplitBillDetails] = useState({})
  const [showSplitBillDetailModal, setShowSplitBillDetailModal] =
    useState(false)

  const getSplitBillDetails = async id => {
    let result
    try {
      const path = apiPath.merchantSplitBillPaymentDetail + '/' + id
      result = await apiGet(path)
      if (result?.status === 200) {
        const response = result?.data?.results
        setSplitBillDetails(response)
        setShowSplitBillDetailModal(true)
      }
    } catch (error) {
      console.log('error in get all offers list==>>>> result:', error)
    }
  }
  return (
    <div className='p-1 md:p-3 pt-0'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-left'>{t('GROUP_NAME')}</div>
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('NO_OF_PARTICIPANTS')}
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>
                  {t('TOTAL_AMOUNT')} ({t('O_LEK')})
                </div>
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='flex justify-start'>{t('NOTE')}</div>
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_CREATED_DATE')}
              </th>
            </tr>
          </thead>
          <tbody>
            {splitBills && splitBills?.length > 0 ? (
              <>
                {splitBills &&
                  splitBills?.map(
                    (
                      {
                        name,
                        note,
                        createdAt,
                        amount,
                        numberOfParticipants,
                        _id
                      },
                      i
                    ) => {
                      return (
                        <tr
                          key={i}
                          className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                          style={i % 2 ? { background: '#F9F9F9' } : {}}
                        >
                          <td className='py-3 px-6 border-r font-medium text-gray-900 dark:text-white'>
                            {i + 1 + 10 * (page - 1)}
                          </td>
                          <td className='py-3 px-6 border-r text-left'>
                            <div>{name}</div>
                          </td>
                          <td className='py-3 px-6 border-r text-right'>
                            <button onClick={() => getSplitBillDetails(_id)}>
                              <div
                                class='flex items-center justify-center w-10 h-10 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800'
                                href='#'
                              >
                                {numberOfParticipants}
                              </div>
                            </button>
                          </td>
                          <td className='py-3 px-6 border-r text-right'>
                            {amount}
                          </td>
                          <td className='py-3 px-6 border-r text-left'>
                            {note}
                          </td>
                          <td className='py-3 px-6 border-r text-right'>
                            {dayjs(createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                          </td>
                        </tr>
                      )
                    }
                  )}{' '}
              </>
            ) : (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-[#F2EFEF]'>
                {' '}
                <td colSpan={6} className='py-4 px-6 border-r'>
                  {t('O_NO_RECORD_FOUND')}
                </td>{' '}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showSplitBillDetailModal ? (
        <SplitBillDetails
          setShowSplitBillDetailModal={setShowSplitBillDetailModal}
          splitBillDetails={splitBillDetails}
        />
      ) : null}
    </div>
  )
}

export default SplitBillPaymentList
