import React, { useState, useEffect } from 'react'
import { apiGet, apiPost } from 'utils/apiFetch'
import apiPath from 'utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import OInputField from 'components/reusable/OInputField'
import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'
import ErrorMessage from 'components/ErrorMessage'
import { ArrowDownCircleIcon } from '@heroicons/react/20/solid'
import OtpInput from 'react18-input-otp'
import OButton from 'components/reusable/OButton'
import { startCase } from 'lodash'

const CashTransfer = ({ sender, setTransferMoneyShowModal }) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })


  const notification = useToastContext()
  const [cashTransferLoading, setCashTransferLoading] = useState(false)
  const [cashPreview, setCashPreview] = useState(false)
  const [cashPreviewData, setCashPreviewData] = useState({})
  const [cashTransferData, setCashTransferData] = useState(false)
  const [receiver, setReceiver] = useState(null)
  const [paymentDetails, setPaymentDetails] = useState(new FormData())
  const [cashPreviewOtp, setCashPreviewOtp] = useState(false)
  var isTimer =
  Number(localStorage.getItem('seconds')) > 0
    ? Number(localStorage.getItem('seconds'))
    : 60
const [seconds, setSeconds] = useState(isTimer)
const [minutes, setMinutes] = useState('00')
const [timerActive, setTimerActive] = useState(true)
const [otp, setOtp] = useState(null)
const [otpError, setOtpError] = useState('')
const handleOTPChange = enteredOtp => {
  setOtp(enteredOtp)
}


  const handleSubmitForm = async data => {
    try {
      if (!otp) {
        setOtpError('Please enter OTP.')
        return
      } else if (otp.length < 4) {
        setOtpError('Please enter OTP.')
        return
      } else {
        setOtpError('')
      }

      paymentDetails.append("otp", otp)



      setCashTransferLoading(true)
      const res = await apiPost(apiPath.TransferCash, paymentDetails)
      if (res.data.success === true) {
        setCashTransferData(true)
        setCashPreviewOtp(false)
      } else {
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setCashTransferLoading(false)
    }
  }

  const handleSubmitPreview = async data => {
    try {
      setCashTransferLoading(true)
      const previewPayload = {
        sender_country_code: sender.country_code,
        sender_mobile: sender.mobile,
        receiver_country_code: receiver.country_code,
        receiver_mobile: receiver.mobile,
        amount: data.amount
      }

      const paymentData = new FormData()
      paymentData.append("sender_country_code", sender.country_code)
      paymentData.append("sender_mobile", sender.mobile)
      paymentData.append("receiver_country_code", sender.country_code)
      paymentData.append("receiver_mobile", receiver.mobile)
      paymentData.append("amount", data.amount)
      paymentData.append("note", data.note)
      if(data.transaction_document.length > 0){
        paymentData.append("doc", data.transaction_document[0])
      }
      
      const res = await apiPost(apiPath.cashTransferPreview, previewPayload)
      if(res?.data?.success === true) {
        setCashPreviewData(res?.data?.results)
        setCashPreview(true)
        setPaymentDetails(paymentData)
        notification.success(res.data.message)
      }else{
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setCashTransferLoading(false)
    }
  }

  
  const handleSubmitOtp = async data => {
    try {
      setCashTransferLoading(true)
      const payload = {
        countryCode: paymentDetails.get("sender_country_code"),
        mobile: paymentDetails.get("sender_mobile"),
        amount: paymentDetails.get("amount")
      }

      const res = await apiPost(apiPath.cashTransferSendOtp, payload)
      if (res.data.success === true) {
        setCashPreviewOtp(true)
        notification.success(res.data.message)
        localStorage.setItem('seconds', 60)
      } else {
        setTransferMoneyShowModal(false)
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setCashTransferLoading(false)
    }
  }
  const handleResendOTP = async data => {
    try {
      if (parseInt(minutes) <= 0 && parseInt(seconds) <= 0) {
        const payload = {
          countryCode: paymentDetails.get("sender_country_code"),
          mobile: paymentDetails.get("sender_mobile"),
          amount: paymentDetails.get("amount"),
          resend: true
        }
        const res = await apiPost(apiPath.cashTransferSendOtp, payload)
        if (res.data.success === true) {
          notification.success(res.data.message)
          setSeconds(60)
          setMinutes(0)
        } else {
          notification.error(res.data.message)
        }
      }
    } catch (err) {
      console.log('err:', err)
    }
  }
  let timerRef
  useEffect(() => {
    if (cashPreviewOtp) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timerRef = setInterval(() => {
        if (minutes >= 0) {
          if (seconds === 0) {
            if (minutes > 0) {
              setSeconds(60)
              setMinutes(prev => prev - 1)
            } else {
              setSeconds(0)
            }
          } else {
            setSeconds(prev => prev - 1)
          }
        } else {
          clearInterval(timerRef)
          setTimerActive(false)
        }
      }, 1000)
      return () => clearInterval(timerRef)
    }
  })

  localStorage.setItem('seconds', seconds)

  const clearTime = () => {
    setSeconds(0)
    localStorage.setItem('seconds', 0)
    setTransferMoneyShowModal(false)
  }

  useEffect(() => {
    if (cashPreviewOtp) {
      window.onbeforeunload = function (event) {
        clearTime()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashPreviewOtp])

  const handleAmount = e => {
    var value = e.target.value
    const pattern = /^\d{0,5}(\.\d{0,2})?$/
    if (e.target.value === '') {
      e.target.value = e.target.value.slice(e.target.value?.length - 1, -1)
    }
    const isValid = pattern.test(value)
    if (!isValid) {
      value = value.slice(0, -1)
    }
    setValue('amount', value)
  }
  var pressedKeys = []

  //search receiver code portion
  const [searchedItems, setSearchedItems] = useState([])
  const [searchInput, setSearchInput] = useState("")
  const [selectedItem, setSelectedItem] = useState(null)

  const handleInputChange = value => {
    setSearchInput(value)
  }

  const handleChange = value => {
    setSelectedItem(value)
    setValue("receiver_id", value)
    setReceiver(value)
  }

  const fetchSearchData = () => {
    try{
      if(searchInput === '') return []

      const payload = {
        status: 'active',
        keyword: searchInput,
      }

      return apiGet(apiPath.getUsers, payload).then(result => {
        return result.data.results.docs.filter(it => it._id !== sender._id)
      })
    }catch(error){
      return []
    }
  }

  const preventMaxValueDescription = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
    if (e.target.value.length > 200) {
      e.target.value = e.target.value.slice(0, 200)
    }
  }

  const countDigitsAfterDecimal = number => {
    if (number?.toString()?.split('.')[1]?.length > 2)
      return parseFloat(number).toFixed(2)
    return number || 0
  }

  return (
    <>
      <div
        id='add-agent'
        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
        aria-labelledby='drawer-label'
      >
        <div>
          <button
            onClick={() => setTransferMoneyShowModal(false)}
            title='Close'
            type='button'
            data-drawer-hide='drawer-example'
            aria-controls='drawer-example'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
          >
            <img src='./images/close-circle.png' alt='close' />

            <span className='sr-only'>{t('CLOSE_MENU')}</span>
          </button>
        </div>
        <h3 className='font-medium text-[18px] mb-4'>
          {t('TRANSFER_MONEY')}
        </h3>
        <form>
          <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow">
                <figure className='w-10 h-10 text-gray-500 mb-3 rounded-full overflow-hidden'>
                  <img
                    src={sender.profile_pic}
                    alt='No_image'
                    className='mx-auto w-full h-full'
                  />
                </figure>
              <a href="#">
                  <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 ">{t('FROM')}</h5>
              </a>
              <p class="font-normal text-gray-500 ">{`${sender.name}`}</p>
              <p class="mb-3 text-sm text-gray-500 ">{`+${sender.country_code}${sender.mobile}`}</p>
              <p class="text-sm  text-gray-500 items-center mr-5">{t('DASHBOARD_WALLET_BALANCE')}</p>
              <p class="font-medium items-center text-blue-600 hover:underline">{countDigitsAfterDecimal(sender.wallet_amount)} ALL</p>
          </div>

          <div className='w-full flex my-4 items-center justify-center'>
            <ArrowDownCircleIcon className='cursor-pointer content-center w-12 h-12 text-green-600' />{' '}
          </div>

          <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow ">
              {receiver && <figure className='w-10 h-10 text-gray-500 mb-3 rounded-full overflow-hidden'>
                <img
                  src={receiver.profile_pic}
                  alt='No_image'
                  className='mx-auto w-full h-full'
                />
              </figure>}
              
              <a href="#">
                  <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 ">{t('TO')}</h5>
              </a>

              

              <div className='searchUsers relative z-0 mb-2 w-full group'>
                <AsyncSelect
                  menuPortalTarget={document.body}
                  styles={{
                  // Fixes the overlapping problem of the component
                  menuPortal: provided => ({ ...provided, zIndex: 9999 })
                  }}                  
                  className='text-sm'
                  cacheOptions
                  defaultOptions
                  id='receiver_id'
                  name='receiver_id'
                  placeholder={t('O_SEARCH_BY_NAME_EMAIL_MOBILE')}
                  value={selectedItem}
                  getOptionLabel={(e) => `${e.first_name} ${e.last_name}`}
                  getOptionValue={(e) => e._id}
                  loadOptions={fetchSearchData}
                  onInputChange={handleInputChange}
                  onChange={handleChange}
                  register={register('receiver_id', {
                    required: t('PLEASE_ENTER_RECEIVER'),
                    validate: value => {
                      return value !== ''
                        ? true
                        : t('VALUE_CAN_NOT_BE_NULL')
                    }
                  })}
                />
                <ErrorMessage message={errors?.receiver_id?.message} />
              </div>

              {
                receiver && 
                <>
                <p class="mb-3 text-sm text-gray-500 ">{`+${receiver.country_code}${receiver.mobile}`}</p>
                <p class="text-sm  text-gray-500  items-center mr-5">{t('DASHBOARD_WALLET_BALANCE')}</p>
                <p class="font-medium items-center text-blue-600 hover:underline">{countDigitsAfterDecimal(receiver.wallet_amount)} ALL</p>
                </>
              }
              
          </div>

          <div className='relative  my-5'>
            <OInputField
              name='amount'
              errors={errors}
              inputLabel={
                <>
                  {t('O_OFFER_AMOUNT')}
                  <span className='text-red-500'>*</span>
                </>
              }
              type='number'
              register={register('amount', {
                required: t('PLEASE_ENTER_COOLECTED_AMOUNT'),
                onChange: e => handleAmount(e),
                validate: value => {
                  return value !== '0'
                    ? true
                    : t('VALUE_CAN_NOT_BE_LESS_THAN_0.01')
                }
              })}
              onKeyDown={e => {
                pressedKeys.push(e.key)
                var lastKey = pressedKeys[pressedKeys.length - 2]
                if (lastKey === '.') {
                  if (['-', '.', '+', 'e'].includes(e.key)) {
                    e.preventDefault()
                  }
                } else if (['-', '+', 'e'].includes(e.key)) {
                  e.preventDefault()
                }
              }}
            />
          </div>

          <div className='relative  my-5'>
            <textarea
              name='note'
              rows='4'
              id='note'
              className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
              placeholder=' '
              maxLength={200}
              onInput={e => preventMaxValueDescription(e)}
              {...register('note', {
                required: t('PLEASE_ENTER_TRANSFER_NOTE'),
                minLength: {
                  value: 10,
                  message: t('MINIMUM_LENGTH_MUST_BE_10')
                },
                maxLength: {
                  value: 200,
                  message: t('MAXIMUM_CHARACTER_CAN_BE_200')
                },
                validate: value => {
                  return !!value?.trim() ? true : t('WHITE_SPACE_NOT_ALLOWED')
                }
              })}
            />
            <label
              htmlFor='note'
              className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
            >
              {t('CASH_TRANSFER_NOTE')}
              <span className='text-red-500'>*</span>
            </label>
            <ErrorMessage message={errors?.note?.message} />
          </div>

          <div className='relative  my-5'>
              <label class="block mb-2 text-sm font-medium text-gray-900" for="transaction_document">{t('DOCUMENT_OPTIONAL')}</label>
              <input 
                  class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 p-3 focus:outline-none " 
                  aria-describedby="file_input_help" 
                  id="transaction_document" 
                  name='transaction_document'
                  accept='.pdf'

                  type="file"
                  {...register('transaction_document', {
                    validate: files => {
                      if(files.length > 0) {
                        return files[0]?.size < 10000000 || "MAX. 10MB"
                      }else{
                        return true
                      }
                    }
                  })}
              />
              <ErrorMessage message={errors?.transaction_document?.message} />
              <p class="mt-1 text-sm text-gray-500" id="file_input_help">PDF (MAX. 10MB).</p>
          </div>

          


          <div>
            <div className='bg-amber-50 p-2 rounded-lg items-center mb-4'>
              <h6 className='text-amber-300 font-medium'>
                {t('CASH_IN_NOTE')} :
              </h6>
              <h6 className='text-Black font-medium text-[16px]'>
                {t('CASH_IN_SERVICE_DEDUCTION')}
              </h6>
            </div>
          </div>
          <button
            class='bg-LightBlue hover:bg-DarkBlue cursor-pointer   text-white  font-medium  w-full active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
            type='submit'
            onClick={handleSubmit(handleSubmitPreview)}
          >
            {cashTransferLoading ? 'Loading...' : <>{t('CASH_IN_TRANSFER')}</>}
          </button>
          {cashPreview ? (
            <>
              <div
                id='transfer-agent'
                className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
                aria-labelledby='drawer-label'
              >
                <div>
                  <button
                    onClick={() => setTransferMoneyShowModal(false)}
                    title='Close'
                    type='button'
                    data-drawer-hide='drawer-example'
                    aria-controls='drawer-example'
                    className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                  >
                    <img src='./images/close-circle.png' alt='close' />

                    <span className='sr-only'>{t('CLOSE_MENU')}</span>
                  </button>
                </div>
                <div className='w-full justify-center mb-4'>
                  <div className='relative'>
                      <h3 className='font-medium text-[18px] mb-4'>{t('CASH_TRANSFER_PAYMENT_CONFIRMATION')}</h3>
                      <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow">
                            <figure className='w-10 h-10 text-gray-500 mb-3 rounded-full overflow-hidden'>
                              <img
                                src={cashPreviewData.senderImage}
                                alt='No_image'
                                className='mx-auto w-full h-full'
                              />
                            </figure>
                          <span href="#">
                              <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 ">{t('FROM')}</h5>
                          </span>
                          <p class="font-normal text-gray-500 ">{`${cashPreviewData.senderFirstName} ${cashPreviewData.senderLastName}`}</p>
                          <p class="mb-3 text-sm text-gray-500 ">{`+${cashPreviewData.senderCountryCode}${cashPreviewData.senderMobile}`}</p>
                          <p class="text-sm  text-gray-500 items-center mr-5">{t('DASHBOARD_WALLET_BALANCE')}</p>
                          <p class="font-medium items-center text-blue-600 hover:underline">{countDigitsAfterDecimal(cashPreviewData.senderWallet)} ALL</p>
                      </div>

                      <div className='w-full flex my-4 items-center justify-center'>
                        <ArrowDownCircleIcon className='cursor-pointer content-center w-12 h-12 text-green-600' />{' '}
                      </div>

                      <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow ">
                        <figure className='w-10 h-10 text-gray-500 mb-3 rounded-full overflow-hidden'>
                          <img
                            src={cashPreviewData.receiverImage}
                            alt='No_image'
                            className='mx-auto w-full h-full'
                          />
                        </figure>
                        
                        <span href="#">
                            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 ">{t('TO')}</h5>
                        </span>
                        <p class="font-normal text-gray-500 ">{`${cashPreviewData.receiverFirstName} ${cashPreviewData.receiverLastName}`}</p>
                        <p class="mb-3 text-sm text-gray-500 ">{`+${cashPreviewData.receiverCountryCode}${cashPreviewData.receiverMobile}`}</p>
                        <p class="text-sm  text-gray-500  items-center mr-5">{t('DASHBOARD_WALLET_BALANCE')}</p>
                        <p class="font-medium items-center text-blue-600 hover:underline">{countDigitsAfterDecimal(cashPreviewData.receiverWallet)} ALL</p>
                      </div>

                    <div className='relative  my-5'>
                      <OInputField
                        errors={errors}
                        disabled={true}
                        inputLabel={
                          <>
                            {t('O_OFFER_AMOUNT')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        type='number'
                        value={cashPreviewData.amountToBeReceived}
                      />
                    </div>

                    {
                        cashPreviewData.commission > 0 && 
                        <>
                          <div>
                            <div className='bg-amber-50 p-2 rounded-lg items-center mb-4'>
                              <h6 className='text-amber-300 font-medium'>
                                {t('CASH_IN_NOTE')} :
                              </h6>
                              <h6 className='text-Black font-medium text-[16px]'>
                                {t('CASH_IN_SERVICE_DEDUCTION')} ({cashPreviewData.amount - cashPreviewData.amountToBeReceived} LEK)
                              </h6>
                            </div>
                          </div>
                        </>
                    }

                    <div className='relative  my-5'>
                      <textarea
                        name='note'
                        rows='4'
                        id='note'
                        disabled={true}
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        maxLength={200}
                        onInput={e => preventMaxValueDescription(e)}
                        {...register('note', {
                          required: t('PLEASE_ENTER_TRANSFER_NOTE'),
                          minLength: {
                            value: 10,
                            message: t('MINIMUM_LENGTH_MUST_BE_10')
                          },
                          maxLength: {
                            value: 200,
                            message: t('MAXIMUM_CHARACTER_CAN_BE_200')
                          },
                          validate: value => {
                            return !!value?.trim() ? true : t('WHITE_SPACE_NOT_ALLOWED')
                          }
                        })}
                      />
                      <label
                        htmlFor='note'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] duration-300 transform -translate-y-6 bg-white scale-75 top-3 left-3 p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('CASH_TRANSFER_NOTE')}
                        <span className='text-red-500'>*</span>
                      </label>
                      <ErrorMessage message={errors?.note?.message} />
                    </div>
                    </div>
                  </div>
                  <div className='flex justify-between items-center'>
                    <button
                      class='bg-LightBlue hover:bg-DarkBlue cursor-pointer text-white  font-medium active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
                      type='button'
                      onClick={() => setTransferMoneyShowModal(false)}
                    >
                      {t('CASH_IN_CANCEL')}
                    </button>
                    <button
                      class='bg-LightBlue hover:bg-DarkBlue cursor-pointer text-white  font-medium active:bg-slate-100 text-sm px-8 py-4 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 undefined'
                      disabled={cashTransferLoading}
                      type='button'
                      onClick={handleSubmit(handleSubmitOtp)}
                    >
                      {t('CASH_IN_OK')}
                    </button>

                    {cashTransferData ? (
                      <div
                        id='add-agent'
                        className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
                        aria-labelledby='drawer-label'
                      >
                        <div>
                          <button
                            onClick={() => setTransferMoneyShowModal(false)}
                            title='Close'
                            type='button'
                            data-drawer-hide='drawer-example'
                            aria-controls='drawer-example'
                            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                          >
                            <img src='./images/close-circle.png' alt='close' />

                            <span className='sr-only'>{t('CLOSE_MENU')}</span>
                          </button>
                        </div>
                        <div className='flex items-center w-full justify-center mb-4'>
                          <div className='relative text-center '>
                            <div className='w-32 h-30 relative mx-auto'>
                              <img src='images/check.png' alt='No_image' />
                            </div>
                            <h3 className='font-medium text-[18px] mb-4'>
                              {t('CASH_IN_THE_AMOUNT_TRANSFERRED')}{' '}
                              {cashPreviewData.receiverMobile}
                            </h3>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
              </div>
            </>
          ) : (
            ''
          )}
        </form>
        {cashPreviewOtp ? (
            <div
              id='add-agent'
              className='fixed  pt-16 z-40 h-screen px-6 pb-4 overflow-y-auto bg-white sm:w-96 w-80 right-0 top-0 '
              aria-labelledby='drawer-label'
            >
              <div>
                <button
                  onClick={clearTime}
                  title='Close'
                  type='button'
                  data-drawer-hide='drawer-example'
                  aria-controls='drawer-example'
                  className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                >
                  <img src='./images/close-circle.png' alt='close' />

                  <span className='sr-only'>{t('CLOSE_MENU')}</span>
                </button>
              </div>
              <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded'>
                <div className=' py-5 flex-auto'>
                  <section className='w-full'>
                    <div className='w-full flex justify-between'>
                      <div className='font-semibold'>
                        {t('O_VERIFICATION_CODE')}
                      </div>{' '}
                    </div>
                    <div className='font-semibold mt-4 '>
                      {t('O_ENTER_OTP')}
                    </div>
                    <OtpInput
                      inputStyle={{
                        border: 'solid 1px #dfdfdf',
                        width: '5rem',
                        height: '2.4rem',
                        marginRight: '.5rem',
                        outline: 'none',
                        borderRadius: '8px'
                      }}
                      shouldAutoFocus
                      containerStyle='text-center mt-2'
                      value={otp}
                      onChange={handleOTPChange}
                      isInputNum={true}
                      numInputs={4}
                      separator={<span> </span>}
                    />
                    <ErrorMessage message={otpError} />
                    <OButton
                      label={startCase('Verify')}
                      extraClasses='w-full mt-4'
                      type='button'
                      onClick={handleSubmit(handleSubmitForm)}
                      disabled={!timerActive}
                    />
                    <div className='text-xs mt-5 text-center'>
                      {t('O_DID_NOT_RECEIVE_OTP')}{' '}
                      <span
                        className='text-violet-700'
                        onClick={handleSubmit(handleResendOTP)}
                        style={{
                          cursor: 'pointer'
                        }}
                      >
                        <span
                          style={{
                            color: seconds === 0 ? 'blue' : 'gray'
                          }}
                        >
                          {' '}
                          {t('O_RESEND_OTP')}{' '}
                        </span>
                        ( {t('O_EXPIRING_IN')} {minutes}:
                        {seconds < 10 ? 0 + seconds : seconds})
                      </span>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
      </div>

      <div class='bg-gray-900  bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 overlay' />
    </>
  )
}

export default CashTransfer
